import { useQuery } from "react-query";

import axios from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { Stick } from "../models/scenario";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

export const ScenarioStickListKey = "ScenarioSticksList";
const fetchSettings = async ({ queryKey }) => {
  const [, scenarioId] = queryKey;
  if (!scenarioId) {
    return [];
  }
  const response = await axios.get<Stick[]>(`${API_URL}/${scenarioId}/sticks`);
  return response.data;
};

const useGetScenarioList = (scenarioId: string) => {
  return useQuery<Stick[], string>([ScenarioStickListKey, scenarioId], fetchSettings, {
    refetchOnWindowFocus: false
  });
};

export default useGetScenarioList;
