import { IEditableProjectLayer } from "components/project/layers/hooks";
import { Shapefile } from "components/project/shapefiles/queries";

import { POINT_SHAPEFILE_TYPE, STROKE_SHAPEFILE_TYPE } from "../constants";

interface AddShapefileLayersParams {
  addLayer;
  mapbox: mapboxgl.Map;
  layerList: IEditableProjectLayer[];
  layerName: string;
  shapefile: Shapefile;
  shapefileFeatures;
}

export function addShapefileLayers(params: AddShapefileLayersParams) {
  const { addLayer, mapbox, layerName, shapefile, shapefileFeatures } = params;

  mapbox.addSource(layerName, {
    type: "geojson",
    data: shapefile.featureCollection
  });

  /**
   * Other shapefile layers
   */
  const layerStrokeName = `${layerName}${STROKE_SHAPEFILE_TYPE}`;
  const layerPointName = `${layerName}${POINT_SHAPEFILE_TYPE}`;

  const geomKeys =
    shapefileFeatures?.[layerName]?.features
      ?.filter((f) => f.checked)
      .map((f) => f.shapefileGeomId) || [];

  const uncheckedFeatures = geomKeys?.length
    ? geomKeys
    : shapefile.featureCollection?.features.map((f) => f.properties.shapefileGeomId);

  const hasPointGeom = (shapefile?.featureCollection?.features ?? []).some(
    (geom) => geom.geometry?.type === "Point"
  );

  // Point layer
  if (hasPointGeom) {
    addLayer(
      {
        id: layerPointName,
        type: "circle",
        source: layerName,
        layout: {},
        paint: {
          "circle-color": ["get", "color"],
          "circle-opacity": ["get", "opacity"],
          "circle-stroke-color": ["get", "strokeColor"],
          "circle-stroke-opacity": ["get", "opacity"],
          "circle-stroke-width": 1,
          "circle-radius": {
            base: 1.8,
            stops: [
              [6, 2],
              [15, 25],
              [22, 30]
            ]
          }
        }
      }
      // ,
      // beforeLayer
    );
  }

  // Stroke Layer
  addLayer(
    {
      id: layerStrokeName,
      type: "line",
      source: layerName,
      layout: {},
      paint: {
        "line-color": ["get", "strokeColor"],
        "line-width": ["get", "thickness"],
        "line-opacity": [
          "match",
          ["get", "shapefileGeomId"],
          uncheckedFeatures,
          ["get", "opacity"],
          0
        ]
      }
    },
    hasPointGeom ? layerPointName : null
    // beforeLayer
  );

  // Fill Layer
  addLayer(
    {
      id: layerName,
      type: "fill",
      source: layerName,
      layout: {},
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": [
          "match",
          ["get", "shapefileGeomId"],
          uncheckedFeatures,
          ["get", "opacity"],
          0
        ]
      }
    },
    layerStrokeName
  );
}
