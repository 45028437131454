import { removeCount } from "utils/removeCountFromTitle";

import { mBinSize } from "./binSize";
import { PdenSourceEnum } from "./pdenDataSourceSetting";

export class LegendItemModel {
  text: string;
  count: number;
  color: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  fontColor: string;
  property: string;
  canBin: boolean;
  isChecked: boolean;
  isNotEqual: boolean;
  groupTitle?: string;
  categoryId: string;
  inFilter: boolean;
  bin: mBinSize;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalizeBySettings: any;
  isColorLocked: boolean;
  groupByTitle: string;
  partialContextTitle: string;
  isForecastToggleOn: boolean;
  selectedForecastFolderName: string;
  pdenSource: PdenSourceEnum;
  constructor(
    title,
    property?,
    canBin?,
    categoryId?,
    bin?,
    inFilter?,
    count?,
    normalizeBySettings?,
    groupByTitle?,
    partialContextTitle?,
    isForecastToggleOn?,
    selectedForecastFolderName?,
    pdenSource?: PdenSourceEnum
  ) {
    this.text = title;
    this.count = count;
    this.value = removeCount(title);
    this.title = title;
    this.property = property;
    this.color = "";
    this.fontColor = "";
    this.canBin = canBin;
    this.isChecked = false;
    this.isNotEqual = false;
    this.categoryId = categoryId;
    this.inFilter = inFilter;
    this.bin = bin;
    this.normalizeBySettings = normalizeBySettings;
    this.isColorLocked = false;
    this.groupByTitle = "";
    this.partialContextTitle = "";
    this.isForecastToggleOn = false;
    this.selectedForecastFolderName = "";
    this.pdenSource = pdenSource ?? PdenSourceEnum.Public;
  }
}

export interface FocusTreeMapInputModel {
  items: LegendItemModel[];
  onChange: (legendItems: LegendItemModel[]) => void;
}
