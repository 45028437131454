import { Delete } from "@material-ui/icons";
import { Button, Card, Input, Row } from "antd";
import styled from "styled-components";

import { ColorEditor } from "components/shared";

const PaletteColors = ({
  selectedPalette,
  thickness,
  numberOfColors,
  setSelectedPalette,
  setIsChanged,
  updateThickness,
  setNumberOfColors
}) => {
  return (
    <ColorsContainer>
      <ContentRow>
        <Card>
          {(selectedPalette?.colors ?? []).map((color, i) => {
            return (
              <ColorRow key={selectedPalette.name + ":" + i}>
                <div>Group {i + 1}</div>
                <ColorEditor
                  color={color}
                  thickness={selectedPalette.thickness[i]}
                  onChange={function (color: string, thickness: number): void {
                    const newColor = selectedPalette.colors;
                    newColor[i] = color;

                    const newThickness = selectedPalette.thickness ?? [];
                    newThickness[i] = thickness;

                    const updatedPallet = Object.assign({}, selectedPalette, {
                      colors: [...newColor],
                      thickness: [...newThickness]
                    });

                    setSelectedPalette(updatedPallet);
                    setIsChanged(true);
                  }}
                />
                <Button
                  icon={<Delete />}
                  type="ghost"
                  onClick={() => {
                    selectedPalette.colors.splice(i, 1);
                    selectedPalette.thickness.splice(i, 1);
                    setSelectedPalette(
                      Object.assign({}, selectedPalette, {
                        colors: [...selectedPalette.colors],
                        thickness: [...selectedPalette.thickness]
                      })
                    );
                    setNumberOfColors((prevNumberOfColors) => prevNumberOfColors - 1);
                  }}
                  className="color-delete-btn"
                />
              </ColorRow>
            );
          })}
        </Card>
        <SpacedBetweenRow className="new-color-btn-row">
          <Row>
            <label>Line Thickness</label>
            <Input
              type="number"
              aria-label="thickness"
              min={1}
              placeholder="Line Thickness"
              max={10}
              step={0.1}
              value={thickness}
              onChange={(evt) => {
                const thickness = parseFloat(evt.target.value);
                updateThickness(thickness);
              }}
            />
          </Row>
          <Row>
            <label>Number of Colours</label>
            <Input
              type="number"
              aria-label="number of colors"
              min={1}
              placeholder="Number of Colours"
              max={100}
              value={numberOfColors}
              step={1}
              onChange={(evt) => {
                setNumberOfColors(parseInt(evt.target.value));
              }}
            />
          </Row>
        </SpacedBetweenRow>
      </ContentRow>
    </ColorsContainer>
  );
};

export default PaletteColors;

const ColorsContainer = styled.div`
  width: 600px;
  padding-right: 100px;
`;

const ContentRow = styled(Row)`
  padding: 5px;
  padding-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  .ant-card {
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }

  .interopolate-title {
    gap: 4px;
    display: flex;
    align-items: center;
  }
`;

const ColorRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 30px;
  gap: 10px;
  min-height: 35px;
  div {
    min-width: 200px;
  }
  &:hover {
    .color-delete-btn {
      display: flex !important;
    }
  }
  .color-delete-btn {
    display: none !important;
    min-width: 30px;
    align-items: center;
    justify-content: center;
    height: 24px;
    color: red;
  }
`;

const SpacedBetweenRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  .ant-input {
    max-width: 300px;
  }
`;
