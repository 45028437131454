import { useSelector } from "react-redux";

import { Table } from "antd";
import styled from "styled-components";

import { RootState } from "../../store/rootReducer";
import { Stick } from "./models/scenario";

interface ScenarioSticksProps {
  containerHeight: number;
}
export default function ScenarioSticks({ containerHeight }: ScenarioSticksProps) {
  const state = useSelector((state: RootState) => state.app.padScenario);
  const columns = [
    {
      title: "UWID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Configuration",
      dataIndex: "configurationId",
      key: "configurationId",
      render: (_, record: Stick) => {
        return record?.configurationId?.substring(0, 8);
      }
    },
    {
      title: "Heel Depth",
      dataIndex: "heelDepth",
      key: "heelDepth",
      render: (_, record: Stick) => {
        return record?.survey?.length > 0 ? record.survey[0].z.toFixed(2) : "";
      }
    },
    {
      title: "Toe Depth",
      dataIndex: "toeDepth",
      key: "toeDepth",
      render: (_, record: Stick) => {
        return record?.survey?.length > 1
          ? record.survey[record.survey.length - 1].z.toFixed(2)
          : "";
      }
    }
  ];

  return (
    <RootContainer>
      <Table
        columns={columns}
        dataSource={state?.sticks}
        bordered
        scroll={{ y: containerHeight - 100 }}
        pagination={false}></Table>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  .ant-table {
    font-size: 1.3rem;
    min-height: 100px;
  }

  .ant-table-cell {
    padding: 5px;
  }
`;
