import html2canvas from "html2canvas";

export const copySamChartToClipboard = (
  screenshotOverlay: HTMLDivElement
): Promise<void> => {
  const selector = ".App .ChartPlot";

  if (screenshotOverlay) {
    screenshotOverlay.style.border = "none";
  }

  return html2canvas(document.querySelector(selector)).then((canvas) => {
    return canvas.toBlob((blob) => {
      if (!blob) return;
      if (!navigator.clipboard) {
        return;
      }
      const data = [new ClipboardItem({ "image/png": blob })];

      return navigator.clipboard.write(data);
    });
  });
};

export const copyRFvsPVChartToClipboard = (
  screenshotOverlay: HTMLDivElement
): Promise<void> => {
  const selector = ".App .ChartPlot";

  if (screenshotOverlay) {
    screenshotOverlay.style.border = "none";
  }

  return html2canvas(document.querySelector(selector)).then((canvas) =>
    canvas.toBlob((blob) => {
      if (!blob) return;
      if (!navigator.clipboard) {
        return;
      }
      const data = [new ClipboardItem({ "image/png": blob })];

      return navigator.clipboard.write(data);
    })
  );
};
