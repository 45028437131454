import { useCallback, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ApolloError, useMutation } from "@apollo/client";
import {
  AddCircle,
  Dashboard,
  Delete,
  Edit,
  Launch,
  Refresh,
  RemoveCircleOutline,
  Save
} from "@material-ui/icons";
import { Divider, Popconfirm, Popover } from "antd";
import classnames from "classnames";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { uid } from "utils";

import { useUser } from "hooks";

import {
  ADD_DASHBOARD,
  DELETE_DASHBOARD,
  REMOVE_WORKSPACE_DASHBOARD,
  UPDATE_DASHBOARD,
  WorkspaceClientName
} from "api/workspace";

import {
  DashboardInput,
  DashboardUpdate,
  DashboardWidgetInput,
  DashboardWidgetUpdate,
  WorkspaceDashboard
} from "models/workspace";

import { Tooltip } from "components/base";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";

import NewDashboardInput from "./NewDashboardInput";
import { useDashboardContext, useDashboardDispatch } from "./hooks";
import {
  getDashboardPermissions,
  getDashboardTypeIcon,
  getNewDashboardName
} from "./utils/";

export interface DashboardTabInputModel {
  className?: string;
  active: boolean;
  dashboard: WorkspaceDashboard;
  index: number;
  onClick: (dashboardId: string) => void;
  refreshWorkspace: () => void;
}

export default function DashboardTab(input: DashboardTabInputModel) {
  // context
  const dashboardDispatch = useDashboardDispatch();
  const {
    dashboard: currentDashboard,
    dashboardRefreshObserver,
    isModified,
    hasModifiedWidgets
  } = useDashboardContext();
  const { activeDashboardId, workspace } = useWorkspaceContext();

  // state
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canRemove, setCanRemove] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [copyError, setCopyError] = useState("");
  const [renameError, setRenameError] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCopyDashboard, setShowCopyDashboard] = useState(false);
  const [showRenameDashboard, setShowRenameDashboard] = useState(false);

  // state from store
  const channel = useSelector((state: RootState) => state.channel.channel);
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const checkedGlobalTypeWells = useSelector(
    (state: RootState) => state.arps.checkedGlobalTypeWells
  );
  const checkedTypeWellKeys = useSelector(
    (state: RootState) => state.arps.checkedTypeWellKeys
  );
  const expandedTypeWellKeys = useSelector(
    (state: RootState) => state.arps.expandedTypeWellKeys
  );

  // derived state
  const isActive = activeDashboardId === input.dashboard.dashboardId;
  const isDashboardModified = isActive && (hasModifiedWidgets || isModified);

  // mutations
  const [removeDashboard] = useMutation(REMOVE_WORKSPACE_DASHBOARD);
  const [addDashboard, { loading: addDashboardLoading }] = useMutation(ADD_DASHBOARD);
  const [updateDashboard, { loading: updateDashboardLoading }] =
    useMutation(UPDATE_DASHBOARD);
  const [deleteDashboard] = useMutation(DELETE_DASHBOARD);

  // custom hooks
  const { user, isAtLeastPowerUser } = useUser();

  // Reset the copy error
  useEffect(() => {
    if (showCopyDashboard) setContextMenuVisible(false);
    setCopyError("");
  }, [showCopyDashboard]);

  // Reset the rename error
  useEffect(() => {
    if (showRenameDashboard) setContextMenuVisible(false);
    setRenameError("");
  }, [showRenameDashboard]);

  // Reset the component to its initial state.
  useEffect(() => {
    const dashboard = input.dashboard;

    const canEditWorkspace = workspace?.owner === user.id || isAtLeastPowerUser;
    const permissions = getDashboardPermissions(
      dashboard,
      user,
      isAtLeastPowerUser,
      canEditWorkspace
    );

    const editable = permissions.canEdit;
    // Prevent removing of active dashboard.
    const removable = input.active ? false : canEditWorkspace;
    const deletable = input.active ? false : permissions.canDelete;

    setCanEdit(editable);
    setCanRemove(removable);
    setCanDelete(deletable);
    setCopyError("");
  }, [user, isAtLeastPowerUser, input.dashboard, input.active, workspace?.owner]);

  // Take action when the current dashboard is requested to be refreshed.
  const handleRefreshDashboard = useCallback(() => {
    setContextMenuVisible(false);
    if (!currentDashboard) return;
    // Clear the session storage cache of the chart settings.
    currentDashboard.widgets?.forEach((widget) => {
      sessionStorage.removeItem(`${widget.type}::${widget.widgetId}`);
    });

    // Dispatch the request to refresh the dashboard with a new transaction id.
    dashboardRefreshObserver.next(uid());
    setContextMenuVisible(false);
  }, [currentDashboard, dashboardRefreshObserver]);

  // Take action when the current dashboard is requested to be renamed.
  const handleRenameDashboard = useCallback(
    (title: string) => {
      // Map to the update dashboard model.
      const updatedDashboard: DashboardUpdate = {
        dashboardId: input.dashboard.dashboardId,
        title: title
      };

      updateDashboard({
        variables: {
          dashboard: updatedDashboard
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: () => {
          input.refreshWorkspace && input.refreshWorkspace();
          setShowRenameDashboard(false);
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          setRenameError(error.message);
        }
      });

      setContextMenuVisible(false);
    },
    [input, updateDashboard]
  );

  const handleEnableDesignMode = useCallback(() => {
    dashboardDispatch({ payload: { dashboardMode: "edit" } });
    setContextMenuVisible(false);
  }, [dashboardDispatch]);

  const handlePopoutDashboard = useCallback(() => {
    if (!input.dashboard || !workspace) return;

    sessionStorage.removeItem(channel);
    const settings = {
      workspaceId: workspace?.workspaceId,
      dashboardId: input.dashboard.dashboardId
    };

    const settingsJson = JSON.stringify({
      filterId,
      groupBy,
      settings: settings,
      typeWells: {
        checkedGlobalTypeWells,
        checkedTypeWellKeys,
        expandedTypeWellKeys
      }
    });
    sessionStorage.setItem(channel, settingsJson);
    window.open(`/standalone/dashboard/${channel}?channel=${channel}`);
    setContextMenuVisible(false);
  }, [
    channel,
    filterId,
    groupBy,
    checkedGlobalTypeWells,
    checkedTypeWellKeys,
    expandedTypeWellKeys,
    input.dashboard,
    workspace
  ]);

  // Take action when the current dashboard is saved.
  const handleSaveDashboard = useCallback(() => {
    if (!currentDashboard) return;
    // Use the session storage cache of the chart settings to update the dashboard.
    const updatedWidgets = currentDashboard.widgets?.map((widget) => {
      const updatedWidget: DashboardWidgetUpdate = {
        widgetId: widget.widgetId,
        title: widget.title,
        type: widget.type,
        x: widget.x,
        y: widget.y,
        width: widget.width,
        height: widget.height,
        settings: widget.settings
      };
      const sessionData = sessionStorage.getItem(`${widget.type}::${widget.widgetId}`);
      if (sessionData) {
        updatedWidget.settings = sessionData;
      }
      return updatedWidget;
    });

    // Map to the update dashboard model.
    const updatedDashboard: DashboardUpdate = {
      dashboardId: currentDashboard.dashboardId,
      parentId: currentDashboard.parentId,
      title: currentDashboard.title,
      layout: {
        format: currentDashboard.layout.format,
        width: currentDashboard.layout.width,
        height: currentDashboard.layout.height
      },
      widgets: updatedWidgets
    };

    updateDashboard({
      variables: {
        dashboard: updatedDashboard
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: () => {
        toast.success("Dashboard saved");

        // Dispatch the request to refresh the dashboard with a new transaction id.
        dashboardRefreshObserver.next(uid());
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
        toast.error("Failed to save dashboard");
      }
    });

    setContextMenuVisible(false);
  }, [currentDashboard, dashboardRefreshObserver, updateDashboard]);

  // Take action when the current dashboard is saved as a new dashboard.
  const handleSaveAsNewDashboard = useCallback(
    (title: string, position: number) => {
      // Use the session storage cache of the chart settings to copy the current dashboard.
      const copiedWidgets = currentDashboard?.widgets?.map((widget) => {
        const copiedWidget: DashboardWidgetInput = {
          title: widget.title,
          type: widget.type,
          x: widget.x,
          y: widget.y,
          width: widget.width,
          height: widget.height,
          settings: widget.settings
        };
        const sessionData = sessionStorage.getItem(`chart::${widget.widgetId}`);
        if (sessionData) {
          copiedWidget.settings = sessionData;
        }
        return copiedWidget;
      });

      // Map to the new dashboard model.
      const newDashboard: DashboardInput = {
        parentId: currentDashboard.dashboardId,
        title: title,
        layout: {
          format: currentDashboard.layout.format,
          width: currentDashboard.layout.width,
          height: currentDashboard.layout.height
        },
        widgets: copiedWidgets
      };

      addDashboard({
        variables: {
          dashboard: newDashboard,
          workspaceId: workspace?.workspaceId,
          position: position
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: (data) => {
          input.refreshWorkspace && input.refreshWorkspace();
          input.onClick(data?.addDashboard?.dashboardId);
          setShowCopyDashboard(false);
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          setCopyError(error.message);
        }
      });
    },
    [currentDashboard, addDashboard, workspace?.workspaceId, input]
  );

  // Take action when the dashboard is removed from the workspace
  const handleRemoveDashboard = useCallback(() => {
    removeDashboard({
      variables: {
        workspaceId: workspace?.workspaceId,
        dashboardId: input.dashboard.dashboardId
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: () => {
        input.refreshWorkspace && input.refreshWorkspace();
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
        toast.error("Failed to remove dashboard");
      }
    });

    setContextMenuVisible(false);
  }, [removeDashboard, workspace?.workspaceId, input]);

  // Take action when the dashboard is permanently deleted.
  const handleDeleteDashboard = useCallback(() => {
    deleteDashboard({
      variables: {
        id: input.dashboard.dashboardId
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: () => {
        input.refreshWorkspace && input.refreshWorkspace();
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
        toast.error("Failed to delete dashboard");
      }
    });
  }, [deleteDashboard, input]);

  const titleClassNames = classnames("nav", {
    "nav-active": isActive,
    plain: !isActive
  });

  return (
    <Draggable draggableId={input.dashboard.dashboardId} index={input.index}>
      {(provided) => (
        <>
          <Popover
            overlayClassName="tab-popover"
            placement="bottom"
            content={
              <>
                {input.active && (
                  <ContextMenuItem
                    data-testid="dashboard-tab-context-refresh"
                    onClick={() => handleRefreshDashboard()}>
                    <Refresh />
                    Refresh Settings
                  </ContextMenuItem>
                )}

                {input.active && canEdit && (
                  <ContextMenuItem
                    data-testid="dashboard-tab-context-save"
                    onClick={() => handleSaveDashboard()}>
                    <Save />
                    Save Settings
                  </ContextMenuItem>
                )}

                {input.active && (
                  <ContextMenuItem
                    data-testid="dashboard-tab-context-save-as"
                    onClick={() => setShowCopyDashboard(true)}>
                    <AddCircle />
                    Save As New Dashboard
                  </ContextMenuItem>
                )}

                {input.active && <StyledDivider />}

                {canEdit && (
                  <ContextMenuItem
                    data-testid="dashboard-tab-context-rename"
                    onClick={() => setShowRenameDashboard(true)}>
                    <Edit />
                    Rename Dashboard
                  </ContextMenuItem>
                )}

                {canRemove && (
                  <ContextMenuItem
                    data-testid="dashboard-tab-context-hide"
                    onClick={() => handleRemoveDashboard()}>
                    <RemoveCircleOutline />
                    Hide Dashboard
                  </ContextMenuItem>
                )}

                {canDelete && (
                  <Popconfirm
                    placement="bottom"
                    onConfirm={(evt) => {
                      handleDeleteDashboard();
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                    onCancel={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                    open={showConfirmDelete}
                    onOpenChange={(visible) => setShowConfirmDelete(visible)}
                    okText="Delete"
                    okType="danger"
                    title={`Are you sure you want to delete this dashboard?`}>
                    <ContextMenuItem
                      data-testid="dashboard-tab-context-delete"
                      onClick={() => setShowConfirmDelete(true)}>
                      <Delete />
                      Delete Dashboard
                    </ContextMenuItem>
                  </Popconfirm>
                )}

                {input.active && canEdit && (
                  <>
                    <StyledDivider />
                    <ContextMenuItem
                      data-testid="dashboard-tab-context-edit-layout"
                      onClick={() => handleEnableDesignMode()}>
                      <Dashboard />
                      Edit Dashboard Layout
                    </ContextMenuItem>
                  </>
                )}
                {!input.dashboard?.widgetTypes?.includes("map") && (
                  <>
                    <StyledDivider />
                    <ContextMenuItem
                      data-testid="dashboard-tab-context-new-tab"
                      onClick={() => handlePopoutDashboard()}>
                      <Launch />
                      Open in New Tab
                    </ContextMenuItem>
                  </>
                )}
              </>
            }
            trigger="contextMenu"
            open={contextMenuVisible}
            onOpenChange={(v) => setContextMenuVisible(v)}>
            <Tooltip
              placement="bottom"
              title={
                <>
                  {getDashboardTypeIcon(input.dashboard)}
                  {input.dashboard.title}
                </>
              }
              mouseEnterDelay={2}>
              <DashboardTitle
                ref={provided.innerRef}
                className={titleClassNames}
                isModified={isDashboardModified}
                onClick={() => input.onClick(input.dashboard.dashboardId)}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <span data-testid="dashboard-tab-title">{input.dashboard.title}</span>
              </DashboardTitle>
            </Tooltip>
          </Popover>

          <NewDashboardInput
            onAdd={(title: string) => {
              handleSaveAsNewDashboard(title.trim(), workspace?.dashboards.length);
            }}
            onCancel={() => setShowCopyDashboard(false)}
            isLoading={addDashboardLoading}
            error={copyError}
            visible={showCopyDashboard}
            title="Save As New Dashboard"
            dashboardTitle={getNewDashboardName(workspace?.dashboards, input.dashboard)}
          />

          <NewDashboardInput
            onAdd={(title: string) => handleRenameDashboard(title.trim())}
            onCancel={() => setShowRenameDashboard(false)}
            isLoading={updateDashboardLoading}
            error={renameError}
            visible={showRenameDashboard}
            title="Rename Dashboard"
            dashboardTitle={`${input.dashboard?.title}`}
          />
        </>
      )}
    </Draggable>
  );
}
const DashboardTitle = styled.div`
  height: 100%;
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  color: var(--color-text-80);
  background: #fff;
  border: none;
  font-size: 1.6rem;
  font-style: ${(props) => (props.isModified ? "italic" : "normal")};
  font-weight: 400;
  padding: 0 var(--space-2);
  overflow: hidden;
  cursor: pointer;

  /* vertical divider between tabs */
  & > span::after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(-1 * var(--space-2));
    transform: translateY(-50%);
    width: 1px;
    height: 24px;
    background-color: var(--color-text-10);
  }

  /* white blur on right */
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    transform: translateY(-50%);
    width: var(--space-2);
    background: linear-gradient(
      to right,
      rgba(255 255 255 / 0),
      rgba(255 255 255 / 1) 50%
    );
  }

  /* underline on active tab */
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: opacity 0.15s;
    background-color: var(--color-primary);
  }

  &:hover {
    color: var(--color-text);
  }
  &.nav-active {
    flex-shrink: 0;
    font-weight: 600;
    color: var(--color-text);
  }
  &.nav-active::before {
    opacity: 1;
  }

  & > span {
    white-space: nowrap;
    text-overflow: clip;
    font-style: inherit;
  }
`;

const ContextMenuItem = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);
  cursor: pointer;

  &:hover {
    color: var(--color-text);
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;
