import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import { IMapRequest } from "store/features/map/mapSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { useLocalStorage } from "hooks";

import { generateFilterQuery } from "api/filter";

import { LegendItemModel } from "models/LegendItem";
import { MapScreenshotPolygon } from "models/filter";

import {
  LegendDimensionsT,
  LegendPositionT,
  LegendT,
  ParentDimensionsT
} from "components/chart/legend/Legend";
import getCalculatedLegend from "components/chart/legend/utils/getCalculatedLegend";
import {
  PositionsT,
  getPositionFromChartSettings
} from "components/chart/legend/utils/getPositionFromChartSettings";
import useFilterQuery from "components/filter/hooks/useFilterQuery";
import { useMapContext } from "components/map/hooks/useMapContext";
import useMapUpdater from "components/map/hooks/useMapUpdater";
import { calculateMapScreenshotPolygon } from "components/map/utils/calculateMapScreenshotPolygon";
import { copyMapColors } from "components/map/utils/copyMapColors";
import { useScreenshotContext } from "components/screenshot/hooks";
import { applyAbbreviations } from "components/screenshot/utils";
import { Legend } from "components/ui";
import { useUserSettings } from "components/user/hooks";

const dimensionsStorageKey = `map-legend-dimensions`;
const positionStorageKey = `map-legend-position`;

type LegendWrapperT = {
  width: number;
  parentDimensions: ParentDimensionsT;
  isScreenshotOverlayVisible: boolean;
};

const LegendWrapper: React.FC<LegendWrapperT> = ({
  width = 200,
  parentDimensions = undefined,
  isScreenshotOverlayVisible
}) => {
  // Hooks
  const { mapbox } = useMapContext();
  const { settings } = useScreenshotContext();
  const { getFilterFromQueryScreenshot } = useFilterQuery();
  const { updateMap } = useMapUpdater();
  // const dispatch = useDispatch();
  const { getStoredValue: getStoredDimensions } = useLocalStorage(
    dimensionsStorageKey,
    undefined
  );

  const { getStoredValue: getStoredPosition } = useLocalStorage(
    positionStorageKey,
    undefined
  );
  const { userAbbreviations } = useUserSettings();

  // State
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const wellListFilter = useSelector((state: RootState) => state.filter.wellListFilter);
  const excludePolygonsFilter = useSelector(
    (state: RootState) => state.filter.excludePolygonsFilter
  );
  const propertiesFilter = useSelector(
    (state: RootState) => state.filter.propertiesFilter
  );
  const mapPolygon = useSelector((state: RootState) => state.filter.polygonFilter);
  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const [groupBy, setGroupBy] = useState({ ...globalGroupBy });
  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const activeColorPalette = useSelector(
    (state: RootState) => state.userSetting.activeColorPalette
  );
  const lockedColors = useSelector((state: RootState) => state.app.lockedColors);
  const sortBy = useSelector((state: RootState) => state.filter.sortBy);

  const [groupLegendItems, setGroupLegendItems] = useState([]);

  const [dimensions, setDimensions] = useState<LegendDimensionsT>(() => {
    // Apply change when implementing legendFontSize
    // const itemSize = screenshot.visible ? legendFontSize + 12 : 30; // 30px for chart, 12px gap around when taking screenshot
    const itemSize = 12; // 30px for chart, 12px gap around when taking screenshot
    const itemsHeight = txnId.legend.legendItems.length * itemSize;

    const storedDimensions = getStoredDimensions();
    return storedDimensions ? storedDimensions : { width, height: itemsHeight + 32 }; // 32px for title
  });

  const [position, setPosition] = useState<LegendPositionT>(() => {
    const storedPosition = getStoredPosition();
    const currentPosition = storedPosition
      ? storedPosition
      : getPositionFromChartSettings(dimensions, parentDimensions, {} as PositionsT);
    return currentPosition;
  });

  const [screenshotLegendItems, setScreenshotLegendItems] = useState(null);
  const screenshotBounds = {
    width: (settings?.width || 1152) * (settings?.mapScreenshotScale ?? 1),
    height: (settings?.height || 681) * (settings?.mapScreenshotScale ?? 1)
  };
  const legendFontSize = isScreenshotOverlayVisible
    ? (settings?.legendFontSize ?? 12) * (Number(settings?.mapScreenshotScale) ?? 1)
    : 12;
  const mapScreenshotScale =
    Number(settings?.mapScreenshotScale) < 1 && isScreenshotOverlayVisible
      ? Number(settings?.mapScreenshotScale)
      : 1;

  const updateGroupLegendItems = useCallback(() => {
    if (!isScreenshotOverlayVisible) return;

    let legend = txnId.legend;

    if (screenshotLegendItems && legend) {
      // copy over map legend colors and use well count from wells inside screenshot borders
      const screenshotLegendItemsWithMatchedColors = copyMapColors(
        txnId.legend,
        screenshotLegendItems.legend
      );
      legend = screenshotLegendItemsWithMatchedColors;
    }
    if (!legend) return;

    const legendItems = legend.legendItems
      .filter((f) => f.inFilter && f.count !== 0)
      .map((item) => {
        const formattedTitle = settings?.applyAbbreviations
          ? applyAbbreviations(item.groupTitle, userAbbreviations?.abbreviations ?? [])
          : item.groupTitle;
        const legendItemModel = new LegendItemModel(formattedTitle);
        legendItemModel.inFilter = true;
        legendItemModel.color = item.color;
        legendItemModel.fontColor = "#555555";
        return legendItemModel;
      });
    setGroupLegendItems(legendItems);
  }, [
    isScreenshotOverlayVisible,
    txnId.legend,
    settings?.applyAbbreviations,
    userAbbreviations?.abbreviations,
    screenshotLegendItems,
    propertiesFilter
  ]);

  useEffect(() => {
    setGroupBy({ ...globalGroupBy });
  }, [globalGroupBy]);

  useEffect(updateGroupLegendItems, [updateGroupLegendItems]);

  const getMapRequest = useCallback(
    (filterId, sortBy) => {
      if (!user) {
        return;
      }
      const bin = Object.assign({}, groupBy.bin);
      if (bin.BinSize === "") {
        bin.BinSize = 1;
      }
      if (bin.MinSize === "") {
        bin.MinSize = null;
      }
      if (bin.GreaterThan === "") {
        bin.GreaterThan = null;
      }
      if (bin.LessThan === "") {
        bin.LessThan = null;
      }
      const requestGroupBy = Object.assign({}, groupBy, { bin });
      const request: IMapRequest = {
        UserName: user.email,
        ColorPalette: activeColorPalette,
        ReverseColor: activeColorPalette.reverse,
        Product: ALL_PRODUCT_TYPES.Oil.key,
        LockStyle: false,
        FilterId: filterId,
        SortBy: sortBy,
        HasCheckedBins: false,
        GetBBox: false,
        ShowGroupsNotInFilter: false,
        GroupBy: requestGroupBy,
        ColorLockedItems: lockedColors,
        NormalizeBySetting: Object.assign({}, globalNormalizeBy, {
          useNormalizeBy
        }),
        EntityKind: "Well"
      };
      return request;
    },
    [groupBy, user, activeColorPalette, useNormalizeBy, lockedColors, globalNormalizeBy]
  );

  useEffect(() => {
    async function getFilter(screenshotPolygon: MapScreenshotPolygon) {
      const query = generateFilterQuery(
        wellListFilter,
        mapPolygon,
        excludePolygonsFilter,
        propertiesFilter,
        "",
        screenshotPolygon
      );

      const filter = await getFilterFromQueryScreenshot(query);

      if (!filter?.ok) {
        return;
      }

      const request = getMapRequest(filter.value, sortBy);
      const screenshotLegendItems = await updateMap(request, true);

      setScreenshotLegendItems(screenshotLegendItems);
    }
    if (!mapbox) {
      return;
    }

    const polygonFilter = calculateMapScreenshotPolygon(
      mapbox,
      parentDimensions,
      settings?.showTownshipBorderInScreenshot
    );
    getFilter(polygonFilter);
  }, [
    txnId.legend,
    screenshotBounds.width,
    screenshotBounds.height,
    settings?.showTownshipBorderInScreenshot
  ]);

  const handleDragStop = (_, dragElement) => {
    const newPosition = {
      x: dragElement.x,
      y: dragElement.y
    };

    setPosition(newPosition);
    localStorage.setItem(
      positionStorageKey,
      JSON.stringify({ x: newPosition.x, y: newPosition.y })
    );
  };

  function handleResize(e, direction, ref, delta, p) {
    const nextDimensions = {
      width: ref.offsetWidth,
      height: ref.offsetHeight
    };
    setDimensions(nextDimensions);
    setPosition(p);

    localStorage.setItem(dimensionsStorageKey, JSON.stringify(nextDimensions));
    localStorage.setItem(positionStorageKey, JSON.stringify(p));
  }

  const backgroundOpacity = isScreenshotOverlayVisible
    ? Number(settings?.legendOpacity) ?? 1
    : 1;

  const showLegendCounts = isScreenshotOverlayVisible
    ? settings?.legendShowCounts ?? true
    : true;

  const currentLegend: LegendT = {
    ...position,
    ...dimensions
  };

  const calculatedLegend = getCalculatedLegend(currentLegend, parentDimensions);

  const storedPosition = getStoredPosition();
  const currentPosition = storedPosition
    ? { x: calculatedLegend.x, y: calculatedLegend.y }
    : getPositionFromChartSettings(
        { width: calculatedLegend.width, height: calculatedLegend.height },
        parentDimensions,
        {} as PositionsT
      );

  if (settings && !settings.showLegendInScreenshot) {
    return null;
  }

  return (
    <StyledRnd
      bounds="parent"
      position={{ x: currentPosition.x, y: currentPosition.y }}
      size={{ width: calculatedLegend.width, height: calculatedLegend.height }}
      onDragStop={handleDragStop}
      onResize={handleResize}
      mapScreenshotScale={mapScreenshotScale}
      showLegendBorder={settings?.showLegendBorderInScreenshot}>
      <StyledLegendWrapper backgroundopacity={backgroundOpacity}>
        <Legend
          className="group-map-legend"
          items={groupLegendItems}
          showTitle={true}
          showCounts={showLegendCounts}
          legendLabelSize={legendFontSize}
        />
      </StyledLegendWrapper>
    </StyledRnd>
  );
};

//Styled components
const StyledRnd = styled(Rnd)`
  display: grid;
  overflow: hidden;
  min-height: ${(props) => props?.mapScreenshotScale * 150 ?? 150}px;
  min-width: ${(props) => props?.mapScreenshotScale * 150 ?? 150}px;
  border: ${(props) => (props.showLegendBorder ? "1px solid gray" : "")};

  & > div:last-child {
    position: unset;
  }
  position: relative;
  z-index: 9999; /* Set a high z-index value to ensure legend is on top within the screenshot component. */
`;

const StyledLegendWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0;
  display: inline-flex;
  flex-direction: column;
  background: ${(props) =>
    props.backgroundopacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundopacity})`};
  overflow: hidden auto;
`;

export default LegendWrapper;
