// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiGrid } from "@mdi/js";

import { updateXDASettings, useVisState } from "../../context";
import XdaToolbarButton from "./XdaToolbarButton";

function XdaGridToggle() {
  const [{ xda }, visDispatch] = useVisState();
  const { showGrid } = xda.settings;

  const handleToggle = (active: boolean) => {
    const nextSettings = { ...xda.settings, showGrid: active };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaToolbarButton
      icon={<Icon path={mdiGrid} size={1.5} />}
      active={showGrid}
      tooltipText={"Show Grid"}
      overflowLabel={"Grid Toggle"}
      onToggle={handleToggle}
    />
  );
}

export default XdaGridToggle;
