import { MinMaxT } from "../ChartWrapper";
import { BaseChart } from "./baseChart";

export class BaseDeclineRate extends BaseChart {
  getDefaultAxisMinMax(): MinMaxT {
    return {
      xMin: null,
      xMax: null,
      yMin: 0,
      yMax: 80
    };
  }

  getDefaultAxis(sessionMinMax?: MinMaxT): MinMaxT {
    const defaultMinMax = this.getDefaultAxisMinMax();
    if (!sessionMinMax) {
      return defaultMinMax;
    }
    const yMax = !isNaN(parseFloat(sessionMinMax?.yMax?.toString()))
      ? parseFloat(sessionMinMax?.yMax?.toString())
      : defaultMinMax.yMax;
    const yMin = !isNaN(parseFloat(sessionMinMax?.yMin?.toString()))
      ? parseFloat(sessionMinMax?.yMin?.toString())
      : defaultMinMax.yMin;
    const xMax = !isNaN(parseFloat(sessionMinMax?.xMax?.toString()))
      ? parseFloat(sessionMinMax?.xMax?.toString())
      : defaultMinMax.xMax;
    const xMin = !isNaN(parseFloat(sessionMinMax?.xMin?.toString()))
      ? parseFloat(sessionMinMax?.xMin?.toString())
      : defaultMinMax.xMin;
    return {
      xMin,
      xMax,
      yMin,
      yMax
    };
  }
}
