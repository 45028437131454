import { toast } from "react-toastify";

import axios, { AxiosResponse } from "axios";

import { FilterModel, IQuery } from "models/filter";

import { Result } from "./dataSource";
import { MapExtent } from "./useTownshipRangeGrid";

const filterServiceEndPoint = process.env.REACT_APP_FILTER_SERVICE;
const dataServiceEndPoint = process.env.REACT_APP_DATA_ROOT;
let mapBoxFilter;

async function getPlaysList() {
  const playsUrl = `${dataServiceEndPoint}/api/v1/data/plays`;
  const response = await axios.get(playsUrl);
  if (response.status !== 200) {
    return null;
  }
  const data = response.data;
  return data.sort();
}

let controller: AbortController = null;
async function getFilterFromQuery(node): Promise<Result<string>> {
  try {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    const response = await axios.post(filterServiceEndPoint, node, {
      signal: controller.signal
    });
    if (response.status !== 200) {
      return {
        ok: false,
        error: response?.data ?? "We're sorry, an error occurred."
      };
    }
    return {
      ok: true,
      value: response.data
    };
  } catch (err) {
    if (axios.isCancel(err)) {
      return {
        ok: false,
        error: "cancelled"
      };
    }
    if (!err.response || err.response.status !== 401) {
      if (err.response && err.response.status === 503) {
        toast.error(
          "We're sorry, EVA is unable to communicate with backend services at this moment."
        );
      } else {
        toast.error("An error occured while filtering.");
      }
    }

    return {
      ok: false,
      error: err?.response?.data ?? "We're sorry, an error occurred."
    };
  }
}

async function getWellListFilter(wellList) {
  if (wellList.length === 0) return;
  const node = {
    job_id: "test",
    user_id: "test1",
    query: {
      operator: "and",
      predicates: [
        {
          type: "Property",
          property: "UniqueId",
          operator: "in",
          value: wellList
        }
      ],
      children_nodes: []
    }
  };

  const response = await axios.post(filterServiceEndPoint, node);

  return response.data;
}

async function getFilter(queryObj) {
  const localQueryObj = JSON.parse(JSON.stringify(queryObj));
  //localQueryObj.forEach((filter) => deconst e filter.id);

  const fullQueryObj = {
    operator: "and",
    predicates: [...localQueryObj, mapBoxFilter],
    children_nodes: []
  };

  const node = {
    job_id: "test",
    user_id: "test1",
    query: fullQueryObj
  };

  const response = await axios.post(filterServiceEndPoint, node);

  return response.data;
}

function generateFilterQuery(
  wellList = [],
  polygon,
  excludePolygons,
  properties,
  previousFilterId,
  screenshotPolygon
): FilterModel {
  const predicates = [];
  let children = [];
  const operator = "and";
  for (const filter of wellList) {
    predicates.push(filter);
  }
  if (polygon) {
    predicates.push(polygon);
  }
  if (screenshotPolygon) {
    predicates.push(screenshotPolygon);
  }
  if (excludePolygons && excludePolygons.length > 0) {
    predicates.push(...excludePolygons);
  }
  if (properties && properties.length > 0) {
    const byPropPredicates = {};
    properties.forEach((prop) => {
      const predicates = prop.predicates;
      predicates.forEach((pred) => {
        const property = pred.property;
        if (!(property in byPropPredicates)) {
          byPropPredicates[property] = [];
        }
        byPropPredicates[property].push(pred);
      });
    });
    //child is AND with a bunch of OR child predicates
    children = Object.keys(byPropPredicates).map((prop) => {
      const pred = byPropPredicates[prop];
      const first = pred.length > 0 ? pred[0] : null;

      const operator =
        first != null &&
        (first.negatedOperator ||
          first.operator === "nin" ||
          first.operator === "!=" ||
          first.operator.startsWith("not "))
          ? "and"
          : "or";

      return {
        operator,
        predicates: byPropPredicates[prop],
        children_nodes: []
      };
    });
  }
  const query: IQuery = {
    operator,
    predicates,
    children_nodes: children
  };

  return {
    // The job_id and user_id are part of legacy code that's not used anymore.
    job_id: "test",
    user_id: "test1",
    query,
    previousFilterId: previousFilterId,
    entityKind: "Well"
  };
}
function getFilterCountsFromFilterId(filterId) {
  const endpoint = `${filterServiceEndPoint}/counts/${filterId}`;
  return axios.get(endpoint);
}

export interface UwiInfo {
  formattedId: string;
  uniqueId: string;
  sortingId: string;
}
export interface WellListResult {
  uwiList: UwiInfo[];
  boundingBox: MapExtent;
}
function getWellsFromFilterId(filterId): Promise<AxiosResponse<WellListResult>> {
  if (!filterId) {
    return undefined;
  }
  const endpoint = `${dataServiceEndPoint}/api/v1/uwi/${filterId}`;

  return axios.get(endpoint);
}
function getUniqueIdsFromFilterId(filterId) {
  const endpoint = `${filterServiceEndPoint}/${filterId}`;
  return axios.get(endpoint);
}

function haveDifferentWells(filterId1, filterId2) {
  const endpoint = `${filterServiceEndPoint}/different-wells/${filterId1}/${filterId2}`;
  return axios.get(endpoint);
}

export {
  getWellListFilter,
  getFilter,
  generateFilterQuery,
  getFilterFromQuery,
  haveDifferentWells,
  getUniqueIdsFromFilterId,
  getFilterCountsFromFilterId,
  getWellsFromFilterId,
  getPlaysList
};
