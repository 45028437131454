import { useQuery } from "react-query";

import axios from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { Scenario } from "../models/scenario";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

export const GetScenarioListKey = "ScenarioList";
const fetchSettings = async () => {
  const response = await axios.get<Scenario[]>(API_URL);
  return response.data;
};

const useGetScenarioList = () => {
  return useQuery<Scenario[]>(GetScenarioListKey, fetchSettings, {
    refetchOnWindowFocus: false
  });
};

export default useGetScenarioList;
