import { AnyLayer } from "mapbox-gl";

import { IOrderedLayer } from "../useManageMapboxLayerSorting";

interface IGetMapLayersMatchingWithLayerListParams {
  mapboxLayers: AnyLayer[];
  layerList: IOrderedLayer[];
}

/**
 * Retrieves the mapbox layers that match the layer list, potentially unordered,
 * so they can be used to compared to the ordered layer list.
 * @param params
 * @returns
 */
export function getMapLayersMatchedToLayerList(
  params: IGetMapLayersMatchingWithLayerListParams
): IOrderedLayer[] {
  const { mapboxLayers, layerList } = params;

  const matchedMapLayers = mapboxLayers
    .filter((layer) => {
      return layerList.some(
        (orderedLayer) =>
          orderedLayer.name === layer.id ||
          // handle including shapefile stroke etc. E.g {shapefileId}-stroke
          orderedLayer.projectShapefileId?.includes(layer.id.split("-")[0]) ||
          orderedLayer.shapefileId?.includes(layer.id.split("-")[0])
      );
    })
    .map((layer, index) => ({ name: layer.id, order: index }));

  return matchedMapLayers;
}
