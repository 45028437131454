import { IScreenshotSettings } from "models/screenshot";

import { copyChartToClipboard } from "components/chart/helpers";
import { copyMapToClipboard } from "components/map/utils";
import { copyMPCToClipboard } from "components/multiphase-chart/util";
import { copyRFvsPVChartToClipboard, copySamChartToClipboard } from "components/sam/util";
import { copyThreeDToClipboard, copyXDAToClipboard } from "components/vis/util";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const copyToClipboard = (
  widgetType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widgetState: any,
  settings: IScreenshotSettings,
  screenshotOverlay: HTMLDivElement
): Promise<void> => {
  switch (widgetType) {
    case "chart":
      return copyChartToClipboard(screenshotOverlay);
    case "map":
      return copyMapToClipboard(widgetState, settings);
    case "sam-rf-pv":
      return copyRFvsPVChartToClipboard(screenshotOverlay);
    case "sam":
      return copySamChartToClipboard(screenshotOverlay);
    case "mpc":
      return copyMPCToClipboard(screenshotOverlay);
    case "threeDViewer":
      return copyThreeDToClipboard(screenshotOverlay);
    case "xdaViewer":
      return copyXDAToClipboard(screenshotOverlay);
    default:
      return Promise.reject(`Unsupported widget type`);
  }
};

export default copyToClipboard;
