// get distance between two points
function getDistanceBetweenPoints(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): string {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2)).toFixed(2);
}

export default getDistanceBetweenPoints;
