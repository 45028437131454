import { Select } from "antd";
import styled from "styled-components";

import {
  IGNORE_METADATA_FILTERS_FOR_GROUPS,
  useGroupByState,
  useGroupByUpdater
} from "./group-by.context";

function MetadataFilterToolbar() {
  const { selectedCategory, selectedMetadataFilters } = useGroupByState();

  const updateGroupByState = useGroupByUpdater();

  const changeSelectedMetadataFilter = (key: string, value: string) => {
    return updateGroupByState({
      type: "selected_metadata_filter",
      payload: {
        key,
        value
      }
    });
  };

  if (
    selectedCategory?.metadataFilters?.length === 0 ||
    IGNORE_METADATA_FILTERS_FOR_GROUPS.includes(selectedCategory?.name)
  ) {
    return null;
  }

  return (
    <Wrapper>
      {selectedCategory?.metadataFilters?.map((filter) => {
        const selectedValue = selectedMetadataFilters[filter.key] || undefined;
        // if the selectedValue is not in the filter options, we set it to undefined
        if (selectedValue && !filter.options.includes(selectedValue)) {
          changeSelectedMetadataFilter(filter.key, undefined);
        }
        return (
          <SelectWrapper key={filter.key}>
            <label>{filter.name}</label>
            <Select
              data-testid={`metadata-select-${filter.key}`}
              showSearch={true}
              allowClear={true}
              value={selectedValue}
              onChange={(value) => changeSelectedMetadataFilter(filter.key, value)}
              options={filter.options.map((option) => ({ label: option, value: option }))}
              popupClassName="modal-select"
              placeholder="All"
            />
          </SelectWrapper>
        );
      })}
    </Wrapper>
  );
}

export default MetadataFilterToolbar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 var(--space-4);
`;
const SelectWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  .ant-select {
    width: 100%;
  }
`;
