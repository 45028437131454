import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import axios from "axios";

function useFilterQuery() {
  const [controller, setController] = useState(null);

  useEffect(() => {
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [controller]);

  const filterServiceEndPoint = process.env.REACT_APP_FILTER_SERVICE;

  const getFilterFromQueryScreenshot = async (node) => {
    try {
      if (controller) {
        controller.abort();
      }
      const newController = new AbortController();
      setController(newController);
      const response = await axios.post(filterServiceEndPoint, node, {
        signal: newController.signal
      });
      if (response.status !== 200) {
        return {
          ok: false,
          error: response?.data ?? "We're sorry, an error occurred."
        };
      }
      return {
        ok: true,
        value: response.data
      };
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          ok: false,
          error: "cancelled"
        };
      }
      if (!err.response || err.response.status !== 401) {
        if (err.response && err.response.status === 503) {
          toast.error(
            "We're sorry, EVA is unable to communicate with backend services at this moment."
          );
        } else {
          toast.error("An error occured while filtering.");
        }
      }

      return {
        ok: false,
        error: err?.response?.data ?? "We're sorry, an error occurred."
      };
    }
  };

  return { getFilterFromQueryScreenshot };
}

export default useFilterQuery;
