import { FunctionComponent, useCallback, useEffect, useState } from "react";
import DataGrid from "react-data-grid";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";

import { Error } from "@material-ui/icons";
import classnames from "classnames";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { useUser } from "hooks";

import useExportChartDataMutation from "api/useExportChartDataMutation";

import { useChartState } from "components/chart/context";
import { useDataTableData } from "components/chart/hooks";
import { IconSpinner } from "components/icons";

import { ExportButton } from ".";
import useBetaFeatures from "../../../../hooks/useBetaFeatures";
import { EntityKind } from "../../../../models/entityKind";

export const DataTableChartTypes: string[] = [
  ALL_CHART_TYPES.Probit.label,
  ALL_CHART_TYPES.CrossPlot.label,
  ALL_CHART_TYPES.RateCum.label,
  ALL_CHART_TYPES.RateDate.label,
  ALL_CHART_TYPES.CAGR.label,
  ALL_CHART_TYPES.BaseDeclineRate.label,
  ALL_CHART_TYPES.RateTime.label,
  ALL_CHART_TYPES.CumTime.label,
  ALL_CHART_TYPES.TotalRateDate.label,
  ALL_CHART_TYPES.BoxPlot.label,
  ALL_CHART_TYPES.TotalRateCum.label,
  ALL_CHART_TYPES.MaterialBalanceTime.label,
  ALL_CHART_TYPES.TrendDate.label,
  ALL_CHART_TYPES.StackedBar.label
];

const rdgThemeClassName = "rdg-light";

const exportFile = (response, fileName) => {
  const encodedUri = URL.createObjectURL(response);
  const link = document.createElement("a");
  link.href = encodedUri;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
type ChartDataTableT = {
  xMinMax;
};
export const ChartDataTable: FunctionComponent<ChartDataTableT> = ({ xMinMax }) => {
  // state
  const [exportError, setExportError] = useState<string | null>();
  const [heightHasScrollbar, setHeightHasScrollbar] = useState(true);

  // state from store
  const hoverLegendItem: string = useSelector(
    (state: RootState) => state.groupBy.hoverLegendItem
  );

  // context & hooks
  const { loading, title, rows, columns, sources } = useDataTableData(xMinMax);
  const { request, settings, entityKind } = useChartState();
  const { isReadonly, hasTrialAccount } = useUser();
  const { hasFeature } = useBetaFeatures();
  const canExportMidstream =
    entityKind == EntityKind.Facility && hasFeature("Midstream Export");
  // derived state
  const canExport =
    !isReadonly &&
    !hasTrialAccount &&
    (entityKind == EntityKind.Well || canExportMidstream);

  // mutations
  const exportChartDataMutation = useExportChartDataMutation(
    (response) => {
      // eslint-disable-next-line no-console
      setExportError(null);

      exportFile(response.data, `EVA ${title}.csv`);
    },
    (err) => {
      setExportError("Failed to export chart data");
      // eslint-disable-next-line no-console
      console.error("Failed to export chart data", err);
    }
  );

  const exportChartDataTable = useCallback(async () => {
    if (!request) return;

    request.axisMinMax = { ...request.axisMinMax, ...xMinMax };
    await exportChartDataMutation.mutateAsync(request);
  }, [exportChartDataMutation, request, xMinMax]);

  // determine if there is a vertical scrollbar, if there is widthHasScrollBar will be checked to reduce the width of the colunms to account for the room taken up by the scroll bar
  const dataGrid = document.querySelector(".rdg");
  useEffect(() => {
    if (dataGrid) {
      setHeightHasScrollbar(dataGrid.scrollHeight > dataGrid.clientHeight);
    }
  }, [dataGrid, dataGrid?.scrollHeight]);

  if (loading) {
    return (
      <Wrapper data-testid="loading">
        <StyledSpinner />
      </Wrapper>
    );
  }

  // for setting light theme by default
  const gridClasses = classnames(rdgThemeClassName);

  return (
    <Wrapper data-testid="loaded">
      <ChartTitle>{title}</ChartTitle>
      <DataSourceHeading>Data Sources</DataSourceHeading>
      <DataSourcesWrapper>
        {" "}
        Production:
        {`${sources?.productionDataSources.map((source) => {
          return ` ${source}`;
        })}`}
        <br /> Forecast:
        {`${sources?.forecastSources.map((source) => {
          return ` ${source}`;
        })}`}
      </DataSourcesWrapper>
      <DataTableWrapper data-testid={settings.chartType}>
        <div style={{ height: "100%" }}>
          <AutoSizer>
            {({ width, height }) => (
              <StyledGrid
                className={gridClasses}
                columns={columns.map((column) => ({
                  ...column,
                  resizable: true,
                  minWidth: 50,
                  width: heightHasScrollbar
                    ? Number(width - 10) / columns.length
                    : Number(width) / columns.length
                }))}
                rows={rows}
                rowClass={(row) => {
                  if (hoverLegendItem && row?.SeriesFull?.startsWith(hoverLegendItem)) {
                    return "row-selected";
                  }
                  return undefined;
                }}
                style={{
                  width: `${Number(width)}px`,
                  height: `${Number(height)}px`
                }}
              />
            )}
          </AutoSizer>
        </div>
      </DataTableWrapper>
      {canExport && (
        <DataTableActionsWrapper>
          {exportError && (
            <ErrorContainer>
              <Error style={{ top: 3 }} /> {exportError}
            </ErrorContainer>
          )}
          <ExportButton
            exporting={exportChartDataMutation?.isLoading}
            onExport={exportChartDataTable}>
            Export To CSV
          </ExportButton>
        </DataTableActionsWrapper>
      )}
    </Wrapper>
  );
};

const StyledSpinner = styled(IconSpinner)`
  align-self: flex-start;
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: #fff;
  padding: 10px;
`;

const ChartTitle = styled.div`
  flex-grow: 0;
  text-transform: uppercase;
  font-weight: 600;
`;

const DataSourceHeading = styled.div`
  flex-grow: 0;
  font-weight: 600;
`;

const DataSourcesWrapper = styled.div``;

const DataTableWrapper = styled.div`
  flex-grow: 1;

  .rdg {
    .row-selected,
    .row-selected:hover {
      background-color: #cdcdcd;
    }
  }
`;

const DataTableActionsWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
`;

const StyledGrid = styled(DataGrid)``;

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;
