// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { mdiContentCopy } from "@mdi/js";
import { Button, Popover, Table, Typography } from "antd";
import styled from "styled-components";

import { RootState } from "../../store/rootReducer";
import useGetFacilityNetworkFraction from "../map/hooks/useGetFacilityNetworkFraction";

const { Title } = Typography;
export default function WellContributionTable() {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const selectedFacilities = useSelector(
    (state: RootState) => state.map.selectedFacilities
  );
  const { data } = useGetFacilityNetworkFraction(
    Object.keys(selectedFacilities).length > 0 ? Object.keys(selectedFacilities)[0] : ""
  );

  if (Object.keys(selectedFacilities).length == 0) {
    return null;
  }

  function formatNumberWithThousandSeparator(number, decimalPlaces) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    });
  }

  const wellContributions = (data ?? []).map((item) => ({
    uwi: item.wellName,
    value: (Math.round(item.gasPct * 10) / 10).toFixed(1),
    distance: item.nodeDistance,
    gasVolume: formatNumberWithThousandSeparator(item.gasMcf * 0.001, 2)
  }));
  const copyTable = async () => {
    if (!navigator.clipboard) {
      toast.error("Clipboard copy is not supported by the browser.");
    }
    if (!tableRef.current) {
      toast.error("Table is not available.");
    }
    const spreadSheetRow = new Blob([tableRef.current.outerHTML], {
      type: "text/html"
    });
    navigator.clipboard
      .write([new ClipboardItem({ "text/html": spreadSheetRow })])
      .then(() => {
        setCopiedToClipboard(true);
        setTimeout(() => {
          setCopiedToClipboard(false);
        }, 2000);
      })
      .catch(() => setCopiedToClipboard(false));
  };
  return (
    <Root>
      <Wrapper>
        <Title level={5}>Current Month Well Contribution</Title>
        <Popover
          open={copiedToClipboard}
          trigger="click"
          content={<div>Copied to clipboard.</div>}>
          <Button onClick={copyTable} icon={<Icon path={mdiContentCopy} size={1.2} />}>
            Copy Table
          </Button>
        </Popover>
      </Wrapper>
      <Table
        size={"small"}
        ref={tableRef}
        dataSource={wellContributions}
        pagination={false}
        columns={[
          {
            title: "UWID",
            dataIndex: "uwi",
            key: "uwi"
          },
          {
            title: "Gas (MMcf)",
            dataIndex: "gasVolume",
            key: "gasVolume",
            align: "right"
          },
          {
            title: "Percent",
            dataIndex: "value",
            key: "value",
            align: "right"
          },
          {
            title: "Nodal Distance",
            dataIndex: "distance",
            key: "distance",
            width: 150,
            align: "right"
          }
        ]}></Table>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;

  .ant-table-wrapper {
    font-family: var(--fontMono);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;
