import { useEffect } from "react";

import styled from "styled-components";

import { BaseButton } from "components/base";

import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";

export default function BaseLayerToggle() {
  const { mapbox, baseLayer } = useMapContext();
  const mapDispatch = useMapDispatch();
  useEffect(() => {
    if (!mapbox) {
      return;
    }
    const layer = baseLayer;
    if (layer === "Street") {
      mapbox.setStyle("mapbox://styles/mapbox/streets-v11");
    } else if (layer === "Satellite") {
      mapbox.setStyle("mapbox://styles/mapbox/satellite-v9");
    } else if (layer === "Terrain") {
      mapbox.setStyle("mapbox://styles/hoda99/ckl5ypgg102e817s5wbnqcly6");
    }
  }, [baseLayer]);

  if (!mapbox) {
    return null;
  }
  return (
    <BaseLayerButtonContainer>
      <BaseButton
        appearance={baseLayer === "Street" ? "primary" : "stroked"}
        onClick={() =>
          mapDispatch({
            payload: {
              baseLayer: "Street"
            }
          })
        }>
        Map
      </BaseButton>
      <BaseButton
        appearance={baseLayer === "Satellite" ? "primary" : "stroked"}
        onClick={() =>
          mapDispatch({
            payload: {
              baseLayer: "Satellite"
            }
          })
        }>
        Satellite
      </BaseButton>
      <BaseButton
        appearance={baseLayer === "Terrain" ? "primary" : "stroked"}
        onClick={() =>
          mapDispatch({
            payload: {
              baseLayer: "Terrain"
            }
          })
        }>
        Terrain
      </BaseButton>
    </BaseLayerButtonContainer>
  );
}
const BaseLayerButtonContainer = styled.div`
  position: absolute;
  bottom: 2px;
  left: 50%;
  margin-left: -100px;
  background: #eee;
  opacity: 0.9;
`;
