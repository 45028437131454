import { memo, useEffect } from "react";

import { CHART_ACTION } from "constants/chart.constants";

import LassoIcon from "components/icons/Lasso";

import {
  toggleLassoOFF,
  toggleLassoON,
  updateBrushEndPosition,
  updateBrushedList,
  useChartDispatch,
  useChartState
} from "../../context";
import { useLasso } from "../../hooks";
import ToolbarButton from "../ToolbarButton";

function LassoBrush() {
  // context
  const dispatch = useChartDispatch();
  const { instance, lasso, options, response } = useChartState();

  // custom hook
  const isAllowed = useLasso();

  const handleLassoToggle = () => (isActive) => {
    instance.setOption({
      tooltip: { show: !isActive, showContent: !isActive }
    });

    if (isActive) {
      toggleLassoON(dispatch);
      updateBrushedList(dispatch, []);
      instance.dispatchAction(CHART_ACTION.brushStart);
    } else {
      toggleLassoOFF(dispatch);
      instance.dispatchAction(CHART_ACTION.brushClear);
      instance.dispatchAction(CHART_ACTION.brushEnd);
      updateBrushEndPosition(dispatch, null);
    }
  };

  // keep lasso ON between chart updates
  useEffect(() => {
    if (!lasso) return;
    handleLassoToggle()(lasso);
  }, [lasso, options, response]);

  return (
    <ToolbarButton
      icon={<LassoIcon />}
      active={lasso}
      disabled={!isAllowed}
      overflowLabel="Lasso selection"
      tooltipText="Lasso selection"
      onToggle={handleLassoToggle()}
    />
  );
}

export default memo(LassoBrush);
