// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { mdiEye, mdiEyeOff } from "@mdi/js";
import { Button } from "antd";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import styled from "styled-components/macro";

import { EntityKind } from "models/entityKind";

import Sync from "components/sync/Sync";

import { DataSourceSelect, NormalizeSelect, TimeStepSelect } from ".";
import useBetaFeatures from "../../../hooks/useBetaFeatures";
import { setActiveEntities } from "../../../store/features";
import { RootState } from "../../../store/rootReducer";

interface ProductionButtonGroupProps {
  entityKind: EntityKind;
}

const ProductionButtonGroup = ({ entityKind }: ProductionButtonGroupProps) => {
  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);
  const dispatch = useDispatch();
  const { hasFeature } = useBetaFeatures();
  const active = activeEntities.includes(entityKind);
  return (
    <Wrapper>
      {hasFeature("Facility") && (
        <StyledButton
          data-testid={`production-button-set-active-entity-${entityKind.toLowerCase()}`}
          type={"default"}
          icon={<Icon path={active ? mdiEye : mdiEyeOff} />}
          onClick={(checked) => {
            if (checked && !activeEntities.includes(entityKind)) {
              dispatch(setActiveEntities([...activeEntities, entityKind]));
            } else {
              dispatch(
                setActiveEntities(
                  activeEntities.filter((activeEntity) => activeEntity !== entityKind)
                )
              );
            }
          }}
        />
      )}
      {IS_INTERNAL_ENV && entityKind === EntityKind.Well && <Sync />}
      <DataSourceSelect />
      <TimeStepSelect />
      <NormalizeSelect />
    </Wrapper>
  );
};

export default ProductionButtonGroup;
const StyledButton = styled(Button)`
  color: #a2aaad;
  &:hover {
    color: var(--color-text);
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
  }
`;
