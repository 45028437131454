import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { PROJECT_SERVICE_ADDRESS } from "../../../../../constants/app.constants";

const API_URL = `${PROJECT_SERVICE_ADDRESS}/shapefile/export`;

export interface ShapefileExportRequest {
  shapefileId: string;
  type: "shapefile" | "folder";
}

const postExport = async ({ shapefileId, type }: ShapefileExportRequest) => {
  const response = await axios.post<Blob>(
    type == "shapefile" ? `${API_URL}/${shapefileId}` : `${API_URL}/tree/${shapefileId}`,
    null,
    {
      responseType: "blob",
      headers: {
        "Content-Type": "application/zip"
      }
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to download the file");
  }
  const checksumHeader = response.headers["x-checksum"];
  if (!checksumHeader) {
    throw new Error("Checksum header not found");
  }
  const hashBuffer = await crypto.subtle.digest(
    "SHA-256",
    await response.data.arrayBuffer()
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  if (hashHex !== checksumHeader) {
    throw new Error("Checksum mismatch");
  }
  const url = window.URL.createObjectURL(new Blob([response.data]));
  // Create an anchor element and set its href attribute to the created URL
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `shapefiles.zip`); // Set the file name
  // Append the anchor to the body
  document.body.appendChild(link);

  // Programmatically click the anchor to trigger the download
  link.click();

  // Clean up by removing the anchor and revoking the created URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const useExportShapefile = () => {
  return useMutation<unknown, AxiosError, ShapefileExportRequest, unknown>(postExport);
};

export default useExportShapefile;
