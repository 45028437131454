import html2canvas from "html2canvas";

const copyMPCToClipboard = (copyOverlay: HTMLDivElement): Promise<void> => {
  const selector = document.querySelector(".fullscreen .ChartPlot")
    ? ".fullscreen .ChartPlot"
    : ".App .chart-wrapper > div > .ChartPlot";

  if (copyOverlay) {
    copyOverlay.style.border = "none";
  }

  return html2canvas(document.querySelector(selector)).then((canvas) => {
    return canvas.toBlob((blob) => {
      if (!blob) return;
      if (!navigator.clipboard) {
        return;
      }
      const data = [new ClipboardItem({ "image/png": blob })];

      return navigator.clipboard.write(data);
    });
  });
};

export default copyMPCToClipboard;
