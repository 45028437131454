import { useQuery } from "react-query";

import { ApiResult } from "../api/apiResult";
import { fetchPlayTo3DDataPrivateSources } from "../api/ipdp";

///hook to fetch ipdb fields
export default function usePlayTo3DDataPrivateSources(): ApiResult<string[]> {
  const { data, isLoading, isError, refetch } = useQuery<string[]>(
    "fetchPlayTo3DDataPrivateSources",
    fetchPlayTo3DDataPrivateSources,
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
