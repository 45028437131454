import { Divider, Space } from "antd";
import styled from "styled-components/macro";

const NormalizeByWrapper = ({ children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <StyledSpace direction="vertical" split={<StyledDivider />}>
        {children}
      </StyledSpace>
    </Wrapper>
  );
};

export default NormalizeByWrapper;

const Wrapper = styled.div`
  width: 350px;
  max-width: 350px;
  background: var(--normalize-section-bg, #ffffff);
  font-weight: 500;
  padding: 20px 20px;
  text-align: left;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;
