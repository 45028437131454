import { useDispatch, useSelector } from "react-redux";

import {
  ATTENTION_WELL_LAYER,
  SELECTED_SINGLE_WELL_LAYER,
  SELECTED_WELL_LAYER,
  SELECTED_WELL_POINT_LAYER,
  SHAPEFILE_LABELS_LAYER,
  SYNC_WELL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import { setLayers } from "store/features";
import { RootState } from "store/rootReducer";

import { shapefileLayerTypes } from "components/project/shapefiles/shapefiles.constants";

/**
 * Updates the visibility of the redux.map.layers based on the checked layers keys.
 * Once the layers have been set, the mapbox layers are updated in the useManageMapboxLayerStyles hook.
 * @returns
 */
export function useUpdateMapLayersVisibility() {
  const dispatch = useDispatch();
  const mapLayers = useSelector((state: RootState) => state.map.layers);

  function updateMapLayersVisibility(checkedLayerKeys: string[]) {
    const mapLayersWithUpdatedVisibility = getMapLayersWithUpdatedVisibility(
      checkedLayerKeys,
      mapLayers
    );

    dispatch(setLayers(mapLayersWithUpdatedVisibility));
  }

  return { updateMapLayersVisibility };
}

export function getMapLayersWithUpdatedVisibility(
  checkedLayerKeys: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapLayers: any[]
) {
  const checkedLayersSet = new Set(checkedLayerKeys);
  const mapLayersCopy = JSON.parse(JSON.stringify(mapLayers));

  for (const layer of mapLayersCopy) {
    // If the layer is in the checkedLayers or is a stroke layer with its corresponding fill layer checked
    const isLayerVisible =
      checkedLayersSet.has(layer.id) ||
      checkedLayersSet.has(layer.id.replace(shapefileLayerTypes.stroke, "")) ||
      checkedLayersSet.has(layer.id.replace(shapefileLayerTypes.point, "")) ||
      (checkedLayersSet.has(layer.id.replace(shapefileLayerTypes.property, "")) &&
        checkedLayersSet.has(SHAPEFILE_LABELS_LAYER));

    // Disable visibility for dependant layers
    const isWellLayerVisible =
      [WELL_LAYER_POINT, ATTENTION_WELL_LAYER].includes(layer.id) &&
      checkedLayersSet.has(WELL_LAYER);

    const isSelectedWellVisible =
      [SELECTED_WELL_POINT_LAYER, SYNC_WELL_LAYER, SELECTED_SINGLE_WELL_LAYER].includes(
        layer.id
      ) && checkedLayersSet.has(SELECTED_WELL_LAYER);

    layer.isVisible = isLayerVisible || isWellLayerVisible || isSelectedWellVisible;
  }

  return mapLayersCopy;
}
