import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { Scenario } from "../models/scenario";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

const updatePadScenario = async (data: Scenario) => {
  const response = await axios.put(API_URL, data);
  return response.data;
};

const useUpdateScenario = () => {
  return useMutation<unknown, AxiosError, Scenario, unknown>(updatePadScenario);
};

export default useUpdateScenario;
