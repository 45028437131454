import { SHAPEFILE_LABELS_LAYER, dependantLayers } from "constants/mapLayers.constants";

import { shapefileLayerTypes } from "components/project/shapefiles/shapefiles.constants";

import { IOrderedLayer } from "../useManageMapboxLayerSorting";

interface IGetLayerListWithShapefilesFromMapbox {
  mapboxLayers: IOrderedLayer[];
  layerList: IOrderedLayer[];
}

/**
 * This gives us source of truth for the order, based on the layer list query, and the mapbox layers.
 *
 * This adds the different property layers of shapefiles that are loaded into mapbox, with its dependant shapefile from the layer list.
 *
 * Example: The layer list query only contains a record for the shapefile fill (e.g xyzUniqueShapefileId).
 * If a shapefile has a stroke, point, or property layer, then that is loaded dynamically into mapbox on the front end (e.g xyzUniqueShapefileId-stroke),
 * and we then need merge those 'missing' layers that are not included in the query, while maintaining the order.
 * ^
 * Exception: The property layer is being controlled by the layer list by the "Shapefile Labels" layer for now. So we're
 * match the shapefile-property layer to the "Shapefile Labels" layer. If we add multiple property layers per shapefile, than we
 * can get rid of the explicit match, and add the property back into addShapefileLayers.
 *
 * This also adds map layers that are not included in the layer list query, but are dependant of layers that are,
 * and also confirms that the dependant layers are present in the mapbox.
 *
 * Example: The vertical well layer, is dependant on the well layer position, and is not included in the layer list query.
 *
 * @param params
 * @returns
 */
export function getLayerListMergedWithMapbox(
  params: IGetLayerListWithShapefilesFromMapbox
): IOrderedLayer[] {
  const { mapboxLayers, layerList } = params;

  const orderedLayers: { name: string; order: number }[] = [];

  layerList.forEach((layer) => {
    const shapefileIdProperty = layer.projectShapefileId
      ? "projectShapefileId"
      : "shapefileId";

    const layerName = layer[shapefileIdProperty] || layer.name;

    if (layer?.[shapefileIdProperty]) {
      addShapefileLayers({ orderedLayers, mapboxLayers, layerName });
      //Refer to function notes, should only be one shapefile with a property layer
    } else if (layerName === SHAPEFILE_LABELS_LAYER) {
      addShapefilePropertyLayer({ orderedLayers, mapboxLayers });
    } else if (dependantLayers.includes(layerName)) {
      addDependantMapLayer({ orderedLayers, mapboxLayers, layerName });
    } else {
      orderedLayers.push({ name: layerName, order: undefined });
    }
  });

  orderedLayers.forEach((layer, index) => {
    layer.order = index;
  });

  return orderedLayers;
}

function addShapefileLayers(params: {
  orderedLayers: { name: string; order: number }[];
  mapboxLayers: IOrderedLayer[];
  layerName: string;
}) {
  const { orderedLayers, mapboxLayers, layerName } = params;

  [
    shapefileLayerTypes.fill,
    shapefileLayerTypes.point,
    shapefileLayerTypes.stroke
  ].forEach((type) => {
    const matchedLayer = mapboxLayers.find((l) => l.name === `${layerName}${type}`);

    if (matchedLayer) {
      orderedLayers.push({ name: matchedLayer.name, order: undefined });
    }
  });
}

function addShapefilePropertyLayer(params: {
  orderedLayers: IOrderedLayer[];
  mapboxLayers: IOrderedLayer[];
}) {
  const { orderedLayers, mapboxLayers } = params;

  // Since we're controlling the property layer by the "Shapefile Labels" layer,
  // we don't have a shapefile id to match, so we're finding the first shapefile property layer that exists
  const matchedShapefilePropertyLayer = mapboxLayers.find((l) => {
    return l.name.includes(shapefileLayerTypes.property);
  });

  if (matchedShapefilePropertyLayer) {
    orderedLayers.push({ name: matchedShapefilePropertyLayer.name, order: undefined });
  }
}

function addDependantMapLayer(params: {
  orderedLayers: IOrderedLayer[];
  mapboxLayers: IOrderedLayer[];
  layerName: string;
}) {
  const { orderedLayers, mapboxLayers, layerName } = params;

  if (mapboxLayers.find((l) => l.name === layerName)) {
    orderedLayers.push({ name: layerName, order: undefined });
  }
}
