import { memo } from "react";

import InfoIcon from "@material-ui/icons/Info";
import { Divider, InputNumber, Space, Switch, Typography } from "antd";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

import { useChartDispatch, useChartState } from "../../context";

const { Text } = Typography;

const SettingsOptions = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const isScatterChart =
    settings.chartType === ALL_CHART_TYPES.Probit.label ||
    settings.chartType === ALL_CHART_TYPES.CrossPlot.label ||
    (settings.chartType === ALL_CHART_TYPES.BoxPlot.label && settings.showScatter);
  const {
    cutoff,
    shutInMonths,
    lockUnits,
    survivorBias,
    useWeightedRatioAverage,
    showLinesOfBestFit,
    dotSize,
    dotOpacity
  } = settings;

  const handleChange = (key) => (v) => {
    const nextValue = { ...settings, [key]: v };
    dispatch({ type: "settings", payload: nextValue });
  };

  return (
    <StyledSpace direction="vertical" split={<StyledDivider />}>
      <Container>
        <Text>Cut Off (%)</Text>
        <Tooltip title="Calculate average until well count below cutoff percent">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          value={cutoff}
          min={0}
          max={100}
          controls={false}
          onChange={handleChange("cutoff")}
        />
      </Container>
      <Container>
        <Text>Shut In Months</Text>
        <Tooltip title="Number of non producing months to be considered shut-in. Shut-in months are removed from historical production.">
          <StyledIcon />
        </Tooltip>
        <StyledInput
          value={shutInMonths}
          min={0}
          max={20}
          controls={false}
          onChange={handleChange("shutInMonths")}
        />
      </Container>
      {isScatterChart && (
        <>
          <Container>
            <Text>Point Size</Text>
            <Tooltip title="Size of dots">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={dotSize}
              min={0}
              max={100}
              controls={false}
              onChange={handleChange("dotSize")}
            />
          </Container>
          <Container>
            <Text>Point Opacity</Text>
            <Tooltip title="Adjust the focused opacity">
              <StyledIcon />
            </Tooltip>
            <StyledInput
              value={dotOpacity}
              min={0}
              max={1}
              step={0.1}
              controls={false}
              onChange={handleChange("dotOpacity")}
            />
          </Container>
        </>
      )}
      <Container>
        <Text>Prevent Survivor Bias</Text>
        <Tooltip title="Allow wells that complete early to continue to affect average">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="survivorBiasCB"
          size="small"
          checked={survivorBias}
          onChange={handleChange("survivorBias")}
        />
      </Container>

      <Container>
        <Text>Weighted Ratio</Text>
        <Tooltip title="Cumulative numerator/cumulative denominator">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="weightedRatioCB"
          size="small"
          checked={useWeightedRatioAverage}
          onChange={handleChange("useWeightedRatioAverage")}
        />
      </Container>

      <Container>
        <Text>Lock Rate Units</Text>
        <Tooltip title="Locking rate units will keep the units on the y-axis constant, rather than switching between bbl/d and Mbbl/d, for example">
          <StyledIcon />
        </Tooltip>
        <Switch
          data-testid="lockUnits"
          size="small"
          checked={lockUnits}
          onChange={handleChange("lockUnits")}
        />
      </Container>

      {settings.chartType === ALL_CHART_TYPES.Probit.label && (
        <Container>
          <Text>Show Lines of Best Fit</Text>
          <RightSwitch
            data-testid="linesOfBestFit"
            size="small"
            checked={showLinesOfBestFit}
            onChange={handleChange("showLinesOfBestFit")}
          />
        </Container>
      )}
    </StyledSpace>
  );
};

export default memo(SettingsOptions);

const StyledSpace = styled(Space)`
  --tooltip: var(--color-text);
  width: 260px;
  display: flex;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(InfoIcon)`
  color: #a2aaad;
  margin-right: auto;
`;

const StyledInput = styled(InputNumber)`
  width: 70px;
  border-radius: var(--border-radius);
`;

const RightSwitch = styled(Switch)`
  margin-left: auto;
`;
