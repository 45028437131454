import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCategoryList,
  setCheckedGlobalTypeWells,
  setFilterId,
  setGlobalGroupBy
} from "store/features";
import { setFilterId as setFacilityFilterId } from "store/features/filter/facilityFilterSlice";
import { setScreenshotPresets } from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";

import { getScreenshotPreset, getUserOrgScreenshots } from "api/users";

import { EntityKind } from "models/entityKind";

import { ErrorBoundary } from "components/base";
import SingleComponentChart from "components/chart/SingleComponentChart";
import { DashboardProvider } from "components/dashboard/contexts/DashboardContext";
import { IconSpinner } from "components/icons";
import { ScreenshotProvider } from "components/screenshot/contexts/ScreenshotContext";
import { ScreenshotPresetProvider } from "components/screenshot/contexts/ScreenshotPresetContext";
import { WorkspaceProvider } from "components/workspace/contexts/WorkspaceContext";

import { GeomBinProvider } from "../components/geom-bin/contexts/GeomBinContext";

function SingleComponentChartPane() {
  // context and dispatch
  const dispatch = useDispatch();

  // state from store
  const channelId = useSelector((state: RootState) => state.channel.channel);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityKind = urlParams.get("entityKind") || EntityKind.Well;
  const categories = useSelector((state: RootState) => state.groupBy.categoryList);
  const presets = useSelector((state: RootState) => state.userSetting.screenshotPreset);
  const token = useSelector((state: RootState) => state.auth.jwtToken);
  const user = useSelector((state: RootState) => state.auth.user);

  // state
  const [isInitialized, setIsInitialized] = useState(false);
  const [isTokenSet, setIsTokenSet] = useState(false);

  // session storage data
  const channelData = sessionStorage.getItem(channelId);

  // derived state
  const initialState = channelData ? JSON.parse(channelData) : null;

  // sync global group by and filter id
  useEffect(() => {
    if (isInitialized) {
      return;
    }
    if (initialState?.filterId) {
      dispatch(
        entityKind == EntityKind.Well
          ? setFilterId(initialState.filterId)
          : setFacilityFilterId(initialState.filterId)
      );
    }

    if (initialState?.checkedGlobalTypeWells && entityKind == EntityKind.Well) {
      dispatch(setCheckedGlobalTypeWells(initialState.checkedGlobalTypeWells));
    }

    if (initialState?.globalGroupBy) {
      if (!isInitialized && entityKind == EntityKind.Well) {
        dispatch(setGlobalGroupBy(initialState.globalGroupBy));
      }
      setIsInitialized(true);
    }
  }, [channelId, entityKind, dispatch, initialState, isInitialized]);

  // fetch and update presets, if not present
  useEffect(() => {
    if (!user) return;
    if (presets.length) return;

    const userPresets = getScreenshotPreset();
    const orgPresets = getUserOrgScreenshots();
    Promise.all([orgPresets, userPresets])
      .then((values) => {
        dispatch(setScreenshotPresets([...values[0], ...values[1]]));
      })
      // eslint-disable-next-line no-console
      .catch(console.log);
  }, [dispatch, presets, user]);

  useEffect(() => {
    if (!isTokenSet && token) {
      setIsTokenSet(true);
    }
  }, [dispatch, isTokenSet, token]);

  // fetch presetList and categories, if not already in store
  useEffect(() => {
    if (isTokenSet && !categories.length) {
      // only get categories/presets if token is set
      dispatch(getCategoryList(EntityKind.Well));
    }
  }, [categories, isTokenSet, dispatch]);

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <div>
              Loading...
              <div>
                <IconSpinner />
              </div>
            </div>
          </div>
        }>
        <WorkspaceProvider>
          <DashboardProvider>
            <ScreenshotProvider>
              <ScreenshotPresetProvider>
                <GeomBinProvider>
                  <SingleComponentChart />
                </GeomBinProvider>
              </ScreenshotPresetProvider>
            </ScreenshotProvider>
          </DashboardProvider>
        </WorkspaceProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default SingleComponentChartPane;
