import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Subject } from "rxjs";
import {
  getCategoryList,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setExpandedKeys,
  setFilterId,
  setGlobalGroupBy
} from "store/features";
import {
  setScreenshotPresets,
  setTypeWellSettings
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";

import { useUser } from "hooks";

import { getScreenshotPreset, getUserOrgScreenshots } from "api/users";

import { EntityKind } from "models/entityKind";

import { ErrorBoundary } from "components/base";
import NewWindowDashboard from "components/dashboard/NewWindowDashboard";
import { DashboardProvider } from "components/dashboard/contexts/DashboardContext";
import { DashboardContextState } from "components/dashboard/contexts/DashboardContextState";
import { IconSpinner } from "components/icons";
import { ScreenshotProvider } from "components/screenshot/contexts/ScreenshotContext";
import { ScreenshotPresetProvider } from "components/screenshot/contexts/ScreenshotPresetContext";
import { WorkspaceProvider } from "components/workspace/contexts/WorkspaceContext";
import { WorkspaceContextState } from "components/workspace/contexts/WorkspaceContextState";

import useTypeWellSettings from "../api/useTypeWellSettings";
import { ArpsProvider } from "../components/arps/context";
import ProjectWrapper from "../components/project/projects/components/ProjectWrapper";
import { ProjectProvider } from "../components/project/projects/context";
import { WellListProvider } from "../components/well-list/context";
import { TYPE_WELLS } from "../constants";

function StandaloneDashboard(): JSX.Element {
  const dispatch = useDispatch();

  const channelId = useSelector((state: RootState) => state.channel.channel);

  const presets = useSelector((state: RootState) => state.userSetting.screenshotPreset);

  const token = useSelector((state: RootState) => state.auth.jwtToken);

  const categories = useSelector((state: RootState) => state.groupBy.categoryList);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [workspaceState, setWorkspaceState] = useState<WorkspaceContextState>();
  const [dashboardState, setDashboardState] = useState<DashboardContextState>();

  const { user } = useUser();

  const channelData = sessionStorage.getItem(channelId);
  const initialState = channelData ? JSON.parse(channelData) : null;

  const { data, refetch } = useTypeWellSettings();

  useEffect(() => {
    if (!data || !data.settings?.length) {
      refetch();
      return;
    }

    dispatch(setTypeWellSettings(data));
  }, [dispatch, data]);

  useEffect(() => {
    if (!initialState) return;

    if (!isInitialized) {
      if (initialState.filterId) {
        dispatch(setFilterId(initialState.filterId));
      }

      if (initialState.settings) {
        setWorkspaceState({
          workspaceId: initialState.settings?.workspaceId,
          activityBarWidth: 0,
          isActivityBarResizing: false,
          isLoading: false,
          workspaceRefreshObserver: new Subject(),
          activeDashboardId: initialState.settings.dashboardId
        });
        setDashboardState({
          lockMap: true,
          isLoading: false,
          isModified: false,
          hasModifiedWidgets: false,
          dashboardRefreshObserver: new Subject(),
          dashboardMode: "explore"
        });
      }

      if (initialState.globalGroupBy) {
        dispatch(setGlobalGroupBy(initialState.globalGroupBy));
      }

      if (initialState?.typeWells?.checkedGlobalTypeWells) {
        dispatch(
          setCheckedGlobalTypeWells(initialState.typeWells.checkedGlobalTypeWells)
        );
      }

      if (initialState?.typeWells?.checkedTypeWellKeys) {
        dispatch(
          setCheckedKeys({
            type: TYPE_WELLS,
            checkedKeys: initialState.typeWells.checkedTypeWellKeys
          })
        );
      }

      if (initialState?.typeWells?.expandedTypeWellKeys) {
        dispatch(
          setExpandedKeys({
            type: TYPE_WELLS,
            expandedKeys: initialState.typeWells.expandedTypeWellKeys
          })
        );
      }

      setIsInitialized(true);
    }
  }, [dispatch, initialState, isInitialized]);

  // Component mounting effects
  useEffect(() => {
    if (!presets.length && user) {
      const userPresets = getScreenshotPreset();
      const orgPresets = getUserOrgScreenshots();
      Promise.all([orgPresets, userPresets])
        .then((values) => {
          dispatch(setScreenshotPresets([...values[0], ...values[1]]));
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  }, [dispatch, presets.length, user]);

  useEffect(() => {
    if (!isTokenSet && token) {
      setIsTokenSet(true);
    }
  }, [dispatch, isTokenSet, token]);

  // Component mounting effects
  useEffect(() => {
    if (isTokenSet && !categories.length) {
      // only get categories/presets if token is set
      dispatch(getCategoryList(EntityKind.Well));
    }
  }, [dispatch, isTokenSet]);

  if (!token) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <div>
              Loading...
              <div>
                <IconSpinner></IconSpinner>
              </div>
            </div>
          </div>
        }>
        <WorkspaceProvider state={workspaceState} useWrapper={false}>
          <DashboardProvider state={dashboardState} useWrapper={false}>
            <ScreenshotProvider>
              <ScreenshotPresetProvider>
                <ProjectProvider>
                  <ProjectWrapper>
                    <WellListProvider>
                      <ArpsProvider>
                        <NewWindowDashboard />
                      </ArpsProvider>
                    </WellListProvider>
                  </ProjectWrapper>
                </ProjectProvider>
              </ScreenshotPresetProvider>
            </ScreenshotProvider>
          </DashboardProvider>
        </WorkspaceProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default StandaloneDashboard;
