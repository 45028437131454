import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useLocalStorage } from "hooks";

import { LegendItemModel } from "models";

import {
  LegendDimensionsT,
  LegendPositionT,
  LegendT,
  ParentDimensionsT
} from "components/chart/legend/Legend";
import getCalculatedLegend from "components/chart/legend/utils/getCalculatedLegend";
import { getPositionFromChartSettings } from "components/chart/legend/utils/getPositionFromChartSettings";
import getUpdatedChartSizeLegend from "components/chart/legend/utils/getUpdatedChartSizeLegend";
import { useScreenshotContext } from "components/screenshot/hooks";

import { useIpdbContext } from "../contexts/IpdbContext";

export type IPDBLegendBaseT = {
  height?: number;
  width?: number;
  parentDimensions: ParentDimensionsT;
  dimensionsStorageKey: string;
  positionStorageKey: string;
  parentDimensionsStorageKey: string;
  showLegend: boolean;
  showLegendBorder?: boolean;
  ipdbTitleSize?: number;
  ipdbLabelsSize?: number;
  isScreenshotOverlayVisible?: boolean;
  backgroundOpacity?: number;
  disableDragging?: boolean;
};

export const IPDBLegendBase: React.FC<IPDBLegendBaseT> = ({
  width = 180,
  height = 250,
  parentDimensions = undefined,
  dimensionsStorageKey = undefined,
  positionStorageKey = undefined,
  parentDimensionsStorageKey = undefined,
  showLegend = false,
  showLegendBorder = true,
  ipdbTitleSize = 15,
  ipdbLabelsSize = 14,
  isScreenshotOverlayVisible = false,
  backgroundOpacity = 1,
  disableDragging = false
}) => {
  const { getStoredValue: getStoredDimensions } = useLocalStorage(
    dimensionsStorageKey,
    undefined
  );

  const { getStoredValue: getStoredPosition } = useLocalStorage(
    positionStorageKey,
    undefined
  );

  const { getStoredValue: getStoredParentDimensions } = useLocalStorage(
    parentDimensionsStorageKey,
    undefined
  );

  // State
  const txnId = useSelector((state: RootState) => state.map.txnId);

  const [dimensions, setDimensions] = useState<LegendDimensionsT>(() => {
    const itemSize = isScreenshotOverlayVisible ? ipdbLabelsSize + 12 : 30; // 30px for chart, 12px gap around when taking screenshot
    const itemsHeight = txnId.legend.legendItems.length * itemSize;

    const storedDimensions = getStoredDimensions();
    return storedDimensions
      ? storedDimensions
      : { width, height: itemsHeight ? itemsHeight + 32 : height }; // 32px for title
  });

  const [position, setPosition] = useState<LegendPositionT>(() => {
    const storedPosition = getStoredPosition();
    const currentPosition = storedPosition
      ? storedPosition
      : getPositionFromChartSettings(dimensions, parentDimensions, "SE");
    return currentPosition;
  });

  const storedDimensions: LegendDimensionsT = getStoredDimensions();
  const storedPosition: LegendPositionT = getStoredPosition();
  const storedParentDimensions: LegendDimensionsT =
    getStoredParentDimensions() || parentDimensions;

  const { legendItems, legendTitle } = useIpdbContext();
  const { settings } = useScreenshotContext();
  const mapScreenshotScale =
    Number(settings?.mapScreenshotScale) < 1 && isScreenshotOverlayVisible
      ? Number(settings?.mapScreenshotScale)
      : 1;

  const handleDragStop = (_, dragElement) => {
    const newPosition = {
      x: dragElement.x,
      y: dragElement.y
    };

    setPosition(newPosition);
    localStorage.setItem(
      positionStorageKey,
      JSON.stringify({ x: newPosition.x, y: newPosition.y })
    );
    localStorage.setItem(
      parentDimensionsStorageKey,
      JSON.stringify({ width: parentDimensions?.width, height: parentDimensions?.height })
    );
  };

  function handleResize(e, direction, ref, delta, p) {
    const nextDimensions = {
      width: ref.offsetWidth,
      height: ref.offsetHeight
    };
    setDimensions(nextDimensions);
    setPosition(p);

    localStorage.setItem(dimensionsStorageKey, JSON.stringify(nextDimensions));
    localStorage.setItem(positionStorageKey, JSON.stringify(p));
    localStorage.setItem(
      parentDimensionsStorageKey,
      JSON.stringify({ width: parentDimensions?.width, height: parentDimensions?.height })
    );
  }

  function calculateLegendPosition() {
    if (!!storedPosition && !disableDragging) {
      const updatedLegend =
        storedParentDimensions?.width !== undefined &&
        storedParentDimensions?.width !== undefined &&
        (storedParentDimensions?.width != parentDimensions?.width ||
          storedParentDimensions?.height != parentDimensions?.height)
          ? getUpdatedChartSizeLegend(
              storedParentDimensions,
              parentDimensions,
              storedPosition,
              storedDimensions,
              currentDimensions
            )
          : currentLegend;
      const calculatedLegend = getCalculatedLegend(updatedLegend, parentDimensions);
      return { x: calculatedLegend.x, y: calculatedLegend.y };
    } else if (!dimensions || !parentDimensions) {
      return { x: 0, y: 0 };
    } else {
      const calculatedLegend = getCalculatedLegend(currentLegend, parentDimensions);
      return getPositionFromChartSettings(
        { width: calculatedLegend.width, height: calculatedLegend.height },
        parentDimensions,
        "SE"
      );
    }
  }

  const currentLegend: LegendT = {
    ...position,
    ...dimensions
  };

  // update the currentDimensions for rendering the legend
  const currentDimensions: LegendDimensionsT = useMemo(() => {
    const calculatedDimensions = getCalculatedLegend(currentLegend, parentDimensions);
    return { width: calculatedDimensions.width, height: calculatedDimensions.height };
  }, [dimensions]);

  // update currentPosition for rendering the legend
  const currentPosition: LegendPositionT = useMemo(() => {
    return parentDimensions ? calculateLegendPosition() : getStoredPosition();
  }, [position]);

  // update legend dimensions in local storage for future renders
  useEffect(() => {
    localStorage.setItem(dimensionsStorageKey, JSON.stringify(currentDimensions));
  }, [currentDimensions]);

  // update legend position in local storage for future renders
  useEffect(() => {
    localStorage.setItem(positionStorageKey, JSON.stringify(currentPosition));
  }, [currentPosition]);

  // setPosition and setDimensions when changing parent dimensions as handleDragStop and handleResize does not account for parent dimension changes
  // also update parent dimensions and legend dimensions in local storage for future renders
  useEffect(() => {
    const currentPosition = parentDimensions
      ? calculateLegendPosition()
      : getStoredPosition();

    setPosition(currentPosition);
    setDimensions(currentDimensions);

    localStorage.setItem(
      parentDimensionsStorageKey,
      JSON.stringify({ width: parentDimensions?.width, height: parentDimensions?.height })
    );
    localStorage.setItem(dimensionsStorageKey, JSON.stringify(currentDimensions));
  }, [parentDimensions]);

  if (!showLegend) {
    return <></>;
  }

  const legendList = (
    <LegendList>
      <LegendTitle fontSize={isScreenshotOverlayVisible ? ipdbTitleSize : 14}>
        {legendTitle}
      </LegendTitle>
      {legendItems.map((li: LegendItemModel, i) => {
        return (
          <IpdbLegendItemTemplate
            key={li.color + li.title + i}
            fontSize={isScreenshotOverlayVisible ? ipdbTitleSize : 15}>
            <LegendColor color={li.color} />
            {li.title}
          </IpdbLegendItemTemplate>
        );
      })}
    </LegendList>
  );

  return parentDimensions ? (
    <StyledRnd
      bounds="parent"
      minWidth="140px"
      minHeight="35px"
      onDragStop={handleDragStop}
      onResize={handleResize}
      position={{ x: currentPosition.x, y: currentPosition.y }}
      size={{ width: currentDimensions.width, height: currentDimensions.height }}
      mapScreenshotScale={mapScreenshotScale}
      backgroundOpacity={backgroundOpacity}
      showLegendBorder={showLegendBorder}>
      <LegendWrapper className="ipdb-legend">{legendList}</LegendWrapper>
    </StyledRnd>
  ) : (
    <NoParentLegendWrapper className="ipdb-legend">{legendList}</NoParentLegendWrapper>
  );
};

const StyledRnd = styled(Rnd)`
  position: absolute;
  margin: 10px;
  background: ${(props) =>
    props.backgroundOpacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundOpacity})`};
  border: ${(props) => (props.showLegendBorder ? "1px solid gray" : "")};
  overflow: hidden;
  z-index: 9998; /* Set a high z-index value to ensure legend is on top within the screenshot component. */

  & > div:last-child {
    position: unset;
  }
`;

const LegendWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
`;

const NoParentLegendWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: ${(props) => props?.width ?? 180}px;
  height: ${(props) => props?.height ?? 250}px;
  background-color: white;
  bottom: 20px;
  right: 50px;
  padding: 5px;
  box-shadow: var(--shadow-popup);
`;

const IpdbLegendItemTemplate = styled.div`
  font-size: ${(props) => (props.fontSize ?? 14) / 10}rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 2px 5px;
  align-items: center;
  gap: 10px;

  .item-color {
    width: 25px;
    height: 25px;
  }

  .item-value {
    width: 100%;

    padding: 0 5px;
  }
`;

const LegendList = styled.ul`
  height: 100%;
  overflow-y: auto;
`;

const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  border-radius: 2px;
`;

const LegendTitle = styled.div`
  font-size: ${(props) => (props.fontSize ?? 15) / 10}rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;
