import { toast } from "react-toastify";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { IS_MCDAN_ENV } from "constants/app.constants";
import {
  MY_FOCUS_FIELDS,
  ORG_FOCUS_FIELDS,
  ORG_GEO_FIELDS
} from "constants/settings.constants";
import _debounce from "lodash/debounce";
import { AppThunk } from "store/store";

import { BetaFeatures } from "hooks/useBetaFeatures";
import { ModuleType } from "hooks/useUserModules";

import { EntityKind } from "models/entityKind";
import {
  IGroupBy,
  IGroupByListColumn,
  IGroupByListItem,
  IGroupByState
} from "models/groupBy";

import { getGeoCalculationsJobs } from "components/org-settings/hooks/useGetGeoCalculationsJobs";

import { fetchOrgDefinedColumns } from "../../../components/geom-bin/helpers/fetchOrgDefinedColumns";
import { fetchUserDefinedColumns } from "../../../components/geom-bin/helpers/fetchUserDefinedColumns";

function getSessionGroupBy(): IGroupBy {
  const sessionData = sessionStorage.getItem("globalGroupBy");
  if (sessionData) {
    return JSON.parse(sessionData);
  }
  return undefined;
}

const initialState: IGroupByState = {
  categoryList: [],
  facilityFields: [],
  presetList: [],
  recentFocus: [],
  hoverDataPoint: undefined,
  hoverLegendItem: "",
  hoverLegendGroup: "",
  cumPlusForecast: false,
  selectedGroups: [],
  selectedFacilityGroups: [],
  hasCheckedBins: false,
  hasCheckedBinsFacility: false,
  globalGroupBy: getSessionGroupBy() ?? {
    title: "Resource Play",
    property: "Header.ResourcePlay",
    groupByField: "Header.ResourcePlay",
    pdenSource: "Public",
    canBin: false,
    dataType: "text",
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Well,
    bin: {
      BinSize: "",
      MinSize: "",
      GreaterThan: "",
      BinType: "BinSize",
      LessThan: ""
    }
    //color: "#F3C300",
  },
  globalFacilityFocus: {
    title: "Facility Operator",
    property: "Header.OperatorName",
    groupByField: "Header.OperatorName",
    pdenSource: "Public",
    canBin: false,
    dataType: "text",
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Facility,
    bin: {
      BinSize: "",
      MinSize: "",
      GreaterThan: "",
      BinType: "BinSize",
      LessThan: ""
    }
    //color: "#F3C300",
  }
};
const setGlobalGroupByBaseOnEntityKind = (state, gb: IGroupBy) => {
  if (gb.entityKind === EntityKind.Well) {
    state.globalGroupBy = gb;
  } else {
    state.globalFacilityFocus = gb;
  }
};

const groupBySlice = createSlice({
  name: "groupby",
  initialState,
  reducers: {
    setCategoryList(state, action: PayloadAction<IGroupByListItem[]>) {
      state.categoryList = action.payload;
    },
    setSelectedGroups(state, action: PayloadAction<string[]>) {
      state.selectedGroups = action.payload;
    },
    setHasCheckedBins(state, action: PayloadAction<boolean>) {
      state.hasCheckedBins = action.payload;
    },
    setHasCheckedBinsFacility(state, action: PayloadAction<boolean>) {
      state.hasCheckedBinsFacility = action.payload;
    },
    setSelectedFacilityGroups(state, action: PayloadAction<string[]>) {
      state.selectedFacilityGroups = action.payload;
    },
    setUserDefinedColumns(state, action: PayloadAction<IGroupByListColumn[]>) {
      const udcIndex = state.categoryList.findIndex((f) => f.name === MY_FOCUS_FIELDS);
      if (udcIndex < 0) {
        return;
      }
      state.categoryList[udcIndex].columns = action.payload;
    },
    setOrgDefinedColumns(state, action: PayloadAction<IGroupByListColumn[]>) {
      const udcIndex = state.categoryList.findIndex((f) => f.name === ORG_FOCUS_FIELDS);
      if (udcIndex < 0) {
        return;
      }
      state.categoryList[udcIndex].columns = action.payload;
    },
    setPresetList(state, action) {
      state.presetList = action.payload;
    },
    setHoverDataPoint(state, action) {
      state.hoverDataPoint = action.payload;
    },
    setHoverLegendItem(state, action) {
      state.hoverLegendItem = action.payload;
    },
    setHoverLegendGroup(state, action) {
      state.hoverLegendGroup = action.payload;
    },
    setFacilityFields(state, action: PayloadAction<IGroupByListItem[]>) {
      state.facilityFields = action.payload;
    },
    setGlobalGroupBy(state, action: PayloadAction<IGroupBy>) {
      if (action.payload.entityKind === EntityKind.Well) {
        sessionStorage.setItem("globalGroupBy", JSON.stringify(action.payload));
      } else {
        sessionStorage.setItem("globalFacilityFocus", JSON.stringify(action.payload));
      }
      const currentGb =
        action.payload.entityKind === EntityKind.Well
          ? state.globalGroupBy
          : state.globalFacilityFocus;
      if (action.payload.property === currentGb.property) {
        //only update groupby state if property is the same
        //skip the recent focus list
        setGlobalGroupByBaseOnEntityKind(state, action.payload);
        return;
      }

      let index = state.recentFocus.findIndex(
        (gb) =>
          gb.property === action.payload.property || gb.property === currentGb.property
      );

      while (index >= 0) {
        state.recentFocus.splice(index, 1);
        index = state.recentFocus.findIndex(
          (gb) =>
            gb.property === action.payload.property || gb.property === currentGb.property
        );
      }
      //add previous group by to recent focus
      state.recentFocus = [currentGb, ...state.recentFocus].slice(0, 6);
      setGlobalGroupByBaseOnEntityKind(state, action.payload);
    },
    setCumPlusForecast(state, action: PayloadAction<boolean>) {
      state.cumPlusForecast = action.payload;
    }
  }
});

const rootUrl = process.env.REACT_APP_COLUMN_SET_SERVICE;

export const {
  setCategoryList,
  setGlobalGroupBy,
  setHasCheckedBins,
  setHasCheckedBinsFacility,
  setHoverDataPoint,
  setHoverLegendItem,
  setHoverLegendGroup,
  setCumPlusForecast,
  setUserDefinedColumns,
  setOrgDefinedColumns,
  setSelectedGroups,
  setSelectedFacilityGroups,
  setFacilityFields
} = groupBySlice.actions;

const debounceDataPoint = _debounce((item, dispatch) => {
  dispatch(setHoverDataPoint(item));
}, 50);

export const updateDataPoint =
  (item): AppThunk =>
  (dispatch) => {
    debounceDataPoint(item, dispatch);
  };

const debounceUWI = _debounce((item, dispatch) => {
  dispatch(setHoverLegendItem(item));
}, 50);

export const updateHoverLegendItem =
  (item): AppThunk =>
  (dispatch) => {
    debounceUWI(item, dispatch);
  };

const debounceGroup = _debounce((group, dispatch) => {
  dispatch(setHoverLegendGroup(group));
}, 50);

export const updateHoverLegendGroup =
  (group): AppThunk =>
  (dispatch) => {
    debounceGroup(group, dispatch);
  };

export const getCategoryList =
  (kind: EntityKind = EntityKind.Well): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(`${rootUrl}/column/new?entityKind=${kind.toString()}`);
      const list: IGroupByListItem[] = res.data;
      const state = getState();
      const orgList = kind == EntityKind.Well ? await fetchOrgDefinedColumns() : null;
      const jobs = kind == EntityKind.Well ? await getGeoCalculationsJobs() : null;

      // Check if user has 3D Geo Model module
      const modules: { [play: string]: number[] } = JSON.parse(
        state.auth?.user?.modules ?? "{}"
      );
      const has3dGeoModel = Object.keys(modules).some(
        (play) => modules[play].indexOf(ModuleType.ThreeDGeoModel) >= 0
      );

      if (orgList) {
        const hasFeature = (feature: BetaFeatures): boolean => {
          return state.auth?.user?.organization?.betaFeatures?.includes(feature);
        };

        // Add 3D Geo Model category
        if (has3dGeoModel && hasFeature("Org Geo Fields")) {
          const orgGeoFields = orgList.columns.filter((item) =>
            jobs.some(
              (job) =>
                item.subgroup === job.name && item.title.startsWith(job.name + " - ")
            )
          );

          // Remove the geo fields from the org list
          orgList.columns = orgList.columns.filter(
            (item) => !orgGeoFields.some((field) => item.property === field.property)
          );

          list.push(orgList);

          list.push({
            name: ORG_GEO_FIELDS,
            hexColor: "#136d8b",
            sortOrder: 100,
            property: "",
            section: "Organization",
            columns: orgGeoFields,
            id: ORG_GEO_FIELDS
          });
        } else {
          list.push(orgList);
        }
      }

      if (
        state.auth?.user?.organization?.allowUserDefinedFields &&
        kind === EntityKind.Well
      ) {
        const userList = await fetchUserDefinedColumns();
        if (userList) {
          list.push(userList);
        }
      }

      list.push({
        name: "Shapefile",
        hexColor: "#8B4513",
        sortOrder: 100,
        property: "",
        section: "Organization",
        columns: [],
        id: "Shapefile"
      });

      const schema = state.app.syncWells?.schema;
      if (IS_MCDAN_ENV && schema) {
        const schemaDefinedColumnResponse = await axios.get(
          `${rootUrl}/schema-defined-columns/${schema}`
        );
        if (schemaDefinedColumnResponse.status === 200) {
          list.push(schemaDefinedColumnResponse.data);
        }
      }
      if (list?.length > 0) {
        if (kind === EntityKind.Well) {
          dispatch(setCategoryList(list));
        } else if (kind === EntityKind.Facility) {
          dispatch(setFacilityFields(list));
        }
      }
    } catch (err) {
      toast.error("Unable to fetch focus fields.");
      // eslint-disable-next-line no-console
      console.error("error", err);
    }
  };

export const updateUserDefinedColumns = (): AppThunk => async (dispatch) => {
  const userList = await fetchUserDefinedColumns();

  if (userList) {
    dispatch(setUserDefinedColumns(userList.columns));
  }

  const orgList = await fetchOrgDefinedColumns();

  if (orgList) {
    dispatch(setOrgDefinedColumns(orgList.columns));
  }
};

export default groupBySlice.reducer;
