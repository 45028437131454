import html2canvas from "html2canvas";

export const copyThreeDToClipboard = (
  screenshotOverlay: HTMLDivElement
): Promise<void> => {
  const selector = ".App .three-d-viewer-container";

  if (screenshotOverlay) {
    screenshotOverlay.style.border = "none";
  }

  return html2canvas(document.querySelector(selector)).then((canvas) => {
    return canvas.toBlob((blob) => {
      if (!blob) return;
      if (!navigator.clipboard) {
        return;
      }
      const data = [new ClipboardItem({ "image/png": blob })];

      return navigator.clipboard.write(data);
    });
  });
};

export const copyXDAToClipboard = (screenshotOverlay: HTMLDivElement): Promise<void> => {
  const selector = ".App .xda-viewer-container";

  if (screenshotOverlay) {
    screenshotOverlay.style.border = "none";
  }

  return html2canvas(document.querySelector(selector)).then((canvas) => {
    return canvas.toBlob((blob) => {
      if (!blob) return;
      if (!navigator.clipboard) {
        return;
      }
      const data = [new ClipboardItem({ "image/png": blob })];

      return navigator.clipboard.write(data);
    });
  });
};
