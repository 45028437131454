import { useState } from "react";

import { Input } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";

const SearchBar = () => {
  const { filterText } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();

  const debouncedUpdate = _debounce((value) => {
    updateGroupByState({ type: "filter", payload: value });
  }, 400);

  const [value, setValue] = useState(filterText);

  const handleSearch = () => (e) => {
    const value = e.target.value;
    setValue(value);
    debouncedUpdate(value);
  };

  return (
    <SearchSection>
      <SearchTitle>Select Field</SearchTitle>
      <Input
        allowClear
        value={value}
        onChange={handleSearch()}
        placeholder="Search field"
        style={{ width: "273px", marginLeft: "auto" }}
      />
    </SearchSection>
  );
};

export default SearchBar;

const SearchSection = styled.div`
  display: grid;
  grid-template-columns: calc(var(--search-width) - var(--space-4)) 1fr;
  align-items: center;
  gap: var(--space-4);
  background-color: var(--color-white);
  padding: var(--space-2) var(--space-4);
`;
const SearchTitle = styled.span`
  width: calc(var(--search-width) - var(--space-2));
  font-weight: var(--fontWeightBold);
`;
