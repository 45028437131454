// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { mdiDelete, mdiPencil } from "@mdi/js";
import { List, Popconfirm, Typography } from "antd";
import styled from "styled-components";

import { setPadScenario } from "../../store/features";
import PadScenarioModal from "./PadScenarioModal";
import { IconButton } from "./ScenarioConfigurationTable";
import { usePadScenario } from "./contexts/usePadScenario";
import useDeleteScenario from "./hooks/useDeleteScenario";
import useGetScenarioList from "./hooks/useScenarioList";
import useScenarioSticks from "./hooks/useScenarioSticks";
import { Scenario } from "./models/scenario";

const Title = Typography.Title;

interface ScenarioListProps {
  title?: ReactNode;
}

export default function ScenarioList({ title }: ScenarioListProps) {
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const { data: scenarios, isLoading, isError } = useGetScenarioList();
  const { state, dispatch: scenarioDispatch } = usePadScenario();
  const { data: sticks } = useScenarioSticks(state.scenario?.scenarioId);
  const [showEditModal, setShowEditModal] = useState(false);
  const { mutateAsync: deleteScenario } = useDeleteScenario();
  const dispatch = useDispatch();
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const handleMouseEnter = (itemId: string) => {
    if (!popconfirmVisible) {
      setHoveredItemId(itemId);
    }
  };

  const handleMouseLeave = () => {
    if (!popconfirmVisible) {
      setHoveredItemId(null);
    }
  };

  useEffect(() => {
    if (scenarios) {
      scenarioDispatch({ type: "SET_SCENARIO_LIST", payload: scenarios });
      if (scenarios.length > 0) {
        const currentScenario = scenarios.find(
          (s) => s.scenarioId === state.scenario.scenarioId
        );
        scenarioDispatch({
          type: "SET_SCENARIO",
          payload: currentScenario ?? scenarios[0]
        });
      }
    }
  }, [scenarios]);

  const handleClick = (item: Scenario) => {
    if (!item.scenarioId || item.scenarioId == state.scenario.scenarioId) {
      return;
    }
    scenarioDispatch({ type: "SET_SCENARIO", payload: item });
    dispatch(
      setPadScenario({
        scenarioId: item.scenarioId,
        play: state.scenario.play,
        showSticksOnMap: true
      })
    );
  };

  useEffect(() => {
    if (sticks) {
      dispatch(
        setPadScenario({
          scenarioId: state.scenario.scenarioId,
          showSticksOnMap: true,
          play: state.scenario.play,
          sticks: sticks
        })
      );
    }
  }, [sticks]);
  const handlePopconfirmVisibleChange = (visible: boolean) => {
    setPopconfirmVisible(visible);
  };
  const renderItem = (item: Scenario) => {
    const isHovered = hoveredItemId === item.scenarioId;
    return (
      <ItemWrapper
        key={item.scenarioId}
        onClick={() => handleClick(item)}
        selected={item.scenarioId == state.scenario.scenarioId}
        onMouseEnter={() => handleMouseEnter(item.scenarioId)}
        onMouseLeave={handleMouseLeave}>
        <ScenarioLabel title={`${item.scenarioId} - ${item.padName}`}>
          {item.scenarioId} - {item.padName}
        </ScenarioLabel>
        {isHovered && (
          <ButtonGroup>
            <IconButton
              type={"text"}
              title={"Edit"}
              shape={"circle"}
              onClick={handleEdit}
              icon={<Icon path={mdiPencil} size={1.2} />}
            />
            <Popconfirm
              title={"Confirm delete?"}
              onConfirm={() => handleDelete(item)}
              okButtonProps={{ danger: true }}
              onOpenChange={handlePopconfirmVisibleChange}
              okText={"Delete"}>
              <IconButton
                type="text"
                title={"Delete"}
                shape={"circle"}
                icon={<Icon path={mdiDelete} size={1.2} />}
              />
            </Popconfirm>
          </ButtonGroup>
        )}
      </ItemWrapper>
    );
  };

  const handleEdit = () => {
    //clicking on the edit button will set the scenario to the clicked item
    //show the edit modal
    setTimeout(() => setShowEditModal(true), 100);
  };

  const handleDelete = async (item: Scenario) => {
    if (!item.scenarioId) {
      return;
    }
    await deleteScenario(item.scenarioId);
    scenarioDispatch({
      type: "SET_SCENARIO",
      payload: {
        scenarioId: ""
      } as Scenario
    });
  };

  if (isLoading) {
    return <RootContainer>Loading...</RootContainer>;
  }
  if (isError) {
    return <RootContainer>Error</RootContainer>;
  }

  return (
    <RootContainer>
      <TitleWrapper>{title ? title : <Title level={5}>Scenarios</Title>}</TitleWrapper>
      <List dataSource={state.scenarioList} renderItem={renderItem} />
      <PadScenarioModal
        open={showEditModal}
        mode={"edit"}
        scenario={state.scenario}
        onClose={() => setShowEditModal(false)}
      />
    </RootContainer>
  );
}
const RootContainer = styled.div`
  min-width: 200px;
  padding: 5px;
  height: 100%;
  overflow-y: auto;
  max-width: 200px;
`;
const ScenarioLabel = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const ButtonGroup = styled.div`
  display: flex;
  margin: auto;
  gap: 5px;
`;
const TitleWrapper = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemWrapper = styled.div`
  cursor: pointer;
  min-width: 120px;
  height: 45px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#f0f0f0" : "white")};
  transition: box-shadow 0.3s ease-in-out;
  z-index: 1;

  &:hover {
    box-shadow: 0 0 10px rgba(159, 169, 179, 0.5);
    z-index: 10; /* Ensure the hovered item is above others */
  }
`;
