import html2canvas from "html2canvas";

import { IScreenshotSettings } from "models/screenshot";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const copyMapToClipboard = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widgetState: any,
  settings: IScreenshotSettings
): Promise<void> => {
  if (!widgetState?.mapbox) {
    return Promise.resolve();
  }
  const mapbox = widgetState.mapbox;
  const canvas = mapbox.getCanvas();
  if (!canvas) return Promise.resolve();
  const parentRect = canvas.getBoundingClientRect();
  let offscreenCanvas = document.createElement("canvas");
  offscreenCanvas.width = canvas.width;
  offscreenCanvas.height = canvas.height;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const el: any = widgetState?.copyOverlay;
  const boundingRect = el.getBoundingClientRect();

  let offscreenCtx = offscreenCanvas.getContext("2d");
  const scale = window.devicePixelRatio;
  const mapScreenshotScale = settings.mapScreenshotScale ?? 1;
  const reverseScale = (1 / mapScreenshotScale) * scale;
  const width = Math.floor(scale * mapScreenshotScale * Number(settings?.width));
  const height = Math.floor(scale * mapScreenshotScale * Number(settings?.height));
  const x = scale * (boundingRect.left - parentRect.left);
  const y = scale * (boundingRect.top - parentRect.top);
  offscreenCtx?.drawImage(canvas, 0, 0);
  const img = offscreenCtx?.getImageData(x, y, width, height);
  offscreenCtx?.clearRect(0, 0, canvas.width, canvas.height);
  offscreenCanvas.width = width;
  offscreenCanvas.height = height;
  offscreenCtx?.putImageData(img, 0, 0);

  // The desired dimensions
  const desiredWidth = Math.floor(Number(settings?.width) * scale);
  const desiredHeight = Math.floor(Number(settings?.height) * scale);

  // Scale map
  if (mapScreenshotScale !== 1) {
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = desiredWidth;
    resizedCanvas.height = desiredHeight;
    const resizedCtx = resizedCanvas.getContext("2d");
    resizedCtx.imageSmoothingEnabled = true;
    resizedCtx.imageSmoothingQuality = "high";

    resizedCtx.drawImage(
      offscreenCanvas,
      0,
      0,
      width,
      height,
      0,
      0,
      desiredWidth,
      desiredHeight
    );

    offscreenCanvas = resizedCanvas;
    offscreenCtx = resizedCtx;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const legendContainer: HTMLElement = document.getElementsByClassName("copy-overlay")[0];
  legendContainer.style.background = "transparent";
  legendContainer.style.border = "none";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  legendContainer.getElementsByClassName("buttons")[0].style.display = "none";
  //convert copy overlay into canvas and overlay it on top of map
  return html2canvas(legendContainer, {
    backgroundColor: "rgba(0,0,0,0)",
    allowTaint: true,
    useCORS: true,
    scale: reverseScale // scale legend
  }).then((legendCanvas) => {
    offscreenCtx?.drawImage(legendCanvas, 0, 0);

    const img = document.createElement("img");
    img.src = offscreenCanvas.toDataURL();

    return offscreenCanvas.toBlob(async (blob) => {
      if (!blob) return;
      if (!navigator.clipboard) {
        return;
      }
      const data = [new ClipboardItem({ "image/png": blob })];

      return navigator.clipboard.write(data);
    });
  });
};
