// eslint-disable-next-line import/no-named-as-default
import { Icon } from "@mdi/react";
import { useEffect, useState } from "react";

import { mdiChevronDown } from "@mdi/js";
import {
  Alert,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  TreeSelect
} from "antd";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import usePlayTo3DDataPrivateSources from "hooks/usePlayTo3DDataPrivateSources";

import { getValidWells } from "api/data";
import { getGroupedPlayZonesByPeriod } from "api/map";

import { useUser } from "../../../hooks";
import { usePostGeoCalculationJob } from "../hooks/usePostGeoCalculationJob";
import { GeoCalculationsJob } from "../models/GeoCalculations";

export const convertToTreeData = (data) => {
  return data.map((period) => ({
    title: period.period,
    value: `period_${period.period}`,
    key: `period_${period.period}`,
    type: `period`,
    checkable: false,
    children: period.plays.map((play) => ({
      title: play.play,
      value: `play_${play.play}`,
      key: `play_${play.play}`,
      type: `play`,
      children: play.zones.map((zone) => ({
        title: zone,
        value: zone,
        key: zone,
        type: `zone`
      }))
    }))
  }));
};

const { TextArea } = Input;
export const CreateGeoCalculationJobModal = ({
  requestShowModal,
  jobs,
  onClose
}: CreateAccessTokenModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(requestShowModal);
  /* const [projects, setProjects] = useState<IProject[]>([]);
  const [savedFilters, setSavedFilters] = useState<SavedFilterModel[]>([]);
  */
  const { email } = useUser();
  const [errors, setErrors] = useState<string>("");
  const [periodKeys, setPeriodKeys] = useState([]);
  const [selectedIpdbZones, setSelectedIpdbZones] = useState<string[]>([]);
  const [treeData, setTreeData] = useState([]);
  const [form] = Form.useForm();
  /*const { mutate: updateProjects } = useGetProjects(
    (projects) => {
      //setProjects(projects);
      setErrors("");
      if (projects.length > 0) {
        if (projects.length > 0) {
          form.setFieldsValue({ selectedProject: projects[0].id });
        } else {
          form.resetFields(["selectedProject"]);
        }
      }
    },
    () => {
      setErrors("Error occurred while fetching projects");
    }
  );
  const { mutate: updateSavedFilters } = useGetProjectSavedFilters(
    (filters) => {
      const savedFiltersWithId = filters.savedFilters.filter((item) => {
        const filter = JSON.parse(item.filterJSON);
        const hasPolygonFilter = filter?.polygonFilter?.coordinates.length > 0 ?? false;
        const hasWellListFilter = filter?.wellListFilter?.length > 0 ?? false;
        return !!item.id && (hasPolygonFilter || hasWellListFilter);
      });
      setSavedFilters(savedFiltersWithId);
      if (savedFiltersWithId.length > 0) {
        form.setFieldsValue({ savedFilterId: savedFiltersWithId[0].id });
      } else {
        form.resetFields(["savedFilterId"]);
      }
    },
    () => {
      setErrors("Error occurred while fetching filters");
    }
  );*/
  const { mutateAsync, isError, error } = usePostGeoCalculationJob();

  const [modelSource, setModelSource] = useState("");
  const { data: modelSources, refetch: refetchDataSources } =
    usePlayTo3DDataPrivateSources();

  useEffect(() => {
    refetchDataSources();
    getGroupedPlayZonesByPeriod().then((response) => {
      const treeData = convertToTreeData(response.data) ?? [];
      setPeriodKeys(response.data.map((p) => `period_${p.period}`));
      setTreeData(treeData);
    });
  }, []);

  useEffect(() => {
    setIsModalVisible(requestShowModal);
    //updateProjects();
  }, [requestShowModal]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const formattedName = (name) => name.trim().toLowerCase();
      const name = formattedName(values.name);

      if (jobs.some((job) => formattedName(job.name) === name)) {
        setErrors("Job name already exists.");
        return;
      }

      const wells = values.wellList.split("\n").map((item) => item.trim());
      const validWells = await getValidWells(wells);

      if (validWells.length === 0) {
        setErrors("0 valid wells in the well list.");
        return;
      }
      const postData: GeoCalculationsJob = {
        ...values,
        jobId: uuidv4(),
        wellList: validWells,
        email
      };

      await mutateAsync(postData);

      setErrors("");
      onClose && onClose();
    } catch (e) {
      setErrors("Error occurred while creating job");
      return;
    }
  };

  const requiredRule = { required: true, message: "This field is required" };

  return (
    <RootContainer>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={onClose}
        title={"Add New Geo Calculation Job"}
        closable={false}
        maskClosable={false}>
        <Form
          name="edit"
          form={form}
          initialValues={{
            remember: true,
            leftWidth: 300,
            rightWidth: 300,
            upHeight: 50,
            downHeight: 50
          }}
          onFinish={handleOk}
          requiredMark={false}>
          <Form.Item name="name" label={<Label>Name</Label>} rules={[requiredRule]}>
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="leftWidth"
                label={<Label>Left Width</Label>}
                rules={[requiredRule]}>
                <InputNumber precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="rightWidth"
                label={<Label>Right Width</Label>}
                rules={[requiredRule]}>
                <InputNumber precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="upHeight"
                label={<Label>Up Height</Label>}
                rules={[requiredRule]}>
                <InputNumber precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="downHeight"
                label={<Label>Down Height</Label>}
                rules={[requiredRule]}>
                <InputNumber precision={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="wellList" label={<Label>Well List</Label>}>
                <TextArea autoSize={{ minRows: 15, maxRows: 15 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="modelSource" label={<Label>Source</Label>}>
                <Select
                  data-testid="model-source-select"
                  value={modelSource}
                  defaultValue={modelSource}
                  options={[{ label: "McDaniel", value: "" }].concat(
                    (modelSources || []).map((source) => ({
                      label: source,
                      value: source
                    }))
                  )}
                  onChange={(e) => {
                    setModelSource(e);
                    setSelectedIpdbZones([]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="zones" label={<Label>Zones</Label>}>
                <TreeSelect
                  showSearch
                  data-testid="geo-zone-select"
                  switcherIcon={
                    <SwitcherIcon>{<Icon path={mdiChevronDown} size={1} />}</SwitcherIcon>
                  }
                  value={selectedIpdbZones}
                  dropdownStyle={{ overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  maxTagCount={10}
                  onChange={setSelectedIpdbZones}
                  treeDefaultExpandedKeys={periodKeys}
                  treeCheckable={true}
                  treeData={treeData}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* //don't use project and filter for now, only accept well list

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="selectedProject" label={<Label>Project</Label>}>
                <Select
                  options={(projects ?? []).map((project) => ({
                    label: project.projectName,
                    value: project.projectId
                  }))}
                  onChange={(val) => {
                    const found = projects.findIndex((p) => p.projectId == val);
                    if (found > -1) {
                      updateSavedFilters(projects[found].projectId);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="savedFilterId"
                label={
                  <Label>
                    {" "}
                    <LabelWithTooltip>
                      <Label>Saved Filter</Label>
                      <Tooltip title="The selected filter determines which wells are included in the calculation">
                        <Icon path={mdiInformation} size={1} />
                      </Tooltip>
                    </LabelWithTooltip>
                  </Label>
                }
                rules={[requiredRule]}>
                <Select
                  options={(savedFilters ?? []).map((filter) => ({
                    value: filter.id,
                    label: filter.name
                  }))}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Alert message={"Only filters with well list or drawn polygon are shown."} />*/}
        </Form>

        {(isError || errors) && (
          <ErrorMessage
            type="error"
            message={`${
              error?.response?.data ?? errors ?? "Error occurred while creating job"
            }`}
          />
        )}
      </Modal>
    </RootContainer>
  );
};

const RootContainer = styled.div``;

const Label = styled.span`
  display: inline-block;
  width: 80px;
`;

const SwitcherIcon = styled.span`
  transform: translateY(2px);
  color: rgba(var(--color-text-rgb), 0.3);
`;
const ErrorMessage = styled(Alert)`
  margin-bottom: 1em;
`;

interface CreateAccessTokenModalProps {
  requestShowModal: boolean;
  jobs: GeoCalculationsJob[];
  onClose: () => void;
}
