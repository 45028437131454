// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiAlert, mdiCalendarClock } from "@mdi/js";
import { Table } from "antd";
import styled from "styled-components";

import { DataSourceFetcherResult, WellColumnDataAnalyzerResult } from "api/appTools";

import { Tooltip } from "components/base";
import { GeomBinProvider } from "components/geom-bin/contexts/GeomBinContext";

export interface ColumnDataAnalyzerTableProps {
  data: WellColumnDataAnalyzerResult;
  isLoading: boolean;
  analyzerError: string;
  analyzerErrorDetails: string;
}

const ColumnDataAnalyzerTable = ({
  data,
  isLoading,
  analyzerError,
  analyzerErrorDetails
}: ColumnDataAnalyzerTableProps) => {
  // State
  const [showAnalyzerErrorDetails, setShowAnalyzerErrorDetails] =
    useState<boolean>(false);

  // Table Columns
  const tableColumns = [
    {
      dataIndex: "dataSource",
      key: "dataSource",
      title: "Data Source"
    },
    {
      dataIndex: "dataFetcherName",
      key: "dataFetcherName",
      title: "Data Fetcher"
    },
    {
      dataIndex: "dataFetcherResult",
      key: "dataFetcherResult",
      title: "Data Fetcher Result"
    },
    {
      dataIndex: "mongoValue",
      key: "mongoValue",
      title: "Mongo Value",
      render: (text, record: DataSourceFetcherResult) => {
        const modifiedText = text == "null" ? <i>{text}</i> : text;
        if (record.mongoValueLastUpdated) {
          const utcDate = new Date(record.mongoValueLastUpdated);
          const localDate = utcDate.toLocaleString();
          return (
            <DateUpdatedWrapper>
              {modifiedText}{" "}
              <Tooltip title={localDate}>
                <Icon path={mdiCalendarClock} size={1.3} />
              </Tooltip>
            </DateUpdatedWrapper>
          );
        }
        return <span>{modifiedText}</span>;
      }
    },
    {
      dataIndex: "mcdanMongoValue",
      key: "mcdanMongoValue",
      title: "Mcdan Mongo Value",
      render: (text, record: DataSourceFetcherResult) => {
        const modifiedText = text == "null" ? <i>{text}</i> : text;
        if (record.mcdanMongoValueLastUpdated) {
          const utcDate = new Date(record.mcdanMongoValueLastUpdated);
          const localDate = utcDate.toLocaleString();
          return (
            <DateUpdatedWrapper>
              {modifiedText}{" "}
              <Tooltip title={localDate}>
                <Icon path={mdiCalendarClock} size={1.3} />
              </Tooltip>
            </DateUpdatedWrapper>
          );
        }
        return <span>{modifiedText}</span>;
      }
    }
  ];

  return (
    <GeomBinProvider>
      <div>
        <ResultsContainer>
          {data && !analyzerError && (
            <>
              <ResultsSummary>
                <p>
                  <b>Data Fetcher Value: </b>
                  <DataFetcherValueWrapper
                    isEmpty={
                      data?.dataFetcherResult === "" || data?.dataFetcherResult === "n/a"
                    }>
                    {data.dataFetcherResult}
                  </DataFetcherValueWrapper>
                </p>
              </ResultsSummary>
              <ResultsTable>
                <Table
                  dataSource={data.dataSourceResults}
                  columns={tableColumns}
                  rowKey="key"
                  pagination={false}
                  loading={isLoading}
                />
              </ResultsTable>
            </>
          )}
          {analyzerError && (
            <ErrorContainer>
              <ErrorMessage>
                <Icon path={mdiAlert} size={1} /> {analyzerError}
                <ErrorDetailsToggle
                  onClick={() => setShowAnalyzerErrorDetails(!showAnalyzerErrorDetails)}>
                  {showAnalyzerErrorDetails ? "hide details" : "show details"}
                </ErrorDetailsToggle>
              </ErrorMessage>
              {showAnalyzerErrorDetails && (
                <ErrorMessageDetails>{analyzerErrorDetails}</ErrorMessageDetails>
              )}
            </ErrorContainer>
          )}
        </ResultsContainer>
      </div>
    </GeomBinProvider>
  );
};

export default ColumnDataAnalyzerTable;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ResultsSummary = styled.div`
  width: 100%;
`;

const ResultsTable = styled.div`
  width: 100%;
`;

const DataFetcherValueWrapper = styled.span<{ isEmpty: boolean }>`
  display: inline-block;
  border-radius: 5px;
  background-color: ${({ isEmpty }) => (isEmpty ? "red" : "var(--color-primary)")};
  color: #fff;
  padding: 2px 5px;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 1px solid var(--color-danger);
  border-radius: 5px;
  padding: 3px 15px;
  font-weight: var(--fontWeightMedium);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-danger);
`;

const ErrorDetailsToggle = styled.span`
  color: #a2aaad;
  cursor: pointer;
`;

const ErrorMessageDetails = styled.div`
  width: 100%;
  color: var(--color-text);
  font-family: var(--fontMono);
  text-align: left;
`;

const DateUpdatedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    color: #a2aaad;
  }
`;
