import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Input } from "antd";
import _debounce from "lodash/debounce";
import { setVisSettings } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

function VisOptions() {
  const dispatch = useDispatch();
  const visSettings = useSelector((state: RootState) => state.app.visSettings);

  const [width, setWidth] = useState(visSettings.width);
  const [height, setHeight] = useState(visSettings.height);

  const updateVisSettings = useRef(
    _debounce((width, height) => {
      dispatch(
        setVisSettings({
          width,
          height
        })
      );
    }, 1200)
  ).current;

  useEffect(() => {
    updateVisSettings(width, height);
  }, [width, height]);

  return (
    <RootContainer>
      <div style={{ textAlign: "left" }}>
        <h6>Options</h6>
      </div>

      <Options>
        <OptionItem>
          <label>Width (m)</label>
          <Input
            type="number"
            value={width}
            max={1000}
            step={10}
            onChange={(evt) => setWidth(parseInt(evt.target.value))}
          />
        </OptionItem>

        <OptionItem>
          <label>Height (m)</label>
          <Input
            type="number"
            value={height}
            step={10}
            max={200}
            onChange={(evt) => setHeight(parseInt(evt.target.value))}
          />
        </OptionItem>
      </Options>
    </RootContainer>
  );
}

export default VisOptions;

const RootContainer = styled(Card)`
  height: 100%;
  display: grid;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: flex-start;
  grid-template-columns: minmax(0, 1fr);
  overflow-y: auto;
  .ant-card-body {
    display: flex;
    display: grid;
    padding: 5px;
    flex-direction: column;
  }
`;
const Options = styled.div`
  display: flex;
  flex-direction: row;
  .ant-card-body {
    padding: 5px;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;
export const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
  .ant-slider {
    width: 80px;
  }

  .ant-select {
    width: 100px;
  }
  .ant-input {
    height: 25px;
    max-width: 80px;
  }
  .ant-btn {
    border-radius: 5px;
  }
  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  padding: 2px 0;
`;
