import { predicateSourceMigration } from "utils/filter";

import { IProperty } from "hooks/useProperty";

import { LegendItemModel } from "models/LegendItem";
import { PropertyFilterModel } from "models/filter";
import { IGroupBy } from "models/groupBy";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

function toLegendItem(
  items,
  groupBy: IGroupBy,
  propertiesFilter: PropertyFilterModel[],
  groupByTitle?: IProperty,
  normalizeBySettings?
): LegendItemModel[] {
  const legendItems = items.map((item) => {
    const legendItemModel = new LegendItemModel(
      item.groupTitle,
      groupBy.property,
      groupBy.canBin,
      groupBy.categoryId,
      groupBy.bin,
      item.inFilter,
      item.count,
      normalizeBySettings
    );
    legendItemModel.pdenSource =
      typeof groupBy.pdenSource === "string"
        ? PdenSourceEnum[groupBy.pdenSource]
        : parseInt(groupBy.pdenSource);
    legendItemModel.color = item.color;
    legendItemModel.fontColor = "#555555";
    legendItemModel.groupByTitle = groupBy.title;
    legendItemModel.partialContextTitle = groupByTitle?.partialContextTitle;
    legendItemModel.isForecastToggleOn = groupByTitle?.isForecastToggleOn;
    legendItemModel.selectedForecastFolderName = groupByTitle?.forecastFolder;
    //take predicates only, flatten
    const props = propertiesFilter.reduce((list, filter) => {
      list.push(...filter.predicates);
      return list;
    }, []);
    const index = props.findIndex((li) => {
      let sourceKey = li.sourceKey;
      if (!sourceKey) {
        sourceKey = predicateSourceMigration(li);
      }
      const ok =
        li.property === legendItemModel.property && sourceKey === legendItemModel.value;
      return ok;
    });
    legendItemModel.isChecked = index >= 0;
    //set whether the checkd legend is set to != or =
    legendItemModel.isNotEqual = index >= 0 && props[index].negatedOperator;
    return legendItemModel;
  });
  return legendItems;
}

export default toLegendItem;
