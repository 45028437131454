/**
 * Obtains precision based on the unit of the axis title
 *
 * @param {string} title - chart axis title.
 * @returns {number} precision.
 */
export const getPrecisionBasedOnUnit = (title: string): number => {
  if (title.includes("MMbbl/d") || title.includes("Bcf/d")) {
    return 6;
  } else if (
    title.includes("Mbbl/d") ||
    title.includes("MMcf/d") ||
    title.includes("bbl/bbl")
  ) {
    return 3;
  } else if (
    title.includes("bbl/d") ||
    title.includes("Mcf/d") ||
    title.includes("bbl/MMcf") ||
    title.includes("scf/bbl")
  ) {
    return 0;
  } else {
    return 2;
  }
};
