import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  TreeSelect,
  Typography
} from "antd";
import styled from "styled-components";

import { AxiosErrorWithData } from "../../utils/errorUtil";
import { useProjectContext } from "../project/projects/context";
import { useProjectShapefilesQuery } from "../project/shapefiles/queries";
import { usePadScenario } from "./contexts/usePadScenario";
import usePostScenario from "./hooks/usePostScenario";
import { GetScenarioListKey } from "./hooks/useScenarioList";
import useUpdatePadScenario from "./hooks/useUpdatePadScenario";
import { Scenario } from "./models/scenario";

interface AddPadScenarioModalProps {
  open: boolean;
  mode: "add" | "edit";
  scenario?: Scenario;
  onClose: () => void;
}

export default function PadScenarioModal({
  open,
  onClose,
  mode,
  scenario
}: AddPadScenarioModalProps) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(open);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shapefilesDictRef = useRef<{ [shapefile: string]: any }>({});
  const [selectedShapefile, setSelectedShapefile] = useState<string>();
  const [shapefiles, setShapefiles] = useState([]);
  const { selectedProjectId } = useProjectContext();

  const projectShapefiles = useProjectShapefilesQuery({
    projectId: selectedProjectId
  });
  const [addedScenarioId, setAddedScenarioId] = useState<string | undefined>(undefined);

  const { mutateAsync: newScenario } = usePostScenario();
  const { mutateAsync: updateScenario } = useUpdatePadScenario();
  const { state, dispatch } = usePadScenario();
  const [form] = Form.useForm<FormInstance>();

  async function handleFinish(values) {
    const { scenarioId, padName, play, shapefileId } = values;
    const newValues = {
      scenarioId,
      padName,
      play,
      shapefileId,
      layerPlacements: scenario?.layerPlacements ?? [],
      stickPlacements: scenario?.stickPlacements ?? []
    } as Scenario;
    try {
      if (mode === "edit") {
        await updateScenario(newValues);
      } else {
        await newScenario(newValues);
      }
      await queryClient.invalidateQueries(GetScenarioListKey);
      if (mode === "add") {
        const scenarioId = newValues.scenarioId;
        setAddedScenarioId(scenarioId);
      }
      form.resetFields();
      onClose();
    } catch (err) {
      const axiosError = err as AxiosErrorWithData;
      setError(axiosError?.response?.data?.error ?? "An error occurred");
    }
  }

  useEffect(() => {
    if (scenario) {
      const values = {
        scenarioId: scenario.scenarioId,
        padName: scenario.padName,
        play: scenario.play,
        shapefileId: scenario.shapefileId
      } as unknown;
      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [scenario]);

  useEffect(() => {
    if (addedScenarioId) {
      const found = state.scenarioList.find((s) => s.scenarioId === addedScenarioId);
      if (found) {
        dispatch({ type: "SET_SCENARIO", payload: found });
      }
    }
  }, [state.scenarioList, addedScenarioId]);

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  useEffect(() => {
    if (!projectShapefiles?.data?.Project?.children) {
      return;
    }
    const parseShapefileNodes = (node, shapefilesDict) => {
      const n = {
        value: node.shapefileId ? node.shapefileId : node.shapefileNodeId,
        title: node.title,
        selectable: node.type === "shapefile",
        children:
          node.children?.map((ch) => parseShapefileNodes(ch, shapefilesDict)) ?? []
      };
      if (node.type === "shapefile") {
        shapefilesDict[node.shapefileId] = node;
      }
      return n;
    };
    setSelectedShapefile(undefined);
    shapefilesDictRef.current = {};

    const shapefileNodes = projectShapefiles.data.Project.children
      .map((node) => parseShapefileNodes(node, shapefilesDictRef.current))
      //remove empty folders
      .filter((node) => node.selectable || node.children.length > 0);
    setShapefiles(shapefileNodes);
  }, [projectShapefiles.data?.Project?.children]);
  return (
    <Modal
      open={showModal}
      footer={null}
      maskClosable={false}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      getContainer={() =>
        document.getElementById("scenario-modal-container") || document.body
      }>
      <Typography.Title level={4}>
        {mode == "add" ? "New" : "Edit"} Scenario
      </Typography.Title>
      <Form form={form} onFinish={handleFinish} size={"middle"}>
        <Form.Item
          label="Scenario Id"
          name="scenarioId"
          rules={[{ required: true, message: "Please input the scenario name!" }]}>
          <Input disabled={mode == "edit"} />
        </Form.Item>
        <Form.Item
          label="Pad Name"
          name="padName"
          rules={[{ required: true, message: "Please input the pad name!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Play"
          name="play"
          rules={[{ required: true, message: "Please input the play!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Shapefiles"
          name="shapefileId"
          rules={[{ required: true, message: "Please select a shapefile!" }]}>
          <TreeSelect
            treeLine
            popupClassName="shapefile-tree-select"
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Please select a Shapefile"
            treeData={shapefiles}
            onSelect={(value) => setSelectedShapefile(value)}
            value={selectedShapefile}
          />
        </Form.Item>
        <Form.Item>
          <ButtonWrapper>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </ButtonWrapper>
        </Form.Item>
      </Form>
      {error && <Alert type="error" message={error} />}
    </Modal>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
