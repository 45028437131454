import { memo } from "react";

import FillerCanIcon from "components/icons/FillerCan";

import { useChartDispatch, useChartState } from "../context";
import ToolbarButton from "./ToolbarButton";

const ProducingToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();

  const toggle = () => (value) => {
    const next = { ...settings, producing: value };
    dispatch({ type: "settings", payload: next });
  };

  return (
    <ToolbarButton
      icon={<FillerCanIcon />}
      active={settings.producing}
      overflowLabel="Producing"
      tooltipText="Toggle Producing Day Rate"
      onToggle={toggle()}
    />
  );
};

export default memo(ProducingToggle);
