import { FC } from "react";

import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Spin, Tooltip } from "antd";
import styled from "styled-components";

import SpinnerIcon from "components/icons/IconSpinner";

type CollapseToggleT = {
  checked: boolean;
  loading: boolean;
  onToggle: (b: boolean) => void;
  tooltipText?: string;
};

const CollapseToggle: FC<CollapseToggleT> = ({
  checked,
  loading,
  onToggle,
  tooltipText = "Toggle"
}) => {
  if (loading) return <Spin indicator={<SpinnerIcon />} />;

  return (
    <Wrapper
      active={checked}
      onClick={() => onToggle(!checked)}
      className="focus-collapse-toggle">
      <Tooltip title={tooltipText}>
        <ArrowDownIcon />
      </Tooltip>
    </Wrapper>
  );
};

export default CollapseToggle;

const Wrapper = styled.span`
  flex-shrink: 0;
  justify-self: flex-end;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-${(p) => (p.active ? "primary" : "text")});
  transform: rotate(${(p) => (p.active ? "0" : "-90")}deg);
  transition: transform 0.15s, color 0.15s;
  cursor: pointer;

  &:hover {
    color: var(--color-primary ${(p) => (p.active ? "-hover" : "")});
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
`;
