import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { ErrorBoundary } from "components/base";
import { GeomBinProvider } from "components/geom-bin/contexts/GeomBinContext";
import NavigationBar from "components/navigation/NavigationBar";
import OrganizationShapefiles from "components/org-settings/org-shapefiles/OrganizationShapefiles";
import { ProjectProvider } from "components/project/projects/context";
import ChatWidget from "components/support/ChatWidget";
import DocumentationDrawer from "components/user-documentation/DocumentationDrawer";
import AccountSecurity from "components/user-settings/AccountSecurity";
import ColorPalette from "components/user-settings/ColorPalette";
import DataSource from "components/user-settings/DataSource";
import DeclineSettings from "components/user-settings/DeclineSettings";
import DisplaySettings from "components/user-settings/DisplaySettings";
import Profile from "components/user-settings/Profile";
import ThirdPartyIntegration from "components/user-settings/ThirdPartyIntegration";
import UserDefaults from "components/user-settings/UserDefaults";
import UserSettingSideNav from "components/user-settings/UserSettingSideNav";
import WorkspacesList from "components/user-settings/WorkspacesList";
import WorkspaceManagement from "components/user-settings/components/workspace/WorkspaceManagement";

import "./SettingsLayout.scss";

export default function SettingsLayout({ authenticated: isAuthenticated }) {
  const activeSetting = useSelector(
    (state: RootState) => state.userSetting.activeSetting
  );

  const components = {
    Profile: <Profile />,
    AccountSecurity: <AccountSecurity />,
    DisplaySettings: <DisplaySettings />,
    UserDefaults: (
      <ProjectProvider>
        <GeomBinProvider>
          <UserDefaults />
        </GeomBinProvider>
      </ProjectProvider>
    ),
    WorkspacesList: <WorkspacesList />,
    WorkspaceManagement: <WorkspaceManagement />,
    ColorPalette: <ColorPalette />,
    DataSource: <DataSource />,
    ThirdPartyIntegration: <ThirdPartyIntegration />,
    DeclineSettings: <DeclineSettings />,
    OrganizationShapefiles: <OrganizationShapefiles />
  };
  return (
    <div className="SettingsOverlay">
      <div className="SettingsLayout">
        <NavigationBar showDashboardButtons={false}></NavigationBar>
        <div className="settings-content">
          <div className="settings-side-bar">
            <UserSettingSideNav />
          </div>
          <div
            className={activeSetting === "OrganizationShapefiles" ? "userAccount" : ""}
            style={{ overflowY: "auto" }}>
            <ErrorBoundary>
              {isAuthenticated ? components[activeSetting] : null}
            </ErrorBoundary>
          </div>
        </div>
        <DocumentationDrawer />
        <ChatWidget />
      </div>
    </div>
  );
}
