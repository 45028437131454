import { useDispatch, useSelector } from "react-redux";

import { SortByT, updateSortBy } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import {
  IconAlphabeticalAsc,
  IconAlphabeticalDesc,
  NumericalAsc,
  NumericalDesc
} from "components/icons";

export const options = [
  {
    name: "AlphabeticalAsc",
    label: "Value Ascending",
    icon: <IconAlphabeticalAsc />
  },
  {
    name: "AlphabeticalDesc",
    label: "Value Descending",
    icon: <IconAlphabeticalDesc />
  },
  {
    name: "CountAsc",
    label: "Count Ascending",
    icon: <NumericalAsc />
  },
  {
    name: "CountDesc",
    label: "Count Descending",
    icon: <NumericalDesc />
  },
  {
    name: "ProdAsc",
    label: "Prod Ascending",
    icon: <NumericalAsc />
  },
  {
    name: "ProdDesc",
    label: "Prod Descending",
    icon: <NumericalDesc />
  }
];

const SortOptions = ({ onSelect }) => {
  const dispatch = useDispatch();
  const sortBy = useSelector((state: RootState) => state.filter.sortBy);

  const handleOptionClick = (option) => () => {
    const { name } = option;
    dispatch(updateSortBy(name as SortByT));
    onSelect(option);
  };

  return (
    <Wrapper>
      {options.map((option) => {
        const { name, icon, label } = option;
        return (
          <Item key={name} selected={name === sortBy} onClick={handleOptionClick(option)}>
            {icon}
            {label}
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default SortOptions;

const Wrapper = styled.div`
  padding: var(--space-4) 0;
  font-weight: var(--fontWeightMedium);
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-${(p) => (p.selected ? "text" : "text-50")});
  background: ${(p) => (p.selected ? "var(--color-text-06)" : "transparent")};
  padding: var(--space-2) var(--space-5);
  user-select: none;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
    background-color: var(--color-text-06);
  }
`;
