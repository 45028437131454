import { ReactNode } from "react";

import { IScaleBy } from "constants/visualization.constants";

import {
  WellCardTypeLogPdf,
  WellDetailCardData,
  WellDetailCardDataGroup
} from "api/data";

import { IGroupByMap } from "models";

export type Action = { type: "update XDA settings"; payload: XDASettingsT };

export type State = {
  xda: {
    settings: XDASettingsT;
  };
};

export type Dispatch = (action: Action) => void;

export type VisProviderT = { children: ReactNode };

export const IpdbColors = ["rainbow", "jet", "viridis", "portland"] as const;
export type IpdbColor = (typeof IpdbColors)[number];

export class IpdbBin {
  lessThan?: number;
  binSize?: number;
  greaterThan?: number;
  isDefault?: boolean;

  constructor(lessThan?: number, binSize?: number, greaterThan?: number) {
    this.lessThan = lessThan;
    this.binSize = binSize;
    this.greaterThan = greaterThan;
  }
}

export enum XDA_RADIUS_TYPES {
  Gross = "Gross",
  SweetSpot = "Sweet Spot",
  LandingZone = "Landing Zone",
  Custom = "Custom"
}

export type XDASettingsT = {
  bin?: IpdbBin;
  completionLength: number;
  dataFields: IGroupByMap[];
  downHeight: number;
  hangWellsToTop: boolean;
  isUsingResourcePlayDefault: boolean;
  ipdbColor: IpdbColor;
  ipdbField: string;
  ipdbUnit: string;
  ipdbIsDefault: boolean;
  ipdbSource: string;
  ipdbFontSize: number;
  ipdbOpacity: number;
  leftWidth: number;
  reverseColor: boolean;
  rightWidth: number;
  scaleByOption: IScaleBy;
  scaleByValue: number;
  showCompletion: boolean;
  showData: boolean;
  showGrid: boolean;
  showScreenshot: boolean;
  showIpdb: boolean;
  showIpdbLegend: boolean;
  showMeasurement: boolean;
  showTooltip: boolean;
  showXdaValueInfo: boolean;
  upHeight: number;
  topsModelSource: string[];
  showAllTops: boolean;
  showOverlap: boolean;
  selectedRadius: string;
  widthScaled: number;
};

export type WellDetailsAction =
  | { type: "update well details"; payload: WellDetailCardData }
  | { type: "append well details"; payload: WellDetailCardData }
  | { type: "set groups well details"; payload: WellDetailCardDataGroup }
  | { type: "set well type log pdf"; payload: WellCardTypeLogPdf }
  | { type: "set active group"; payload: string }
  | { type: "reset well details context"; payload: undefined };

export type WellDetailsState = {
  wellDetails: WellDetailCardData;
  loadedGroups: string[];
  currentGroup: string;
};
export type WellDetailsDispatch = (action: WellDetailsAction) => void;
export type WellDetailsProviderT = { children: ReactNode };
