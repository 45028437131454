import { BetaFeatures } from "hooks/useBetaFeatures";

import {
  ChartAxisType,
  IAllChartTypes,
  IAllProductTypes,
  IChartSettings
} from "models/chart";
import { EntityKind } from "models/entityKind";

import { StateT, defaultChartState } from "components/chart/context";
import OilCond from "components/icons/OilCond";

import { CalculationMethod } from "../components/chart/components/stacked-bar-options/StackedBarCalculation";
import {
  C2,
  C3,
  C4,
  C5,
  Flared,
  Fuel,
  GPWater,
  GasEnergy,
  GasInlet,
  GasOutlet,
  GasSales,
  GasShrinkage,
  HeatingValue,
  NglMix,
  NglMix234,
  OilCondC5,
  PolymerInjection,
  RatioIcon,
  SalesLiquids,
  Vented
} from "../components/icons";
import AcidGasInjection from "../components/icons/AcidGasInjection";
import CO2Injection from "../components/icons/CO2Injection";
import GasInjection from "../components/icons/GasInjection";
import OilCut from "../components/icons/OilCut";
import OnTimePercent from "../components/icons/OnTimePercent";
import ProductBoe from "../components/icons/ProductBoe";
import ProductGas from "../components/icons/ProductGas";
import ProductWater from "../components/icons/ProductWater";
import RatioCGR from "../components/icons/RatioCGR";
import RatioCSOR from "../components/icons/RatioCSOR";
import RatioCWB from "../components/icons/RatioCWB";
import RatioGOR from "../components/icons/RatioGOR";
import RatioISOR from "../components/icons/RatioISOR";
import RatioIWB from "../components/icons/RatioIWB";
import RatioOnePlusWOR from "../components/icons/RatioOnePlusWOR";
import RatioWGR from "../components/icons/RatioWGR";
import RatioWOR from "../components/icons/RatioWOR";
import SolventInjection from "../components/icons/SolventInjection";
import SteamInjection from "../components/icons/SteamInjection";
import Sulphur from "../components/icons/Sulphur";
import TotalFluid from "../components/icons/TotalFluid";
import TotalFluidInjection from "../components/icons/TotalFluidInjection";
import TotalGasInjection from "../components/icons/TotalGasInjection";
import WaterCut from "../components/icons/WaterCut";
import WaterInjection from "../components/icons/WaterInjection";
import { IChartResult } from "../models/model";

export const DEFAULT_TITLE_FONT_SIZE = 14;
export const DEFAULT_AXIS_FONT_SIZE = 12;
export const DEFAULT_AXIS_FONT_WEIGHT = 400;
export const DEFAULT_LEGEND_FONT_SIZE = 12;
export const DEFAULT_AXIS_LABEL_FONT_SIZE = 12;
export const DEFAULT_DOT_OPACITY = 1;
export const DEFAULT_DOT_SIZE = 5;

export const AXIS_TYPE: { [key: string]: ChartAxisType } = Object.freeze({
  x: "x",
  y: "y"
});

const hasPlantLiquids = (hasFeature: (feature: BetaFeatures) => boolean): boolean => {
  return hasFeature("Plant Liquids");
};

const hasMidstream = (hasFeature: (feature: BetaFeatures) => boolean): boolean => {
  return hasFeature("Facility");
};

// To change chart title and axis labels go to ProductType.cs in eva-chart-svc
export const ALL_PRODUCT_TYPES: IAllProductTypes = {
  // primary
  Oil: {
    key: "Oil",
    label: "Oil + Cond",
    id: 0,
    icon: <OilCond key="oil-cond" />
  },
  Gas: {
    key: "Gas",
    label: "Gas",
    id: 1,
    icon: <ProductGas key="product-gas" />
  },
  Water: {
    key: "Water",
    label: "Water",
    id: 3,
    icon: <ProductWater key="product-water" />
  },
  TotalFluid: {
    key: "Total Fluid",
    label: "Total Fluid",
    id: 4,
    icon: <TotalFluid key="total-fluid" />
  },
  BOE: {
    key: "BOE",
    label: "BOE",
    id: 12,
    icon: <ProductBoe key="product-boe" />
  },
  WaterInj: {
    key: "Water Inj",
    label: "Water Inj",
    id: 15,
    icon: <WaterInjection key="water-injection" />
  },
  GasInj: {
    key: "Gas Inj",
    label: "Gas Inj",
    id: 14,
    icon: <GasInjection key="gas-injection" />
  },
  SteamInj: {
    key: "Steam Inj",
    label: "Steam Inj",
    id: 13,
    icon: <SteamInjection key="steam-injection" />
  },
  PolymerInj: {
    key: "Polymer Inj",
    label: "Polymer Inj",
    id: 20,
    icon: <PolymerInjection key="polymer-injection" />
  },
  CO_2Inj: {
    key: "CO2 Inj",
    label: "CO₂ Inj",
    id: 24,
    icon: <CO2Injection key="co2-injection" />
  },
  AcidGasInj: {
    key: "Acid Gas Inj",
    label: "Acid Gas Inj",
    id: 25,
    icon: <AcidGasInjection key="acid-gas-injection" />
  },
  SolventInj: {
    key: "Solvent Inj",
    label: "Solvent Inj",
    id: 26,
    icon: <SolventInjection key="solvent-injection" />
  },
  TotalFluidInj: {
    key: "Total Fluid Inj",
    label: "Total Fluid Inj",
    id: 27,
    icon: <TotalFluidInjection key="total-fluid-injection" />
  },
  TotalGasInj: {
    key: "Total Gas Inj",
    label: "Total Gas Inj",
    id: 28,
    icon: <TotalGasInjection key="total-gas-injection" />
  },
  // ratio
  CGR: {
    key: "CGR",
    label: "(O+C)GR",
    id: 6,
    lock: 1000,
    icon: <RatioCGR key="ratio-cgr" />
  },
  GOR: {
    key: "GOR",
    label: "GOR",
    id: 5,
    lock: 20000,
    icon: <RatioGOR key="ratio-gor" />
  },
  WGR: {
    key: "WGR",
    label: "WGR",
    id: 7,
    icon: <RatioWGR key="ratio-wgr" />
  },
  WOR: {
    key: "WOR",
    label: "WOR",
    id: 18,
    icon: <RatioWOR key="ratio-wor" />
  },
  OnePlusWOR: {
    key: "1+WOR",
    label: "1+WOR",
    id: 19,
    icon: <RatioOnePlusWOR key="ratio-one-plus-wor" />
  },
  ISOR: {
    key: "ISOR",
    label: "ISOR",
    id: 9,
    lock: 10,
    icon: <RatioISOR key="ratio-isor" />
  },
  CSOR: {
    key: "CSOR",
    label: "CSOR",
    id: 10,
    lock: 10,
    icon: <RatioCSOR key="ratio-csor" />
  },
  IWB: {
    key: "IWB",
    label: "IWB",
    id: 16,
    icon: <RatioIWB key="ratio-iwb" />
  },
  CWB: {
    key: "CWB",
    label: "CWB",
    id: 17,
    icon: <RatioCWB key="ratio-cwb" />
  },
  // percent
  OilCut: {
    key: "Oil Cut",
    label: "Oil Cut",
    id: 8,
    icon: <OilCut key="oil-cut" />
  },
  WaterCut: {
    key: "Water Cut",
    label: "Water Cut",
    id: 11,
    icon: <WaterCut key="water-cut" />
  },
  OnTime: {
    key: "OnTime",
    label: "On-Time",
    id: 20,
    icon: <OnTimePercent key="on-time" />
  },
  Sulphur: {
    id: 21,
    key: "Sulphur",
    label: "Sulphur",
    icon: <Sulphur key="sulphur" />,
    available: hasMidstream
  },
  SulphurYieldsOutlet: {
    id: 22,
    key: "Sulphur Yields Sales",
    label: "Sulphur Yields Outlet",
    icon: (
      <RatioIcon
        key="sales-sulphur"
        numeratorIcon={<Sulphur />}
        denominatorIcon={<GasOutlet />}
      />
    ),
    available: hasMidstream
  },
  SulphurYieldsInlet: {
    id: 23,
    key: "Sulphur Yields Raw",
    label: "Sulphur Yields Inlet",
    icon: (
      <RatioIcon
        key="raw-sulphur"
        numeratorIcon={<Sulphur />}
        denominatorIcon={<GasInlet />}
      />
    ),
    available: hasMidstream
  },
  C2: {
    id: 32,
    key: "C2",
    label: "C2",
    available: hasPlantLiquids,
    icon: <C2 key="c2" />
  },
  C2YieldsRaw: {
    id: 40,
    key: "C2 Yields Raw",
    label: "C2 Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c2" numeratorIcon={<C2 />} denominatorIcon={<ProductGas />} />
    )
  },
  C2YieldsSales: {
    id: 48,
    key: "C2 Yields Sales",
    label: "C2 Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c2" numeratorIcon={<C2 />} denominatorIcon={<GasSales />} />
    )
  },
  C3: {
    id: 33,
    key: "C3",
    label: "C3",
    available: hasPlantLiquids,
    icon: <C3 key="c3" />
  },
  C3YieldsRaw: {
    id: 41,
    key: "C3 Yields Raw",
    label: "C3 Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c3" numeratorIcon={<C3 />} denominatorIcon={<ProductGas />} />
    )
  },
  C3YieldsSales: {
    id: 49,
    key: "C3 Yields Sales",
    label: "C3 Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c3" numeratorIcon={<C3 />} denominatorIcon={<GasSales />} />
    )
  },
  C4: {
    id: 34,
    key: "C4",
    label: "C4",
    available: hasPlantLiquids,
    icon: <C4 key="c4" />
  },
  C4YieldsRaw: {
    id: 42,
    key: "C4 Yields Raw",
    label: "C4 Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c4" numeratorIcon={<C4 />} denominatorIcon={<ProductGas />} />
    )
  },
  C4YieldsSales: {
    id: 50,
    key: "C4 Yields Sales",
    label: "C4 Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c4" numeratorIcon={<C4 />} denominatorIcon={<GasSales />} />
    )
  },
  C5: {
    id: 35,
    key: "C5",
    label: "C5+",
    available: hasPlantLiquids,
    icon: <C5 key="c5" />
  },
  C5YieldsRaw: {
    id: 43,
    key: "C5 Yields Raw",
    label: "C5+ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="raw-c5" numeratorIcon={<C5 />} denominatorIcon={<ProductGas />} />
    )
  },
  C5YieldsSales: {
    id: 51,
    key: "C5 Yields Sales",
    label: "C5+ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon key="sales-c5" numeratorIcon={<C5 />} denominatorIcon={<GasSales />} />
    )
  },
  LiquidsYieldsRaw: {
    id: 45,
    key: "Liquids Yields Raw",
    label: "Liquids Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-liquids"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<ProductGas />}
      />
    )
  },
  LiquidsYieldsSales: {
    id: 52,
    key: "Liquids Yields Sales",
    label: "Liquids Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-liquid-ngl-mix"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<GasSales />}
      />
    )
  },
  NGL_234: {
    id: 39,
    key: "NGL_234",
    label: "NGL₂₃₄",
    available: hasPlantLiquids,
    icon: <NglMix234 key="ngl-234" />
  },
  NGLMix: {
    id: 38,
    key: "NGL Mix",
    label: "NGL Mix",
    available: hasPlantLiquids,
    icon: <NglMix key="ngl-mix" />
  },
  NGLMixYieldsRaw: {
    id: 46,
    key: "NGL Mix Yields Raw",
    label: "NGL Mix Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<ProductGas />}
      />
    )
  },
  NGLMixYieldsSales: {
    id: 54,
    key: "NGL Mix Yields Sales",
    label: "NGL Mix Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<GasSales />}
      />
    )
  },
  NGL_234YieldsRaw: {
    id: 47,
    key: "NGL_234 Yields Raw",
    label: "NGL₂₃₄ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<ProductGas />}
      />
    )
  },
  NGL_234YieldsSales: {
    id: 55,
    key: "NGL_234 Yields Sales",
    label: "NGL₂₃₄ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<GasSales />}
      />
    )
  },
  OilCondC5: {
    id: 37,
    key: "Oil+Cond+C5",
    label: "Oil, Cond, C5+",
    available: hasPlantLiquids,
    icon: <OilCondC5 key="oil-cond-c5" />
  },
  OilCondC5YieldsRaw: {
    id: 44,
    key: "Oil+Cond+C5 Yields Raw",
    label: "Oil, Cond, C5+ Yields Raw",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="raw-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<ProductGas />}
      />
    )
  },
  OilCondC5YieldsSales: {
    id: 53,
    key: "Oil+Cond+C5 Yields Sales",
    label: "Oil, Cond, C5+ Yields Sales",
    available: hasPlantLiquids,
    icon: (
      <RatioIcon
        key="sales-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<GasSales />}
      />
    )
  },
  SalesGas: {
    id: 30,
    key: "Sales Gas",
    label: "Sales Gas",
    available: hasPlantLiquids,
    icon: <GasSales key="sales-gas" />
  },
  SalesLiquids: {
    id: 36,
    key: "Sales Liquids",
    label: "Sales Liquids",
    available: hasPlantLiquids,
    icon: <SalesLiquids key="yields-sales-liquids" />
  },
  Shrinkage: {
    id: 31,
    key: "Shrinkage",
    label: "Shrinkage",
    available: hasPlantLiquids,
    icon: <GasShrinkage key="shrinkage" />
  },
  GasEnergy: {
    id: 56,
    key: "Gas Energy",
    label: "Gas Energy",
    available: hasPlantLiquids,
    icon: <GasEnergy key="gasEnergy" />
  },
  GasEnergyYieldsSales: {
    id: 57,
    key: "Gas Energy Yields Sales",
    label: "Heating Value",
    available: hasPlantLiquids,
    icon: <HeatingValue key="sales-gasEnergy" />
  },
  SalesBOE: {
    id: 59,
    key: "Sales BOE",
    label: "Sales BOE",
    available: hasPlantLiquids,
    icon: <ProductBoe key="sales-boe" />
  },
  GPWater: {
    id: 60,
    key: "Water Condensation",
    label: "GP Water",
    available: hasMidstream,
    icon: <GPWater key="gp-water" />
  },
  GPWaterYieldsOutlet: {
    id: 61,
    key: "Water Condensation Yields Sales",
    label: "GP Water Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="sales-gp-water"
        numeratorIcon={<GPWater />}
        denominatorIcon={<GasOutlet />}
      />
    )
  },
  GPWaterYieldsInlet: {
    id: 62,
    key: "Water Condensation Yields Raw",
    label: "GP Water Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="raw-gp-water"
        numeratorIcon={<GPWater />}
        denominatorIcon={<GasInlet />}
      />
    )
  },

  /*** midstream products **/
  GasInlet: {
    key: "Gas",
    label: "Gas Inlet",
    id: 100,
    icon: <GasInlet key="product-gas-inlet" />,
    available: hasMidstream
  },
  GasOutlet: {
    key: "Sales Gas",
    label: "Gas Outlet",
    id: 101,
    icon: <GasOutlet key="product-gas-outlet" />,
    available: hasMidstream
  },
  H2S: {
    key: "H2S",
    label: "H2S",
    id: 102,
    icon: <ProductGas key="product-h2s" />,
    available: hasMidstream
  },
  Fuel: {
    key: "Fuel",
    label: "Fuel",
    id: 103,
    icon: <Fuel key="product-fuel" />,
    available: hasMidstream
  },
  Flared: {
    key: "Flared",
    label: "Flared",
    id: 104,
    icon: <Flared key="product-flare" />,
    available: hasMidstream
  },
  Vented: {
    key: "Vented",
    label: "Vented",
    id: 105,
    icon: <Vented key="product-vent" />,
    available: hasMidstream
  },
  PlantShrinkage: {
    key: "Plant Shrinkage",
    label: "Plant Shrinkage",
    id: 105,
    icon: <GasShrinkage key="product-plant-shrinkage" />,
    available: hasMidstream
  },
  C2YieldsInlet: {
    id: 108,
    key: "C2 Yields Raw",
    label: "C2 Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c2" numeratorIcon={<C2 />} denominatorIcon={<GasInlet />} />
    )
  },
  C2YieldsOutlet: {
    id: 109,
    key: "C2 Yields Sales",
    label: "C2 Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="outlet-c2" numeratorIcon={<C2 />} denominatorIcon={<GasOutlet />} />
    )
  },
  C3YieldsInlet: {
    id: 110,
    key: "C3 Yields Raw",
    label: "C3 Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c3" numeratorIcon={<C3 />} denominatorIcon={<GasInlet />} />
    )
  },
  C3YieldsOutlet: {
    id: 111,
    key: "C3 Yields Sales",
    label: "C3 Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="outlet-c3" numeratorIcon={<C3 />} denominatorIcon={<GasOutlet />} />
    )
  },
  C4YieldsInlet: {
    id: 112,
    key: "C4 Yields Raw",
    label: "C4 Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c4" numeratorIcon={<C4 />} denominatorIcon={<GasInlet />} />
    )
  },
  C4YieldsOutlet: {
    id: 113,
    key: "C4 Yields Sales",
    label: "C4 Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="outlet-c4" numeratorIcon={<C4 />} denominatorIcon={<GasOutlet />} />
    )
  },
  C5YieldsInlet: {
    id: 114,
    key: "C5 Yields Raw",
    label: "C5+ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="inlet-c5" numeratorIcon={<C5 />} denominatorIcon={<GasInlet />} />
    )
  },
  C5YieldsOutlet: {
    id: 115,
    key: "C5 Yields Sales",
    label: "C5+ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon key="outlet-c5" numeratorIcon={<C5 />} denominatorIcon={<GasOutlet />} />
    )
  },
  LiquidsYieldsInlet: {
    id: 116,
    key: "Liquids Yields Raw",
    label: "Liquids Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-liquids"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<GasInlet />}
      />
    )
  },
  LiquidsYieldsOutlet: {
    id: 117,
    key: "Liquids Yields Sales",
    label: "Liquids Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-liquids"
        numeratorIcon={<SalesLiquids />}
        denominatorIcon={<GasOutlet />}
      />
    )
  },
  NGLMixYieldsInlet: {
    id: 118,
    key: "NGL Mix Yields Raw",
    label: "NGL Mix Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<GasInlet />}
      />
    )
  },
  NGLMixYieldsOutlet: {
    id: 119,
    key: "NGL Mix Yields Sales",
    label: "NGL Mix Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-ngl-mix"
        numeratorIcon={<NglMix />}
        denominatorIcon={<GasOutlet />}
      />
    )
  },
  NGL_234YieldsInlet: {
    id: 120,
    key: "NGL_234 Yields Raw",
    label: "NGL₂₃₄ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<GasInlet />}
      />
    )
  },
  NGL_234YieldsOutlet: {
    id: 121,
    key: "NGL_234 Yields Sales",
    label: "NGL₂₃₄ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-ngl-234"
        numeratorIcon={<NglMix234 />}
        denominatorIcon={<GasOutlet />}
      />
    )
  },
  OilCondC5YieldsInlet: {
    id: 122,
    key: "Oil+Cond+C5 Yields Raw",
    label: "Oil, Cond, C5+ Yields Inlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="inlet-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<GasInlet />}
      />
    )
  },
  OilCondC5YieldsOutlet: {
    id: 123,
    key: "Oil+Cond+C5 Yields Sales",
    label: "Oil, Cond, C5+ Yields Outlet",
    available: hasMidstream,
    icon: (
      <RatioIcon
        key="outlet-oil-cond-c5"
        numeratorIcon={<OilCondC5 />}
        denominatorIcon={<GasOutlet />}
      />
    )
  }
};

/**
 * It returns the label of the product that matches the key variable from the ALL_PRODUCT_TYPES object.
 * If no product matches the key variable, it returns the key variable itself.
 *
 * @param {string} key - The key of the product.
 * @returns {string} - The label of the matched product or the key if no product matches.
 */
export function getProductLabel(key: string, entityKind?: string): string {
  let product;
  if (entityKind === EntityKind.Well) {
    product = Object.values(ALL_PRODUCT_TYPES).find((prod) => prod.key === key);
  } else {
    product = Object.values(ALL_PRODUCT_TYPES).find(
      (prod) => prod.key === key && prod.available === hasMidstream
    );
    if (!product) {
      product = Object.values(ALL_PRODUCT_TYPES).find((prod) => prod.key === key);
    }
  }
  return product ? product.label : key;
}

export type ChartTypeLabels =
  | "Rate Cum"
  | "Rate Time"
  | "Rate Date"
  | "Total Rate Date"
  | "Cum Time"
  | "Cross Plot"
  | "Probit"
  | "Box Plot"
  | "Stacked Bar"
  | "Pie"
  | "Mosaic"
  | "Material Balance Time"
  | "Total Rate Cum"
  | "CAGR"
  | "Decline Rate"
  | "Trend Date"
  | "Well Contribution";

export const ALL_CHART_TYPES: IAllChartTypes = {
  RateCum: { label: "Rate Cum", id: 1 },
  RateTime: { label: "Rate Time", id: 2 },
  RateDate: { label: "Rate Date", id: 3 },
  TotalRateDate: { label: "Total Rate Date", id: 4 },
  CumTime: { label: "Cum Time", id: 10 },
  CrossPlot: { label: "Cross Plot", id: 0, lock: { x: true, y: true } },
  Probit: { label: "Probit", id: 5, lock: { x: true, y: true } },
  BoxPlot: { label: "Box Plot", id: 6, lock: { x: false, y: true } },
  StackedBar: { label: "Stacked Bar", id: 8, lock: { x: false, y: true } },
  Pie: { label: "Pie", id: 7, lock: { x: false, y: false } },
  Mosaic: { label: "Mosaic", id: 9, lock: { x: false, y: true } },
  MaterialBalanceTime: {
    label: "Material Balance Time",
    id: 11,
    lock: { x: true, y: true }
  },
  TotalRateCum: { label: "Total Rate Cum", id: 12 },
  CAGR: {
    label: "CAGR",
    id: 13,
    tooltip: "Compound Annual Growth Rate"
  },
  BaseDeclineRate: {
    label: "Decline Rate",
    id: 14,
    tooltip: "Base Decline Rate"
  },
  TrendDate: { label: "Trend Date", id: 15 },
  WellContribution: { label: "Well Contribution", id: 16 }
};

export type ChartTypeT = {
  name: string;
  items: ChartTypeLabels[];
};

export const ChartType: ChartTypeT[] = [
  {
    name: "Average",
    items: [
      ALL_CHART_TYPES.RateCum.label,
      ALL_CHART_TYPES.RateTime.label,
      ALL_CHART_TYPES.CumTime.label
    ]
  },
  {
    name: "Aggregate",
    items: [
      ALL_CHART_TYPES.RateDate.label,
      ALL_CHART_TYPES.TotalRateDate.label,
      ALL_CHART_TYPES.TotalRateCum.label
    ]
  },
  {
    name: "",
    items: [
      ALL_CHART_TYPES.CrossPlot.label,
      ALL_CHART_TYPES.Probit.label,
      ALL_CHART_TYPES.BoxPlot.label,
      ALL_CHART_TYPES.StackedBar.label,
      ALL_CHART_TYPES.Pie.label,
      ALL_CHART_TYPES.Mosaic.label,
      ALL_CHART_TYPES.MaterialBalanceTime.label,
      ALL_CHART_TYPES.CAGR.label,
      ALL_CHART_TYPES.BaseDeclineRate.label,
      ALL_CHART_TYPES.TrendDate.label
    ]
  }
];

export const MidstreamChartTypes: ChartTypeT[] = [
  {
    name: "Aggregate",
    items: [
      ALL_CHART_TYPES.RateDate.label,
      ALL_CHART_TYPES.TotalRateDate.label,
      ALL_CHART_TYPES.WellContribution.label
    ]
  },
  {
    name: "",
    items: [ALL_CHART_TYPES.CrossPlot.label]
  }
];

export const CHART_TYPE_LABELS = Object.keys(ALL_CHART_TYPES).map(
  (key) => ALL_CHART_TYPES[key].label
);

export const ChartProductTypes = [
  ALL_CHART_TYPES.RateCum.label,
  ALL_CHART_TYPES.RateTime.label,
  ALL_CHART_TYPES.RateDate.label,
  ALL_CHART_TYPES.TotalRateDate.label,
  ALL_CHART_TYPES.CumTime.label,
  ALL_CHART_TYPES.MaterialBalanceTime.label,
  ALL_CHART_TYPES.TotalRateCum.label,
  ALL_CHART_TYPES.CAGR.label,
  ALL_CHART_TYPES.BaseDeclineRate.label,
  ALL_CHART_TYPES.TrendDate.label
];
export type ProductTypeT = {
  name: string;
  items: string[];
};
export const SalesProduct: ProductTypeT[] = [
  {
    name: "Liquids",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5.label,
      ALL_PRODUCT_TYPES.NGL_234.label,
      ALL_PRODUCT_TYPES.NGLMix.label,
      ALL_PRODUCT_TYPES.SalesLiquids.label,
      ALL_PRODUCT_TYPES.C2.label,
      ALL_PRODUCT_TYPES.C3.label,
      ALL_PRODUCT_TYPES.C4.label,
      ALL_PRODUCT_TYPES.C5.label,
      ALL_PRODUCT_TYPES.SalesBOE.label
    ]
  },
  {
    name: "Gas",
    items: [
      ALL_PRODUCT_TYPES.SalesGas.label,
      ALL_PRODUCT_TYPES.GasEnergy.label,
      ALL_PRODUCT_TYPES.GasEnergyYieldsSales.label,
      ALL_PRODUCT_TYPES.Shrinkage.label
    ]
  },
  {
    name: "Yields Raw",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5YieldsRaw.label,
      ALL_PRODUCT_TYPES.NGL_234YieldsRaw.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsRaw.label,
      ALL_PRODUCT_TYPES.LiquidsYieldsRaw.label,
      ALL_PRODUCT_TYPES.C2YieldsRaw.label,
      ALL_PRODUCT_TYPES.C3YieldsRaw.label,
      ALL_PRODUCT_TYPES.C4YieldsRaw.label,
      ALL_PRODUCT_TYPES.C5YieldsRaw.label
    ]
  },
  {
    name: "Yields Sales",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5YieldsSales.label,
      ALL_PRODUCT_TYPES.NGL_234YieldsSales.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsSales.label,
      ALL_PRODUCT_TYPES.LiquidsYieldsSales.label,
      ALL_PRODUCT_TYPES.C2YieldsSales.label,
      ALL_PRODUCT_TYPES.C3YieldsSales.label,
      ALL_PRODUCT_TYPES.C4YieldsSales.label,
      ALL_PRODUCT_TYPES.C5YieldsSales.label
    ]
  }
];

export const SalesProductList = SalesProduct.flatMap((category) => category.items);

export const CrossKindProduct = [
  {
    group: ALL_PRODUCT_TYPES.Oil.key,
    items: [ALL_PRODUCT_TYPES.OilCondC5]
  },
  {
    group: ALL_PRODUCT_TYPES.CGR.key,
    items: [ALL_PRODUCT_TYPES.OilCondC5YieldsRaw, ALL_PRODUCT_TYPES.OilCondC5YieldsSales]
  },
  {
    group: ALL_PRODUCT_TYPES.Gas.key,
    items: [ALL_PRODUCT_TYPES.SalesGas]
  }
];

export const CrossKindProductList = CrossKindProduct.flatMap((category) =>
  category.items.map((item) => item.label)
);

export const ProductKind = {
  Summary: 0,
  Wellhead: 1,
  Sales: 2,
  Midstream: 3,
  Injection: 4
};

export const ChartProduct: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.TotalFluid.label,
      ALL_PRODUCT_TYPES.BOE.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.CGR.label,
      ALL_PRODUCT_TYPES.GOR.label,
      ALL_PRODUCT_TYPES.WGR.label,
      ALL_PRODUCT_TYPES.WOR.label,
      ALL_PRODUCT_TYPES.OnePlusWOR.label
    ]
  },
  {
    name: "Percent",
    items: [
      ALL_PRODUCT_TYPES.OilCut.label,
      ALL_PRODUCT_TYPES.WaterCut.label,
      ALL_PRODUCT_TYPES.OnTime.label
    ]
  }
];

export const SummaryProduct: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.BOE.label,
      ALL_PRODUCT_TYPES.SalesBOE.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.OilCondC5YieldsRaw.label,
      ALL_PRODUCT_TYPES.GOR.label,
      ALL_PRODUCT_TYPES.WGR.label,
      ALL_PRODUCT_TYPES.WOR.label,
      ALL_PRODUCT_TYPES.OnePlusWOR.label
    ]
  },
  {
    name: "Percent",
    items: [ALL_PRODUCT_TYPES.OilCut.label, ALL_PRODUCT_TYPES.WaterCut.label]
  }
];
export const MidstreamUniqueProduct: ProductTypeT[] = [
  {
    name: "Midstream",
    items: [
      ALL_PRODUCT_TYPES.GasInlet.label,
      ALL_PRODUCT_TYPES.GasOutlet.label,
      ALL_PRODUCT_TYPES.Fuel.label,
      ALL_PRODUCT_TYPES.Flared.label,
      ALL_PRODUCT_TYPES.Vented.label,
      ALL_PRODUCT_TYPES.PlantShrinkage.label,
      ALL_PRODUCT_TYPES.Sulphur.label,
      ALL_PRODUCT_TYPES.GPWater.key
    ]
  }
];
export const MidstreamProduct: ProductTypeT[] = [
  {
    name: "Gas",
    items: [
      ALL_PRODUCT_TYPES.GasInlet.label,
      ALL_PRODUCT_TYPES.GasOutlet.label,
      ALL_PRODUCT_TYPES.Fuel.label,
      ALL_PRODUCT_TYPES.Flared.label,
      ALL_PRODUCT_TYPES.Vented.label,
      ALL_PRODUCT_TYPES.Shrinkage.label
    ]
  },
  {
    name: "Products",
    items: [
      ALL_PRODUCT_TYPES.NGL_234.label,
      ALL_PRODUCT_TYPES.NGLMix.label,
      ALL_PRODUCT_TYPES.C2.label,
      ALL_PRODUCT_TYPES.C3.label,
      ALL_PRODUCT_TYPES.C4.label,
      ALL_PRODUCT_TYPES.C5.label,
      ALL_PRODUCT_TYPES.Sulphur.label,
      ALL_PRODUCT_TYPES.GPWater.key
    ]
  },
  {
    name: "Yields Inlet",
    items: [
      ALL_PRODUCT_TYPES.NGL_234YieldsInlet.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsInlet.label,
      ALL_PRODUCT_TYPES.C2YieldsInlet.label,
      ALL_PRODUCT_TYPES.C3YieldsInlet.label,
      ALL_PRODUCT_TYPES.C4YieldsInlet.label,
      ALL_PRODUCT_TYPES.C5YieldsInlet.label,
      ALL_PRODUCT_TYPES.SulphurYieldsInlet.label,
      ALL_PRODUCT_TYPES.GPWaterYieldsInlet.key
    ]
  },
  {
    name: "Yields Outlet",
    items: [
      ALL_PRODUCT_TYPES.NGL_234YieldsOutlet.label,
      ALL_PRODUCT_TYPES.NGLMixYieldsOutlet.label,
      ALL_PRODUCT_TYPES.C2YieldsOutlet.label,
      ALL_PRODUCT_TYPES.C3YieldsOutlet.label,
      ALL_PRODUCT_TYPES.C4YieldsOutlet.label,
      ALL_PRODUCT_TYPES.C5YieldsOutlet.label,
      ALL_PRODUCT_TYPES.SulphurYieldsOutlet.label,
      ALL_PRODUCT_TYPES.GPWaterYieldsOutlet.key
    ]
  }
];

export const InjectionProduct: ProductTypeT[] = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.WaterInj.label,
      ALL_PRODUCT_TYPES.GasInj.label,
      ALL_PRODUCT_TYPES.PolymerInj.label,
      ALL_PRODUCT_TYPES.TotalFluidInj.label,
      ALL_PRODUCT_TYPES.CO_2Inj.label,
      ALL_PRODUCT_TYPES.AcidGasInj.label,
      ALL_PRODUCT_TYPES.SolventInj.label,
      ALL_PRODUCT_TYPES.TotalGasInj.label,
      ALL_PRODUCT_TYPES.SteamInj.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.ISOR.label,
      ALL_PRODUCT_TYPES.CSOR.label,
      ALL_PRODUCT_TYPES.IWB.label,
      ALL_PRODUCT_TYPES.CWB.label
    ]
  }
];

export const MaterialBalanceTimeChartProducts = [
  ALL_PRODUCT_TYPES.Oil.label,
  ALL_PRODUCT_TYPES.Gas.label,
  ALL_PRODUCT_TYPES.Water.label,
  ALL_PRODUCT_TYPES.TotalFluid.label,
  ALL_PRODUCT_TYPES.BOE.label
];

export const PERFORMANCE_PRODUCT_LIST = [
  ALL_PRODUCT_TYPES.Oil,
  ALL_PRODUCT_TYPES.Gas,
  ALL_PRODUCT_TYPES.Water,
  ALL_PRODUCT_TYPES.TotalFluid,
  ALL_PRODUCT_TYPES.BOE,
  ALL_PRODUCT_TYPES.CGR,
  ALL_PRODUCT_TYPES.GOR,
  ALL_PRODUCT_TYPES.WGR,
  ALL_PRODUCT_TYPES.WOR,
  ALL_PRODUCT_TYPES.ISOR,
  ALL_PRODUCT_TYPES.CSOR,
  ALL_PRODUCT_TYPES.IWB,
  ALL_PRODUCT_TYPES.CWB,
  ALL_PRODUCT_TYPES.SteamInj,
  ALL_PRODUCT_TYPES.GasInj,
  ALL_PRODUCT_TYPES.WaterInj,
  ALL_PRODUCT_TYPES.PolymerInj,
  ALL_PRODUCT_TYPES.CO_2Inj,
  ALL_PRODUCT_TYPES.AcidGasInj,
  ALL_PRODUCT_TYPES.SolventInj,
  ALL_PRODUCT_TYPES.TotalFluidInj,
  ALL_PRODUCT_TYPES.TotalGasInj,
  ALL_PRODUCT_TYPES.OilCut,
  ALL_PRODUCT_TYPES.WaterCut,
  ALL_PRODUCT_TYPES.SalesGas,
  ALL_PRODUCT_TYPES.GasEnergy,
  ALL_PRODUCT_TYPES.GasEnergyYieldsSales,
  ALL_PRODUCT_TYPES.Shrinkage,
  ALL_PRODUCT_TYPES.C2,
  ALL_PRODUCT_TYPES.C3,
  ALL_PRODUCT_TYPES.C4,
  ALL_PRODUCT_TYPES.C5,
  ALL_PRODUCT_TYPES.SalesLiquids,
  ALL_PRODUCT_TYPES.SalesBOE,
  ALL_PRODUCT_TYPES.OilCondC5,
  ALL_PRODUCT_TYPES.NGLMix,
  ALL_PRODUCT_TYPES.NGL_234,
  ALL_PRODUCT_TYPES.C2YieldsRaw,
  ALL_PRODUCT_TYPES.C3YieldsRaw,
  ALL_PRODUCT_TYPES.C4YieldsRaw,
  ALL_PRODUCT_TYPES.C5YieldsRaw,
  ALL_PRODUCT_TYPES.OilCondC5YieldsRaw,
  ALL_PRODUCT_TYPES.LiquidsYieldsRaw,
  ALL_PRODUCT_TYPES.NGLMixYieldsRaw,
  ALL_PRODUCT_TYPES.NGL_234YieldsRaw,
  ALL_PRODUCT_TYPES.C2YieldsSales,
  ALL_PRODUCT_TYPES.C3YieldsSales,
  ALL_PRODUCT_TYPES.C4YieldsSales,
  ALL_PRODUCT_TYPES.C5YieldsSales,
  ALL_PRODUCT_TYPES.LiquidsYieldsSales,
  ALL_PRODUCT_TYPES.OilCondC5YieldsSales,
  ALL_PRODUCT_TYPES.NGLMixYieldsSales,
  ALL_PRODUCT_TYPES.NGL_234YieldsSales,
  ALL_PRODUCT_TYPES.Fuel,
  ALL_PRODUCT_TYPES.Flared,
  ALL_PRODUCT_TYPES.Vented,
  ALL_PRODUCT_TYPES.PlantShrinkage,
  ALL_PRODUCT_TYPES.Sulphur,
  ALL_PRODUCT_TYPES.SulphurYieldsInlet,
  ALL_PRODUCT_TYPES.SulphurYieldsOutlet,
  ALL_PRODUCT_TYPES.GPWater,
  ALL_PRODUCT_TYPES.GPWaterYieldsInlet,
  ALL_PRODUCT_TYPES.GPWaterYieldsOutlet
];

export const FORECAST_PRODUCT_LIST = [
  ALL_PRODUCT_TYPES.Oil,
  ALL_PRODUCT_TYPES.Gas,
  ALL_PRODUCT_TYPES.Water
];

export const PRODUCT_COLOR = {
  condensate: "#1bce0c",
  gas: "#fd0e1a",
  oil: "#186b2d",
  water: "#025aff"
};

export const PRODUCT_LIST = [
  ALL_PRODUCT_TYPES.Oil.label,
  ALL_PRODUCT_TYPES.Gas.label,
  ALL_PRODUCT_TYPES.Water.label,
  ALL_PRODUCT_TYPES.TotalFluid.label,
  ALL_PRODUCT_TYPES.GOR.label,
  ALL_PRODUCT_TYPES.CGR.label,
  ALL_PRODUCT_TYPES.WGR.label,
  ALL_PRODUCT_TYPES.OilCut.label,
  ALL_PRODUCT_TYPES.WaterCut.label
];

// ** Start Multiproduct Constants ** //

export const ChartTypeMultiProduct = [
  {
    name: "Chart Type",
    items: [
      ALL_CHART_TYPES.RateCum.label,
      ALL_CHART_TYPES.RateTime.label,
      ALL_CHART_TYPES.CumTime.label,
      ALL_CHART_TYPES.RateDate.label
    ]
  }
];

export const ProductsForMultiProductChart = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil.label,
      ALL_PRODUCT_TYPES.Gas.label,
      ALL_PRODUCT_TYPES.Water.label,
      ALL_PRODUCT_TYPES.TotalFluid.label,
      ALL_PRODUCT_TYPES.BOE.label,
      ALL_PRODUCT_TYPES.WaterInj.label,
      ALL_PRODUCT_TYPES.GasInj.label,
      ALL_PRODUCT_TYPES.SteamInj.label,
      ALL_PRODUCT_TYPES.PolymerInj.label,
      ALL_PRODUCT_TYPES.CO_2Inj.label,
      ALL_PRODUCT_TYPES.AcidGasInj.label,
      ALL_PRODUCT_TYPES.SolventInj.label,
      ALL_PRODUCT_TYPES.TotalFluidInj.label,
      ALL_PRODUCT_TYPES.TotalGasInj.label
    ]
  },
  {
    name: "Ratio",
    items: [
      ALL_PRODUCT_TYPES.CGR.label,
      ALL_PRODUCT_TYPES.GOR.label,
      ALL_PRODUCT_TYPES.WGR.label,
      ALL_PRODUCT_TYPES.WOR.label,
      ALL_PRODUCT_TYPES.OnePlusWOR.label,
      ALL_PRODUCT_TYPES.ISOR.label,
      ALL_PRODUCT_TYPES.CSOR.label,
      ALL_PRODUCT_TYPES.IWB.label,
      ALL_PRODUCT_TYPES.CWB.label
    ]
  },
  {
    name: "Percent",
    items: [ALL_PRODUCT_TYPES.OilCut.label, ALL_PRODUCT_TYPES.WaterCut.label]
  }
];

// ** End Multiproduct Constants ** //

export const CHART_ACTION = Object.freeze({
  brushStart: {
    type: "takeGlobalCursor",
    key: "brush",
    brushOption: { brushType: "polygon" }
  },
  brushClear: {
    type: "brush",
    command: "clear",
    areas: []
  },
  brushEnd: {
    type: "takeGlobalCursor",
    key: "brush",
    brushOption: {
      brushType: false,
      brushMode: "single"
    }
  }
});

// chart component events
export const CHART_EVENT = Object.freeze({
  snapshotDownloadClick: "onSnapshotDownloadClicked",
  fullscreenToggle: "onFullscreenToggle"
});

// x and y-axis range defaults for fluids
export const CHART_RANGE = Object.freeze({
  oil: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  water: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  gas: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  cond: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  gor: { xMin: 0, xMax: "", yMin: 0, yMax: 10000 },
  wor: { xMin: 0, xMax: "", yMin: 0, yMax: 99 },
  ogr: { xMin: 0, xMax: "", yMin: 0, yMax: 500 },
  cgr: { xMin: 0, xMax: "", yMin: 0, yMax: 500 },
  wgr: { xMin: 0, xMax: "", yMin: 0, yMax: 250 },
  totalFluid: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  waterCut: { xMin: 0, xMax: "", yMin: 0, yMax: "" },
  oilCut: { xMin: 0, xMax: "", yMin: 0, yMax: "" }
});

// Object of plotly scale values
export const PLOTLY_SCALE = Object.freeze({
  default: "-",
  log: "log",
  linear: "linear",
  date: "date",
  category: "category",
  multi: "multicategory"
});

// Plotly default config object
export const PLOTLY_DEFAULT_CONFIG = {
  /*
    doubleClick: false, 'reset', 'autosize' or 'reset+autosize'
    Disable zoom out on double clicking axis
  */
  displaylogo: false,
  locale: "en",
  modeBarButtonsToRemove: ["autoScale2d", "lasso2d", "toImage", "toggleSpikelines"],
  responsive: true
};

export const SNAPSHOT_SIZE = Object.freeze({
  hr1: {
    label: "HR1",
    width: 1152,
    height: 681
  },
  hr2: {
    label: "HR2",
    width: 2416,
    height: 956
  },
  s1: {
    label: "S1",
    width: 1184,
    height: 938
  },
  vr1: {
    label: "VR1",
    width: 774,
    height: 938
  },
  custom: {
    label: "Custom",
    width: "",
    height: ""
  }
});

const Vintage = {
  id: "",
  property: "Dates.Vintage_Year",
  title: "Vintage",
  dataType: "Integer",
  canBin: true
};
const HzLength = {
  id: "",
  property: "Well_Design.HzLength_m",
  title: "Hz Length",
  dataType: "Number",
  canBin: true
};

export const DEFAULT_CHARTS: IChartSettings[] = [
  {
    showP10: false,
    showP50: false,
    showP90: false,
    id: "Chart 0",
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Rate Cum",
    chartTypeId: 1,
    product: "Oil + Cond",
    groupByListVisible: false,
    groupBy: null,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    forecast: true,
    average: false,
    typewells: true,
    producing: false,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length Estimate",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    showActiveWellOnly: true,
    timeStep: "month",
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    sum: false,
    source: "Public",
    cutoff: 50,
    shutInMonths: 3,
    survivorBias: true,
    useWeightedRatioAverage: true,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    busy: false,
    range: { x: [], y: [] },
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    stackedBarCalculation: CalculationMethod.Count,
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false
  },
  {
    id: "Chart 1",
    showP10: false,
    showP50: false,
    showP90: false,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false,
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Mosaic",
    chartTypeId: 1,
    product: "Gas",
    groupByListVisible: false,
    groupBy: null,
    forecast: true,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    shutInMonths: 3,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length Estimate",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    average: false,
    typewells: true,
    producing: false,
    showActiveWellOnly: true,
    sum: false,
    source: "Public",
    cutoff: 50,
    survivorBias: true,
    useWeightedRatioAverage: true,
    timeStep: "month",
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    stackedBarCalculation: CalculationMethod.Count,
    busy: false,
    range: { x: [], y: [] },
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE
  },
  {
    id: "Chart 2",
    showP10: false,
    showP50: false,
    showP90: false,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false,
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Rate Cum",
    chartTypeId: 1,
    product: "Water",
    shutInMonths: 3,
    groupByListVisible: false,
    groupBy: null,
    forecast: true,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length Estimate",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    average: false,
    typewells: true,
    producing: false,
    showActiveWellOnly: true,
    sum: false,
    source: "Public",
    cutoff: 50,
    survivorBias: true,
    useWeightedRatioAverage: true,
    timeStep: "month",
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    busy: false,
    range: { x: [], y: [] },
    stackedBarCalculation: CalculationMethod.Count,
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE
  },
  {
    id: "Chart 3",
    showP10: false,
    showP50: false,
    showP90: false,
    screenshot: false,
    legendPosition: "NE",
    log: {
      x: false,
      y: false
    },
    showOnlySelectedWells: false,
    chartType: "Total Rate Date",
    chartTypeId: 1,
    product: "Gas",
    groupByListVisible: false,
    groupBy: null,
    forecast: true,
    showToolbar: true,
    showNormalize: true,
    enableZoom: true,
    shutInMonths: 3,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length Estimate",
      per: 100,
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useNormalizeBy: false,
    average: false,
    typewells: true,
    producing: false,
    showActiveWellOnly: true,
    sum: false,
    source: "Public",
    cutoff: 50,
    survivorBias: true,
    useWeightedRatioAverage: true,
    timeStep: "month",
    legend: { visible: false, position: null },
    palette: false,
    snapshot: false,
    fullscreen: false,
    rangeVisible: false,
    xAxisParamColor: "#f6768e",
    yAxisParamColor: "#f6768e",
    xAxisParamVisible: false,
    yAxisParamVisible: false,
    xAxisParamListVisible: false,
    yAxisParamListVisible: false,
    busy: false,
    range: { x: [], y: [] },
    stackedBarCalculation: CalculationMethod.Count,
    chartTypeParam: {
      x: Vintage,
      y: HzLength
    },
    axisMinMax: null,
    dotOpacity: DEFAULT_DOT_OPACITY,
    dotSize: DEFAULT_DOT_SIZE,
    showUnitSlope: false,
    showHalfSlope: false,
    showQuarterSlope: false
  }
];

export const CHART_SIZE_ERROR = "Error - Series Limit Exceeded";
export const CHART_WELL_ERROR = "Error - Well Count Exceeded";
export const CHART_EMPTY_ERROR = "noData";

export const PARAM_FIELD = {
  Oil12MCalTime: {
    id: "",
    property: "Performance.Oil.TwelveMCalTimeCum",
    title: "12M CalTime Cum (Mbbl)",
    dataType: "Number",
    canBin: true,
    product: "Oil",
    defaultUnit: "Mbbl"
  },
  Vintage_YearMonth: {
    id: "",
    property: "Dates.Vintage_YearMonth",
    title: "Vintage (Year-Month)",
    dataType: "Text",
    canBin: false
  },
  HzLength: {
    id: "",
    property: "Well_Design.HzLength_m",
    title: "Hz Length (m)",
    dataType: "Number",
    canBin: true
  },
  ResourcePlay: {
    id: "",
    property: "Header.ResourcePlay",
    title: "Resource Play",
    dataType: "Text",
    canBin: false
  },
  FacilityStartDateYear: {
    id: "",
    property: "Header.FacilityStartDate_YearMonth",
    title: "Start Date (Year-Month)",
    dataType: "Text",
    canBin: false
  },
  Gas12MCalTime: {
    id: "",
    property: "Performance.Gas.TwelveMCalTimeCum",
    title: "12M CalTime Cum (MMcf)",
    dataType: "Number",
    canBin: true,
    product: "Gas",
    defaultUnit: "MMcf"
  },
  CurrentCalRate: {
    id: "",
    property: "Performance.Gas.CurrentCalRate",
    title: "Current Cal. Rate (Mcf/d)",
    dataType: "Number",
    canBin: true,
    product: "Gas",
    defaultUnit: "Mcf"
  }
};

export const DEFAULT_CHART_STATE: StateT = {
  ...defaultChartState,
  settings: DEFAULT_CHARTS[0]
};

// Plotly constants
const grid_color = "#000000";
const modebar_default_icon_color = "#abbace";
const modebar_active_icon_color = "#40546b";
const font_color = "#314155";
const transparent_hex = "#00000000";

// Plotly default layout object
export const PLOTLY_DEFAULT_LAYOUT = {
  /* TODO: set colorway */
  autosize: true,
  title: {
    text: "Plot Title"
  },
  font: {
    color: font_color,
    size: 12
  },
  margin: {
    l: 46,
    r: 16,
    b: 54,
    t: 25
  },
  showlegend: false,
  legend: {
    font: {
      family: "apercu"
    }
  },
  modebar: {
    activecolor: modebar_active_icon_color,
    color: modebar_default_icon_color,
    bgcolor: transparent_hex,
    orientation: "v"
  },
  /* Hovermode: "x" | "y" | "closest" | false */
  hovermode: "closest",
  /* Dragmode:  "zoom" | "pan" | "select" | "lasso" | "orbit" | "turntable" | false */
  hoverlabel: {
    font: {
      family: "apercu",
      size: 14
    }
  },
  dragmode: "zoom",
  /* No color for paper and plot */
  plot_bgcolor: transparent_hex,
  paper_bgcolor: transparent_hex,
  xaxis: {
    automargin: true,
    color: grid_color,
    gridcolor: grid_color,
    tickformat: "yyyy-dd",
    showdividers: false,
    showline: false,
    zeroline: false
  },
  yaxis: {
    automargin: true,
    color: grid_color,
    gridcolor: grid_color,
    showdividers: false,
    showline: false,
    zeroline: false
  },
  datarevision: 1
};

export const ForecastRatios = [
  { label: "GOR", value: 5 },
  { label: "CGR", value: 6 },
  { label: "WGR", value: 7 },
  { label: "OGR", value: 8 },
  { label: "WOR", value: 9 }
];

export const ForecastProducts = [
  { label: "Oil", value: 1 },
  { label: "Gas", value: 2 },
  { label: "Water", value: 3 },
  ...ForecastRatios
];

export const EMPTY_CHART_RESULT = {
  title: "",
  messages: [
    {
      message: "",
      messageType: CHART_EMPTY_ERROR,
      entities: []
    }
  ],
  errorMessage: "",
  messageType: CHART_EMPTY_ERROR,
  errorEntities: [],
  series: [],
  layout: {
    xAxis: {
      title: "",
      defaultAxisType: "linear",
      labels: [],
      min: 0,
      max: 0,
      interval: 0,
      data: [],
      logMin: null,
      yScaleToX: 1,
      axisIsDate: false,
      isSecondaryYAxis: false
    },
    yAxis: {
      title: "",
      defaultAxisType: "linear",
      labels: [],
      min: 0,
      max: 0,
      interval: 0,
      data: [],
      logMin: null,
      yScaleToX: 1,
      axisIsDate: false,
      isSecondaryYAxis: false
    },
    yAxis2: [],
    xAxis2: []
  },
  requestId: "",
  uwis: [],
  uwiCoordinates: {},
  segments: [],
  constants: [],
  wellSources: {
    productionDataSources: [],
    forecastSources: []
  },
  productionSources: "",
  syncStatus: {
    isForecastSynced: false,
    isProductionSynced: false,
    syncedSchema: "",
    warningMessage: ""
  },
  normalizeData: null
} as IChartResult;
