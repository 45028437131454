import {
  SELECTED_SINGLE_WELL_LAYER,
  SELECTED_WELL_LAYER,
  SELECTED_WELL_POINT_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";

import {
  IEditableProjectLayer,
  useCheckedLayersChangeHandler
} from "components/project/layers/hooks";
import { useProjectContext } from "components/project/projects/context";

export function useLayerListOnCheckHandler() {
  const projectContext = useProjectContext();

  const { handleCheckedLayersChange } = useCheckedLayersChangeHandler();

  function handleCheckingLayer(isChecked: boolean, layer: IEditableProjectLayer) {
    const checkedLayerKey = layer.projectShapefileId || layer.shapefileId || layer.name;
    let newCheckedKeys = [...projectContext.checkedLayerKeys];

    // Similar to the layer tree, we could also base the keys off the layer list, which already handles the dependant layers if this gets messy
    // Not sure of the implications, maybe speed, but this is direct way of ensuring the dependant layers are added to the cache, and styles are applied on load correctly
    const checkedKeysBeingAdded = getCheckedKeys({ layerName: checkedLayerKey });

    if (isChecked) {
      newCheckedKeys.splice(layer.order, 0, ...checkedKeysBeingAdded);
    } else {
      newCheckedKeys = newCheckedKeys.filter(
        (key) => !checkedKeysBeingAdded.includes(key)
      );
    }

    handleCheckedLayersChange({
      checkedLayerKeys: newCheckedKeys,
      checkedTreeKeys: newCheckedKeys
    });
  }

  return { handleCheckingLayer };
}

function getCheckedKeys(params: { layerName: string }): string[] {
  const { layerName } = params;
  const newCheckedKeys = [];

  if ([SELECTED_WELL_LAYER, WELL_LAYER].includes(layerName)) {
    if (layerName === SELECTED_WELL_LAYER) {
      newCheckedKeys.push(SELECTED_WELL_POINT_LAYER);
      newCheckedKeys.push(SELECTED_SINGLE_WELL_LAYER);
    }

    if (layerName === WELL_LAYER) {
      newCheckedKeys.push(WELL_LAYER_POINT);
    }
  }
  newCheckedKeys.push(layerName);
  return newCheckedKeys;
}
