// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { toast } from "react-toastify";

import { mdiExport } from "@mdi/js/commonjs/mdi";

import { ShapefileAction } from "../..";
import { StyledSpinner } from "../../../../../../../user-settings/components/defaults/shared";
import useExportShapefile from "../../../../../hooks/export-shapefile/useExportShapefile";

interface ExportShapefileActionProps {
  isDisabled?: boolean;
  closePopover: () => void;
  shapefileNodeId: string;
}

export function ShapefileFolderExportAction({
  isDisabled,
  closePopover,
  shapefileNodeId
}: ExportShapefileActionProps) {
  const { mutateAsync: exportShapefile, isLoading } = useExportShapefile();

  return (
    <ShapefileAction
      disabled={isDisabled}
      placement="bottom"
      onClick={async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        try {
          await exportShapefile({
            shapefileId: shapefileNodeId,
            type: "folder"
          });

          closePopover();
        } catch (error) {
          toast.error("Error exporting shapefile");
        }
      }}>
      {isLoading ? (
        <StyledSpinner padding={0} size={16} />
      ) : (
        <Icon path={mdiExport} size={1} />
      )}
      Export Shapefile
    </ShapefileAction>
  );
}
