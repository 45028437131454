/* eslint-disable no-console */
import { getMapLayersMatchedToLayerList } from ".";

/** Can confirm output of mapbox layers are what we expect from the ordered list
 * */
export function debugMapboxSorting(mapboxLayers, orderedLayerList) {
  const matchedMapboxLayers = getMapLayersMatchedToLayerList({
    layerList: orderedLayerList,
    mapboxLayers: mapboxLayers
  });

  const isOrderedLayerDataSame =
    JSON.stringify(matchedMapboxLayers) === JSON.stringify(orderedLayerList);

  console.log(
    "\n",
    " ",
    "\n",
    "~~ Mapbox layers:",
    mapboxLayers,
    "\n",
    " ",
    "\n",
    "~~ Mapbox layers after sort: ",
    "\n",
    "   (layer list matching only)",
    "\n",
    matchedMapboxLayers,
    "\n",
    " ",
    "\n",
    "~~ Layers matching the layer list have been sorted:",
    "\n",
    `~~ ${isOrderedLayerDataSame ? "Successfully" : "Unsuccessfully"}.`
  );
}
