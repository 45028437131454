import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Tree } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { IShapefileNode } from "models/projects";

import { Heading } from "components/base";
import { IconSpinner } from "components/icons";
import { findParentNode } from "components/project/layers/utils";
import {
  useMoveProjectShapefileFolderMutation,
  useMoveProjectShapefileMutation,
  useUploadProjectShapefileListMutation
} from "components/project/shapefiles/mutations";
import { useProjectShapefileFeaturesQuery } from "components/project/shapefiles/queries";

import { useOrgShapefiles } from "../hooks/useOrgShapefiles";
import OrganizationFolderNode from "./OrganizationFolderNode";
import OrganizationRootNode from "./OrganizationRootNode";
import { OrganizationShapefileDetails } from "./OrganizationShapefileDetails";
import OrganizationShapefileNode from "./OrganizationShapefileNode";
import "./OrganizationShapefiles.scss";

interface OrganizationShapefilesT {
  allowUpload?: boolean;
  showHeader?: boolean;
}
export default function OrganizationShapefiles({
  allowUpload = true,
  showHeader = true
}: OrganizationShapefilesT): JSX.Element {
  const [uploadError, setUploadError] = useState(false);
  const [loading, setLoading] = useState(false);
  const treeContainer = useRef(null);
  const [selectedShapefile, setSelectedShapefile] = useState(null);
  const [shapefileFeatures, setShapefileFeatures] = useState({});
  const [selectedFeatureProperty, setSelectedFeatureProperty] = useState({});
  const [, setTreeHeight] = useState(585);

  useResizeObserver(treeContainer, (client) => {
    setTreeHeight(client.contentRect.height);
  });
  const { tree } = useOrgShapefiles();

  const role = useSelector((state: RootState) => state.auth.user.role);
  const isAdminOrPowerUser = role === "Admin" || role === "PowerUser";

  const shapefileFeaturesQuery = useProjectShapefileFeaturesQuery({
    id: selectedShapefile?.key,
    isProjectLinkedShapefile: false
  });

  useEffect(() => {
    if (!selectedShapefile?.shapefileId) return;
    if (shapefileFeaturesQuery.data) {
      const shapefile = shapefileFeaturesQuery.data;
      const newFeatures = shapefile.geoms;
      if (newFeatures) {
        const id = selectedShapefile.shapefileId;
        const features = {
          [id]: {
            features: newFeatures,
            layerName: selectedShapefile.name
          }
        };
        setShapefileFeatures({ ...shapefileFeatures, ...features });

        const property = selectedFeatureProperty[id]
          ? selectedFeatureProperty[id]
          : selectedShapefile.properties.find((x) => x === "NAME") ||
            selectedShapefile.properties[0];

        if (property) {
          setSelectedFeatureProperty({ ...selectedFeatureProperty, [id]: property });
        }
      }
    }
  }, [shapefileFeaturesQuery.data]);

  const moveProjectShapefile = useMoveProjectShapefileMutation({
    projectId: "organization"
  });

  const moveProjectShapefileFolder = useMoveProjectShapefileFolderMutation({
    projectId: "organization"
  });

  function getDropKey(dropKey) {
    if (dropKey === "Project Shapefiles" || dropKey === "McDaniel Shapefiles") {
      return "";
    }
    if (dropKey === "Organization Shapefiles") {
      return "orgLevelShapefiles";
    }
    return dropKey;
  }

  const onDrop = useCallback(
    (info) => {
      const dragType = info.dragNode.type;
      let dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      let dropPosition = info.dropPosition;
      if (info.dropToGap) {
        const parentNodeId = findParentNode(tree, dropKey, "org");
        //drop to gap means that the user wishes the dragNode
        //to to be a sibiling of the drop target
        //the parent of the dropkey is the actual drop target
        dropKey = parentNodeId ?? "";
      } else {
        const dragParentNodeId = findParentNode(tree, dragKey, "");
        const dragNode = info.dragNode;
        const dropNode = info.node;
        const droppedToSameFolder =
          dragNode?.shapefileNodeId == dropNode.shapefileNodeId || //shapefileParentNode is same as folder
          dragNode?.parentId === dropNode.shapefileNodeId ||
          dragParentNodeId === dropNode.key; //parent node is same as drop node
        if (droppedToSameFolder) {
          //dropping to the same folder previously and dropToGap is false
          //so the user must want to drop to the top of the folder
          dropPosition = 0;
        }
      }

      if (info.node.type === "shapefile") {
        if (!info.dropToGap) {
          dropPosition += 1;
        }
        //can't drop on to a shapefile so we move it to the node that
        //holds the shapefile we're trying to drop to
        dropKey = info.node.shapefileNodeId;
      }

      const dropLevel = info.node.pos.split("-").length;
      const dropToRoot = dropLevel === 2;

      if (dropToRoot) {
        //dropped to root node so set dropPosition to 0 because
        //user wants to drop it at the top of the
        dropPosition = 0;
      }
      if (dragType === "node") {
        dropKey = getDropKey(dropKey);
        moveProjectShapefileFolder.mutate({
          shapefileNodeId: dragKey,
          moveToId: dropKey,
          dropPosition: dropPosition,
          type: "project"
        });
      } else if (dragType === "shapefile") {
        dropKey = getDropKey(dropKey);
        moveProjectShapefile.mutate({
          shapefileId: dragKey,
          moveToId: dropKey,
          dropPosition: dropPosition,
          type: "project"
        });
      } else if (dragKey === "") {
        // The user attempted to move the root shapefiles folder, so ignore.
      } else {
        toast.error("Unable to move layer");
      }
    },
    [tree]
  );

  const uploadShapefilesMutation = useUploadProjectShapefileListMutation({
    projectId: "organization",
    onSuccess: () => {
      toast.success(`Uploaded successfully.`);
      setUploadError(false);
      setLoading(false);
    },
    onError: (err) => {
      setUploadError(err);
      setLoading(false);
    }
  });

  function onAcceptFiles(files, parentId) {
    setLoading(true);

    uploadShapefilesMutation.mutate({
      files,
      visibility: "organization",
      projectId: "",
      shapefileNodeId: parentId,
      thickness: "2.0"
    });
  }

  const customTreeNode = useCallback((node: IShapefileNode | IShapefileNode) => {
    node = { ...node };
    if (node.type === "root") {
      return <OrganizationRootNode node={node} />;
    } else if (node.type === "shapefile") {
      return (
        <OrganizationShapefileNode
          node={node}
          setSelectedShapefile={setSelectedShapefile}
        />
      );
    } else if (node.type === "node") {
      return <OrganizationFolderNode node={node} />;
    } else {
      return null;
    }
  }, []);
  return (
    <>
      {showHeader && (
        <div className="organizationAccountHeader">
          <Heading element="h4">Organization Shapefiles </Heading>
        </div>
      )}
      <div className="org-shapefiles">
        {selectedShapefile && (
          <ShapefileDetailsWrapper>
            <OrganizationShapefileDetails
              selectedProjectId={""}
              selectedShapefile={selectedShapefile}
              shapefileFeatures={shapefileFeatures}
              selectedFeatureProperty={selectedFeatureProperty}
              setSelectedFeatureProperty={setSelectedFeatureProperty}
              setSelectedShapefile={setSelectedShapefile}
            />
          </ShapefileDetailsWrapper>
        )}
        {allowUpload && (
          <Dropzone onDrop={onAcceptFiles}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="dropzone-container">
                <div
                  className={`dropzone ${uploadError ? "hasError" : ""} ${
                    isDragActive ? "dragActive" : ""
                  }`}
                  {...getRootProps()}>
                  <input title="Upload shapefiles" {...getInputProps()} />
                  <p>
                    Drag and drop shapefiles (.shp,.prj,.shx,.dbf,...) here or click to
                    select files
                  </p>
                </div>
                {loading && (
                  <SpinnerContainer>
                    <IconSpinner></IconSpinner>
                  </SpinnerContainer>
                )}
              </div>
            )}
          </Dropzone>
        )}
        {tree.length > 0 && (
          <div ref={treeContainer} className="org-tree-container">
            <Tree
              className="draggable-tree"
              selectable={false}
              showLine={{ showLeafIcon: true }}
              showIcon={true}
              onDrop={onDrop}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              draggable={(node: any) => node.type !== "root" && isAdminOrPowerUser}
              blockNode
              defaultExpandAll={true}
              treeData={tree}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              titleRender={customTreeNode}
            />
          </div>
        )}
      </div>
    </>
  );
}

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  background: #dededec4;
  width: 100%;
  height: 100%;
`;

const ShapefileDetailsWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background-color: #fcfdfc;
`;
