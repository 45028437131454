import { IChartSettings } from "models/chart";

import { CalculationMethod } from "../components/stacked-bar-options/StackedBarCalculation";

/*  Often used for backwards compatibility,
 * When new settings have been introduced to the chart, and we need to make older charts compatible.
 * For example, when we introduced bin type.
 * The DB does not have the bin type stored in charts that were created before the bin type was introduced.
 * Although, new charts will have the bin type set by default.
 */
export const applyChartDefaults = (settings: IChartSettings): IChartSettings => {
  if (!settings) return settings;

  const newSettings = { ...settings };

  // Bin Type
  const bin = newSettings.chartTypeParam.x?.bin;
  if (bin && !bin.BinType) {
    bin.BinType = "BinSize";
  }

  // Enable Zoom
  if (!newSettings.enableZoom === undefined) {
    newSettings.enableZoom = true;
  }

  // Lock Units
  if (newSettings.lockUnits === undefined) {
    newSettings.lockUnits = false;
  }

  // Log
  if (newSettings.log === undefined) {
    newSettings.log = {
      x: false,
      y: false
    };
  }

  // Show Lines of Best Fit
  if (newSettings.showLinesOfBestFit === undefined) {
    newSettings.showLinesOfBestFit = true;
  }

  // Show Well Count
  if (newSettings.showWellCount === undefined) {
    newSettings.showWellCount = true;
  }

  // Time Step
  if (newSettings.timeStep === undefined) {
    newSettings.timeStep = "month";
  }
  if (newSettings.shutInMonths === undefined) {
    newSettings.shutInMonths = 3;
  }

  if (newSettings.dotSize === undefined) {
    newSettings.dotSize = 10;
  }

  if (newSettings.dotOpacity === undefined) {
    newSettings.dotOpacity = 1;
  }

  if (newSettings.stackedBarCalculation === undefined) {
    newSettings.stackedBarCalculation = CalculationMethod.Count;
  }
  return newSettings;
};
